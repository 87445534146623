import React from "react";
import styled from "styled-components";
export const Wrapper = styled.div`
  padding: 50px 25px;
  min-height: 100vh;
  color: #fff;
  background-image: url("/images/collage.jpeg");
  background-size: cover;
  background-position: 50% 50%;
  z-index: 2;
  border-radius: 5px;



  :after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(35, 50, 102, 0.4);
    z-index: -1;
  } 
`

export const PageTitle = styled.h1`
  color: #512581;
  font-weight: bold;
  padding: 5px 0;
`;

export const Wrapper1 = styled.div`
  padding: 10px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 8px 10px 5px rgb(0 0 0 / 10%);
`

export const Overview = () => {
  return (
    <Wrapper1>
      <Wrapper />
    </Wrapper1>
  )
}