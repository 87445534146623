import React from "react";

export default function NavMenuTop() {
  return (
    <div className="top-menu">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-xs-12 text-right">
            <p className="site" style={{ paddingLeft: 0 }}>
              <a
                href="/stage-et-emploi/offres"
                className="btn btn-primary button-top-menu-left"
              >
                Offres d'emploi
              </a>
              <a
                href="/auth/~p/signup"
                className="btn btn-outline btn-primary button-top-menu-left"
              >
                Déposez votre CV
              </a>
              <a
                href="https://www.moncvpro.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn  button-top-menu-left cligner"
                style={{
                  backgroundColor: "#be1823",
                  color: "white",
                  borderColor: "#be1823",
                }}
              >
                Créez votre CV
              </a>
            </p>
          </div>
          <div className="col-xs-12 text-right">
            <p className="num">
              <a
                href="/stage-et-emploi/e/particulier"
                className="btn btn-primary button-top-menu-right"
              >
                Mon espace
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
