import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import axios from "axios";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from "ui/TextField";
import { useAuthToken } from "hooks/useAuthToken";
import AuthWrapper from "../Wrapper";
import { Title } from "../style";
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  email: yup.string()
    .email("Veuillez saisir une adresse email valide.")
    .required("Veuillez saisir votre adresse email."),
  password: yup.string().required("Veuillez saisir votre mot de passe.")
});

const Auth: React.FC<RouteComponentProps> = () => {
  const [_, setAuthToken] = useAuthToken();
  const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data: any) => {
    toast.dismiss();
    axios.post("/api/v1/teachers/signin", {
      email: data.email,
      password: data.password
    }).then(res => {
      setAuthToken(res.data);
      toast.info("Connecté");
      navigate("/soutien-scolaire/e/enseignant/interventions");
    })
      .catch(error => {
        if (error.response && (error.response.status === 404 || error.response.status === 401)) {
          toast.error("Le mot de passe et/ou l'email que vous avez saisi est invalide.");
        }

        if (error.response && error.response.status === 403) {
          if (!error.response.data?.account_status) {
            toast.error("Votre compte est actuellement suspendu");
          } else {
            toast.error("Votre compte n'est pas encore activé, Veuillez consulter votre mail pour activer votre compte");
          }
        }
      })
  }

  return (
    <AuthWrapper profile="teacher">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Title className="text-center">
            Connectez-vous
          </Title>
        </div>
        <div className="form-group">
          <TextField
            label="Adresse email"
            name="email"
            type="email"
            required
            inputRef={register}
            error={!!errors.email}
            helpText={errors.email?.message}
          />
        </div>
        <div className="form-group">
          <TextField
            label="Mot de passe"
            name="password"
            type="password"
            required
            inputRef={register}
            error={!!errors.password}
            helpText={errors.password?.message}
          />
        </div>

        <div className="form-group text-center">
          <button
            type="submit" className="btn btn-primary">
            Connexion
								    </button>
        </div>

        <div className="form-group text-center">
          Vous n'avez pas encore de compte ? <a href="/auth/~t/signup">S'inscrire</a>
        </div>

        <div className="form-group text-center">
          <a href="/auth/~t/reset/new">Mot de passe oublié ?</a>
        </div>
      </form>
    </AuthWrapper>
  )
}

export default Auth;