
import React from "react";
import { RouteComponentProps } from "@reach/router";
import HomeItem from "pages/Job/HomeItem";
import JobItem from "pages/JobOffer/JobItem";

interface Ar extends RouteComponentProps {
  data: any[];
}
export default function HomeArticle(props: Ar) {

  React.useEffect(() => {
    if (props?.data.length > 0)
      initSlider();
  }, [props?.data.length]);

  const initSlider = () => {
    if ((window as any).$) {
      (window as any).$("#homesheet.owl-carousel").owlCarousel({
        loop: false,
        margin: 10,
        nav: true,
        autoplay: true,
        dots: false,
        animateOut: true,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 4
          }
        }
      })
    }
  }

  return (
    <div id="homesheet" className="owl-carousel">
      {
        props.data.map((article, key) => {
          return (
            <>
              <JobItem type="grid" item={article} />
            </>
          )
        })
      }
    </div>
  )
}
