import React from "react";
import MainLayout from "ui/Layout";
import { navigate, RouteComponentProps } from "@reach/router";
import NavMenuTop from "./components/NavMenuTop";
import { JobOverviewList } from "../style";
import JobItem from "./JobItem";
import JobDetails from "./JobDetails";
import axios from "axios";
import SearchBar from "./SearchBar";
import { isMobile } from "utils";

const Overview: React.FC<RouteComponentProps> = () => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<Array<any>>([]);
  const params = new URLSearchParams(window.location.search);
  // const [showList, setShowList] = React.useState(true);
  // const isMobile = window?.innerWidth <= 767;


  React.useEffect(() => {
    axios.get('/api/v1/joboffers/public')
      .then(res => {
        if (res.status === 200) {
          setLoading(false);
          setData(res.data);
          if (res.data.length > 0) {
            const params = new URLSearchParams(window.location.search);
            // if (!params.get('jobid') && !isMobile) {
            //   navigate("/stage-et-emploi/offres?jobid=" + res.data[0].jobId);
            // }
          }
        }
      });
  }, []);

  return (
    <MainLayout top={<NavMenuTop />}>
      <aside
        className="page-cover-1"
        style={{ backgroundImage: 'url(https://media.newjobs.com/global/img/New_HERO_purp_offcenteredV5.jpg)', backgroundSize: 'cover' }}
      >
        <div className="container">
          <SearchBar />
        </div>
      </aside>

      <br />
      <aside id="fh5co-course" style={{ paddingTop: "0px" }}>

        {
          loading ?
            <div className="text-center">
              <br />
              <div className="spinner-grow" style={{ width: "4rem", height: "4rem" }} role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <br />
            </div> :
            data.length > 0 ?
              <div className="container-fluid">
                <div className="row">
                  {
                    <div className={`col-md-${isMobile ? 12 : 4}`}>
                      <JobOverviewList>
                        {
                          data.map((item, key) =>
                            <JobItem item={item} key={key} selected={params.get('jobid') && params.get('jobid') === item.jobId} />
                          )
                        }

                      </JobOverviewList>
                    </div>
                  }
                  {
                    !isMobile &&
                    <div className="col-md-8">
                      <JobDetails />
                    </div>
                  }
                </div>
              </div> :
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <br />
                    <div className="card-simple text-center">
                      <br />
                      <img src="/images/search-empty-icon.png" alt="" />
                      <p>
                        <br />
                        <h4>Aucune offre disponible</h4>
                      </p>
                      <a href="/stage-et-emploi/offres" className="btn btn-primary button-top-menu-left">
                        Toutes les offres d'emploi
                      </a>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
        }
        {/* {
          !loading && data.length > 0 ?
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 col-lg-4">
                  <JobOverviewList>
                    {
                      data.map((item, key) =>
                        <JobItem item={item} key={key} selected={params.get('jobid') && params.get('jobid') === item.jobId} />
                      )
                    }
                  </JobOverviewList>
                </div>
                <div className="col-md-8">
                  <JobDetails />
                </div>
              </div>
            </div> :
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <br />
                  <div className="card-simple text-center">
                    <br />
                    <img src="/images/search-empty-icon.png" alt="" />
                    <p>
                      <br />
                      <h4>Aucune offre disponible</h4>
                    </p>
                    <a href="/stage-et-emploi/offres" className="btn btn-primary button-top-menu-left">
                      Toutes les offres d'emploi
                    </a>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
        } */}
        {/* {
          loading && "kk"
        } */}
      </aside>
    </MainLayout>
  )
}

export default Overview;