import React from "react";
import { navigate } from "@reach/router";
import { JOB_OFFER_STATUS, Status } from "./ApplicationItem";
import axios from "axios";
import moment from "moment";
import { OverviewCard, OverviewCardHeader } from "../../style";
import { useAuthToken } from "hooks/useAuthToken";


const Applications = ({ }) => {
  const [data, setData] = React.useState<Array<any>>([]);
  const [authToken] = useAuthToken();

  React.useEffect(() => {
    axios.get("/api/v1/entreprises/myoffers", {
      headers: {
        ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {}),
      }
    }).then(res => {
      if (res.status === 200) {
        setData(res.data)
      }
    })
  }, []);

  return (
    <div className="container">
      <div className="row">
        <OverviewCard className="col-lg-12">
          <OverviewCardHeader>
            <h4 className="page-title">Offres d'emploi</h4>
            <button className="btn btn-primary" onClick={() => navigate("candidatures/nouveau")} >Publier une offre</button>
          </OverviewCardHeader>
          {
            data &&
              data.length > 0 ?
              <table className="table table-bordered table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Titre de l'offre</th>
                    <th scope="col">Candidatures</th>
                    <th scope="col">Statut</th>
                    <th scope="col">Date de creation</th>
                    <th scope="col">Date d'expiration</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.map((item: any, key: number) =>
                      <tr key={key}>
                        <td>
                          <a href={`/stage-et-emploi/offres?jobid=${item?.jobId}`} target="_blank">
                            {item.title}
                          </a>

                        </td>
                        <td>{item.applicationsCount} </td>
                        <td>
                          <Status style={{ backgroundColor: item.status === "TO_VALIDATE" ? "#B77400" : "" }}>
                            {JOB_OFFER_STATUS[item.status]}
                          </Status>
                        </td>
                        <td>{moment(item.createdAt).format('MMMM D YYYY, h:mm')} </td>
                        <td>{item.expiration_date} </td>
                        <td className="text-right">
                          <a className="btn btn-primary" href={`/stage-et-emploi/e/entreprise/candidatures/${item?.jobId}`}> Details</a>

                          <a className="btn btn-info" href={`/stage-et-emploi/e/entreprise/candidatures/editer/${item?.jobId}`}> Editer</a>
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
              :
              <>Rien à afficher</>
          }
        </OverviewCard>
      </div>
    </div>
  )
}

export default Applications;
