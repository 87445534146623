import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import EntrepriseLayout from "ui/Layout/Entreprise";
import PrivateRoute from "./PrivateRoute";
import Applications from "./Applications";
import Application from "./Application";
import NewApplication from "./NewApplication";
import Setting from "./Setting";
import ContactInfos from "./ContactInfos";
import Presentation from "./Presentation";
import CVTheque from "./CVTheque";
import MyCVTheque from "./MyCVTheque";

const Entreprise: React.FC<RouteComponentProps> = () => {
  return (
    <EntrepriseLayout>
      <Router>
        <PrivateRoute path="/candidatures" component={Applications} default />
        <PrivateRoute path="/cvtheque/trouver" component={CVTheque} />
        <PrivateRoute path="/cvtheque" component={MyCVTheque} />
        <PrivateRoute path="/candidatures/:jobid" component={Application} />
        <PrivateRoute path="/candidatures/nouveau" component={NewApplication} />
        <PrivateRoute path="/candidatures/editer/:jobid" component={NewApplication} />
        <PrivateRoute path="/parmaretres" component={Setting} />
        <PrivateRoute path="/presentation" component={Presentation} />
        <PrivateRoute path="/coordonnees" component={ContactInfos} />
      </Router>
    </EntrepriseLayout>
  )
}
export default Entreprise;