import React from "react";
import { RouteComponentProps } from "@reach/router";
import axios from "axios";
import { useAuthToken } from "hooks/useAuthToken";
import moment from "moment";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import { OverviewCard, OverviewCardHeader } from "../../style";
import { InfoList, Ul } from "../JobDetails";
import LabelRadio from "../components/LabelRadio";
import { toast } from "react-toastify";
import StatusInfo from "ui/StatusInfo";

const APPLY_STATUS = {
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  ACCEPTED: "ACCEPTED",
};

export const STATUS_COLOR = {
  PENDING: {
    bg: "#6610f2",
    text: "En attente",
  },
  REJECTED: {
    bg: "#DD2421",
    text: "Refusée",
  },
  ACCEPTED: {
    bg: "#28a745",
    text: "Retenue",
  },
};

interface ApplicationProps extends RouteComponentProps {
  jobid?: string;
}

const Application = ({ jobid }: ApplicationProps) => {
  const [data, setData] = React.useState(null);
  const [applications, setApplications] = React.useState([]);
  const [currentCandidate, setCurrentCandidate] = React.useState(null);
  const [currentApplication, setCurrentApplication] = React.useState(null);
  const [applyStatus, setApplyStatus] = React.useState("PENDING");

  const [authToken] = useAuthToken();
  let cancelRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (jobid) {
      axios
        .get("/api/v1/joboffers/jobid/" + jobid, {
          headers: {
            ...(Boolean(authToken)
              ? { Authorization: `Bearer ${authToken}` }
              : {}),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setData(res.data);
            setApplications(res.data.applications);
          }
        });
    }
  }, []);

  const manage = () => {
    if (applyStatus && currentApplication) {
      axios
        .put(
          "/api/v1/applications/manage/" + currentApplication.id,
          { status: applyStatus },
          {
            headers: {
              ...(Boolean(authToken)
                ? { Authorization: `Bearer ${authToken}` }
                : {}),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            toast.success("Candidature gérer avec succès");
            cancelRef.current.click();
            let newApp = applications.map((item) => {
              if (item.id === res.data.id) item.status = res.data.status;
              return item;
            });
            setApplications([...newApp]);
          }
        });
    }
  };

  const handleInputChange = (e: any) => {
    setApplyStatus(e.target.value);
  };

  return (
    <div className="container">
      <div className="row">
        {data ? (
          <OverviewCard className="col-lg-12">
            <OverviewCardHeader>
              <h4 className="page-title">{data?.title}</h4>
            </OverviewCardHeader>
            <div>
              <Ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="pills-home-tab"
                    data-toggle="pill"
                    href="#pills-home"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Description
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="pills-profile-tab"
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Infos clés
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="pills-cand-tab"
                    data-toggle="pill"
                    href="#pills-cand"
                    role="tab"
                    aria-controls="pills-cand"
                    aria-selected="false"
                  >
                    Candidatures
                  </a>
                </li>
              </Ul>

              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade active show"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div>
                    <h4>Description du poste</h4>
                    <p>{data.description}</p>
                    <h4>Profil recherché</h4>
                    <p>{data.profile}</p>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <InfoList>
                    <li className="item">
                      <span className="item-title">Poste : </span>
                      <span> {data.title}</span>
                    </li>
                    <li className="item">
                      <span className="item-title">Lieu du poste : </span>
                      <span>
                        {" "}
                        {data.city}, {data.address}
                      </span>
                    </li>
                    <li className="item">
                      <span className="item-title">Type de contrat : </span>
                      <span>{data.contract_type}</span>
                    </li>
                    <li className="item">
                      <span className="item-title">Durée du contrat : </span>
                      <span>{data.contract_length}</span>
                    </li>
                    <li className="item">
                      <span className="item-title">Remuneration : </span>
                      <span>{data.remuneration}</span>
                    </li>
                    <li className="item">
                      <span className="item-title">Niveau d'études : </span>
                      <span>{data.required_training}</span>
                    </li>
                    <li className="item">
                      <span className="item-title">Experience requise : </span>
                      <span>{data.required_experience}</span>
                    </li>
                    <li className="item">
                      <span className="item-title">Code de référence : </span>
                      <span>{data.reference}</span>
                    </li>
                  </InfoList>
                </div>

                <div
                  className="tab-pane fade"
                  id="pills-cand"
                  role="tabpanel"
                  aria-labelledby="pills-cand-tab"
                >
                  <table className="table table-bordered table-hover">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Nom</th>
                        <th scope="col">Prénom</th>
                        <th scope="col">Message</th>
                        <th scope="col">Statut</th>
                        <th scope="col">Postulé le</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {applications.map((application: any, key: number) => (
                        <tr key={key}>
                          <td>{application.candidat.firstname} </td>
                          <td>{application.candidat.lastname} </td>
                          <td>{application.message?.substr(0, 90)}... </td>
                          <td>
                            <StatusInfo
                              width="80px"
                              background={
                                STATUS_COLOR[application.status]["bg"]
                              }
                              status={STATUS_COLOR[application.status]["text"]}
                            />
                          </td>
                          <td>
                            {moment(application.createdAt).format(
                              "MMMM D YYYY, h:mm"
                            )}
                          </td>
                          <td style={{ width: "194px" }}>
                            <button
                              className="btn btn-info"
                              data-toggle="modal"
                              data-target="#manageModal"
                              onClick={() => {
                                setCurrentApplication(application);
                              }}
                            >
                              Gérer
                            </button>
                            <button
                              className="btn btn-primary"
                              data-toggle="modal"
                              data-target="#exampleModal"
                              onClick={() => {
                                setCurrentCandidate({
                                  ...application.candidat,
                                  message: application.message,
                                });
                              }}
                            >
                              Détails
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </OverviewCard>
        ) : (
          ""
        )}
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Candidat
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {currentCandidate ? (
              <div className="modal-body" style={{ minHeight: "70vh" }}>
                <Ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="pills-details-tab"
                      data-toggle="pill"
                      href="#pills-details"
                      role="tab"
                      aria-controls="pills-details"
                      aria-selected="true"
                    >
                      Profil
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-resume-tab"
                      data-toggle="pill"
                      href="#pills-resume"
                      role="tab"
                      aria-controls="pills-resume"
                      aria-selected="false"
                    >
                      CV
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-message-tab"
                      data-toggle="pill"
                      href="#pills-message"
                      role="tab"
                      aria-controls="pills-message"
                      aria-selected="false"
                    >
                      Message laissé
                    </a>
                  </li>
                </Ul>

                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade active show"
                    id="pills-details"
                    role="tabpanel"
                    aria-labelledby="pills-details-tab"
                  >
                    <InfoList>
                      <li className="item">
                        <span className="item-title">Nom : </span>
                        <span> {currentCandidate.firstname}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Prénom : </span>
                        <span>{currentCandidate.lastname}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Tel : </span>
                        <span>{currentCandidate.phonenumber}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Email : </span>
                        <span>{currentCandidate.email}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Adresse : </span>
                        <span>{currentCandidate.address}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Niveau d'étude : </span>
                        <span>{currentCandidate.study_level}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">
                          Type de contrat recherché :{" "}
                        </span>
                        <span>{currentCandidate.search_contract_type}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Disponibilité : </span>
                        <span>{currentCandidate.availability}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">
                          Expérience professionnelle :{" "}
                        </span>
                        <span>{currentCandidate.current_job_level}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">
                          Situation professionnelle :{" "}
                        </span>
                        <span>{currentCandidate.professional_situation}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Métier ciblé : </span>
                        <span>{currentCandidate.sought_sectors}</span>
                      </li>
                    </InfoList>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-resume"
                    role="tabpanel"
                    aria-labelledby="pills-resume-tab"
                  >
                    {currentCandidate?.resume && (
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                        <div style={{ height: "750px" }}>
                          <Viewer
                            fileUrl={currentCandidate?.resume?.replace(
                              "http://",
                              "https://"
                            )}
                          />
                        </div>
                      </Worker>
                    )}
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-message"
                    role="tabpanel"
                    aria-labelledby="pills-message-tab"
                  >
                    {currentCandidate?.message}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="manageModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Gérer la candidature
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {currentApplication ? (
              <div className="modal-body text-center">
                <LabelRadio
                  onChange={handleInputChange}
                  id="1"
                  label="En Attente"
                  name="status"
                  value={APPLY_STATUS.PENDING}
                  defaultChecked={
                    currentApplication.status === APPLY_STATUS.PENDING
                  }
                />
                <LabelRadio
                  onChange={handleInputChange}
                  id="2"
                  label="Retenue"
                  name="status"
                  value={APPLY_STATUS.ACCEPTED}
                  defaultChecked={
                    currentApplication.status === APPLY_STATUS.ACCEPTED
                  }
                />
                <LabelRadio
                  onChange={handleInputChange}
                  id="3"
                  label="Refusée"
                  name="status"
                  value={APPLY_STATUS.REJECTED}
                  defaultChecked={
                    currentApplication.status === APPLY_STATUS.REJECTED
                  }
                />
              </div>
            ) : (
              ""
            )}
            <div className="modal-footer">
              <button
                ref={cancelRef}
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Annuler
              </button>
              <button
                onClick={manage}
                type="button"
                className="btn btn-primary"
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Application;
