import React from "react";
import MainLayout from "ui/Layout";
import { Router, RouteComponentProps } from "@reach/router";
import AllSectorPage from "./AllSector";
import SectorPage from "./Sector";
import SheetPage from "./Sheet";
import Article from "./Article";

const StaticPage: React.FC<RouteComponentProps> = () => {
  return (
    <MainLayout>
      <Router>
        <AllSectorPage path="/" />
        <Article path="/articles/:slug" />
        <SheetPage path="/:secteur/:slug" />
        <SectorPage path="/:slug" />
      </Router>
    </MainLayout>
  )
}

export default StaticPage;