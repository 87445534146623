import clsx from "clsx";
import React, { ReactElement } from "react";
import styled from "styled-components";

const SwitchBox = styled.div`
  width: 329px;
  height: 41px;
  border-radius: 26px;
  border: solid 1px rgba(112, 112, 112, 0.25);
  background-color: #ffffff;
  display: flex;
  position: relative;
  margin: auto;

  & .MuiButton-root {
    height: inherit;
    flex: 1;
    z-index: 2;
    color: #707070;
    border-radius: 26px;
  }

  &.checked::after {
    content: "";
    width: 50%;
    border-radius: 26px;
    background-color: #512581;
    position: absolute;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
    transform: translateX(0px);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    position: absolute;
    border: solid 1px #512581;
    top: -1px;
    bottom: -1px;
  }

  &.unchecked::after {
    content: "";
    width: 50%;
    top: -1px;
    bottom: -1px;
    right: 50%;
    border-radius: 26px;
    background-color: #512581;
    position: absolute;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
    transform: translateX(165px);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    position: absolute;
  }

  &.checked .btnchecked {
    -webkit-transition: 0.4s;
    transition: 0.4s;
    color: #fff;
  }

  &.unchecked .btnunchecked {
    -webkit-transition: 0.4s;
    transition: 0.4s;
    color: #fff;
  }
`;

const Button = styled.button`
  width: 168px;
  z-index: 1;
`;
interface SwitchLabelProps {
  label: string;
  icon?: ReactElement;
  checked?: boolean;
}

interface SwitchPros {
  primary: SwitchLabelProps;
  secondary: SwitchLabelProps;
  onChange?: (checked: boolean) => void;
}
export default function Switch({ primary, secondary, onChange }: SwitchPros) {
  const handleChange = () => {
    primary.checked = !primary.checked;
    onChange && onChange(primary.checked);
  };

  return (
    <SwitchBox
      className={clsx({
        checked: primary.checked,
        unchecked: !primary.checked,
      })}
    >
      <Button
        className={clsx("btn", {
          btnchecked: primary.checked,
        })}
        onClick={handleChange}
      >
        {primary.label}
      </Button>

      <Button
        className={clsx("btn", {
          btnunchecked: !primary.checked,
        })}
        onClick={handleChange}
      >
        {secondary.label}
      </Button>
    </SwitchBox>
  );
}
