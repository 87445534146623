import React from "react";
import { RouteComponentProps } from "@reach/router";
import axios from "axios";
import { toast } from 'react-toastify';

import styled from "styled-components";
import { SubTitle } from "pages/Job/Sector";
import ReactPlayer from 'react-player';
import RatingInfos from "./RatingInfos";

export const Cover = styled.div`

  position: relative;
  background-size: cover;

  :before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(8,25,43,0.15);
    background-image: -webkit-linear-gradient(rgba(8,25,43,0.15),rgba(8,25,43,0.85));
    background-image: linear-gradient(rgba(8,25,43,0.15),rgba(8,25,43,0.85));
    width: 100%;
    height: 100%;
    content: '';
    z-index: 1;
  }

  .container {
    z-index: 2
  }

`;
export const Icon = styled.span`
  background-color: var(--primary-color);
  color: #fff;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  display: inline-block;
  text-align: center;
  line-height: 2;
`;

const defaultValues = {
  name: '',
  email: '',
  phonenumber: '',
  address: '',
  level: '',
  education: '',
  message: ''
}

const ratingDefaultValues = {
  name: '',
  message: ''
}

interface IProps extends RouteComponentProps {
  name?: string;
}

export default function StudiesPage({ name }: IProps) {
  const [data, setData] = React.useState(null);
  // const [type, setType] = React.useState('Ecoles');

  const [formValues, setFormValues] = React.useState(defaultValues);
  const [ratingFormValues, setRatingFormValues] = React.useState(ratingDefaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [isRatingFormSubmited, setRatingFormSubmited] = React.useState(false);
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const cancelRatingRef = React.useRef<HTMLButtonElement>(null);
  const ratingRef = React.useRef<HTMLInputElement>(null);


  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  const handleRatingInputChange = React.useCallback(
    (event: any) => {
      setRatingFormValues({
        ...ratingFormValues,
        [event.target.name]: event.target.value,
      });
    },
    [ratingFormValues]
  );

  React.useEffect(() => {
    fetch('/api/v1/studies/slug/' + name)
      .then(res => res.json())
      .then((data) => {
        setData(data)
      })
      .catch(console.log);
  }, []);

  const sendMessage = (e: any) => {
    setFormSubmited(true);

    if (!Boolean(formValues.name.trim().length)
      || !Boolean(formValues.phonenumber.trim().length)
      || !Boolean(formValues.email.trim().length)
      || !Boolean(formValues.message.trim().length)
    )
      return;

    axios.post('/api/v1/studies/messages', {
      ...formValues,
      school_id: data.id
    }).then(async res => {
      toast.success("Votre message a bien été envoyé");
      cancelRef.current.click();
      setFormSubmited(false);
      setFormValues(defaultValues);
    });
  }

  const sendRating = (e: any) => {
    setRatingFormSubmited(true);

    if (!Boolean(ratingFormValues.name.trim().length)
      || !Boolean(ratingFormValues.message.trim().length)
      || !ratingRef.current.value
    )
      return;

    axios.post('/api/v1/studies/rating', {
      ...ratingFormValues,
      rating: ratingRef.current.value,
      school_id: data.id
    }).then(async res => {
      toast.success("Votre avis a bien été envoyé");
      cancelRatingRef.current.click();

      setTimeout(() => {
        window.location.reload();
      }, 500)
      setRatingFormSubmited(false);
      setRatingFormValues(ratingDefaultValues);
    });
  }



  return (
    <>
      <Cover className="page-cover-3" style={{ backgroundImage: `url(https://image.freepik.com/free-photo/top-view-different-copybooks-colorful-ones-brown-desk_140725-65866.jpg)` }}>
        <div className="container">
          <div className="row">

            <div className="col-md-8">
              <h1>
                {data?.name}
              </h1>
            </div>
            <div className="col-md-4">
              <div className="logo-card">
                {
                  data?.logo_image &&
                  <img src={data?.logo_image} alt="" width="200" height="200" />
                }
                <br />
                <br />
                {
                  data?.contact_option &&
                  <div>
                    <button type="button" className="btn btn-primary school-card-button" data-toggle="modal" data-target="#exampleModal" >Je m'informe</button>
                  </div>
                }

                <br />

                <div style={{ textAlign: 'left', }}>
                  <p>
                    {
                      data?.email &&
                      <span><Icon className="far fa-envelope" aria-hidden="true"></Icon> <a href={"mailto:" + data?.email} target="_blank">{data?.email}</a> </span>
                    }
                  </p>
                  <p>
                    {
                      data?.phonenumber &&
                      <span><Icon className="fas fa-phone-alt" aria-hidden="true"></Icon> <a href={"tel:" + data?.phonenumber} target="_blank">{data?.phonenumber}</a></span>
                    }
                  </p>
                  <p>
                    {
                      data?.website_link &&
                      <span><Icon className="fas fa-link" aria-hidden="true"></Icon> <a href={data?.website_link} target="_blank">{data?.website_link}</a></span>
                    }
                  </p>

                  <p>
                    {
                      data?.facebook_link &&
                      <span><Icon className="fab fa-facebook-f" aria-hidden="true"></Icon> <a href={data?.facebook_link} target="_blank">{data?.facebook_link}</a></span>
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Cover>

      <div className="container">
        <br />
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Accueil</a></li>
                <li className="breadcrumb-item"><a href="/etudes">Études</a></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div id="fh5co-blog" >
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <SubTitle>Présentation</SubTitle>
              <div dangerouslySetInnerHTML={{ __html: data?.presentation }}>
              </div>
            </div>

            <div className="col-md-8">

              <div style={{ textAlign: 'center' }}>
                {
                  data?.video_link &&
                  <ReactPlayer url={data?.video_link} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        data &&

        <RatingInfos schoolId={data.id} />
      }

      <div className="modal fade" id="ratingModal" role="dialog" aria-labelledby="ratingModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="ratingModalLabel">Donner votre avis</h3>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div id="school-contact-form" className="modal-body">
              <form action="#">
                <div className="row">
                  <div className="col-md-12">
                    <input value={ratingFormValues.name} onChange={handleRatingInputChange} name="name" type="text" id="fname" className="form-control" placeholder="Votre nom" />
                    {
                      isRatingFormSubmited && !Boolean(ratingFormValues.name.trim().length) && (
                        <label className="text-error">Veuillez saisir votre nom</label>
                      )
                    }
                  </div>



                  <div className="col-md-12 form-group">
                    <textarea value={ratingFormValues.message} onChange={handleRatingInputChange} name="message" id="message" cols={30} rows={5} className="form-control" placeholder="Votre message"></textarea>
                    {
                      isRatingFormSubmited && !Boolean(ratingFormValues.message.trim().length) && (
                        <label className="text-error">Veuillez saisir votre message</label>
                      )
                    }
                  </div>

                  <div className="col-md-12 form-group" style={{ textAlign: "center" }}>
                    <div style={{ display: "inline-block" }} id="rateYo"></div>
                    <input ref={ratingRef} type="hidden" name="rating" id="rating_input" />
                  </div>

                </div>
              </form>
            </div>

            <div className="modal-footer">
              <button ref={cancelRatingRef} type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
              <button onClick={sendRating} type="button" className="btn btn-primary">Soumettre</button>
            </div>

          </div>
        </div>
      </div>

      <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">Je m'informe</h3>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div id="school-contact-form" className="modal-body">
              <form action="#">
                <div className="row">
                  <div className="col-md-6">
                    <input value={formValues.name} onChange={handleInputChange} name="name" type="text" id="fname" className="form-control" placeholder="Nom" />
                    {
                      isFormSubmited && !Boolean(formValues.name.trim().length) && (
                        <label className="text-error">Veuillez remplir votre nom</label>
                      )
                    }
                  </div>
                  <div className="col-md-6">
                    <input value={formValues.phonenumber} onChange={handleInputChange} name="phonenumber" type="text" id="phonenumber" className="form-control" placeholder="Téléphone" />
                    {
                      isFormSubmited && !Boolean(formValues.phonenumber.trim().length) && (
                        <label className="text-error">Veuillez remplir votre Téléphone</label>
                      )
                    }

                  </div>

                  <div className="col-md-6">
                    <input value={formValues.email} onChange={handleInputChange} name="email" type="text" id="email" className="form-control" placeholder="Email" />
                    {
                      isFormSubmited && !Boolean(formValues.email.trim().length) && (
                        <label className="text-error">Veuillez remplir votre email</label>
                      )
                    }

                  </div>

                  <div className="col-md-6">
                    <input value={formValues.address} onChange={handleInputChange} name="address" type="text" id="address" className="form-control" placeholder="Lieu de residence" />
                  </div>

                  <div className="col-md-6">
                    <input value={formValues.education} onChange={handleInputChange} name="education" type="text" id="education" className="form-control" placeholder="Formation recherché" />
                  </div>

                  <div className="col-md-6">
                    <input value={formValues.level} onChange={handleInputChange} name="level" type="text" id="level" className="form-control" placeholder="Niveau d'étude" />
                  </div>

                  <div className="col-md-12 form-group">
                    <textarea value={formValues.message} onChange={handleInputChange} name="message" id="message" cols={30} rows={10} className="form-control" placeholder="Votre message"></textarea>
                    {
                      isFormSubmited && !Boolean(formValues.message.trim().length) && (
                        <label className="text-error">Veuillez remplir votre message</label>
                      )
                    }
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer">
              <button ref={cancelRef} type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
              <button onClick={sendMessage} type="button" className="btn btn-primary">Envoyez</button>
            </div>

          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </>
  )
}
