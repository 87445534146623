import React from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import moment from "moment";
import Covers from "pages/Covers";
import Ads from "pages/Ads";

const Wrapper = styled.div`
  background: #f1f1f1;
  padding: 20px;

  .img-responsive {
    height: 250px;
    width: 100%;
  }
`

const Title = styled.div`
  font-size: 30px;
  color: var(--primary-color);
  font-weight: bold;
`

const Date = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: var(--secondary-color);
  padding: 15px 0;
`

const ArticleCover = styled.div`
  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
`

export const Desc = styled.p`
  background-color: #fff;
  padding: 10px 10px;
  border-left: 3px solid #FF4C28;
`

interface IProps extends RouteComponentProps {
  slug?: string;
}

export default function SheetPage({ slug }: IProps) {
  const [sheet, setSheet] = React.useState(null);
  const [cityQuery, setCityQuery] = React.useState(null);
  const [nameQuery, setNameQuery] = React.useState('');

  React.useEffect(() => {
    fetch('/api/v1/articles/studies_news/public/slug/' + slug)
      .then(res => res.json())
      .then((data) => {
        setSheet(data);
      })
      .catch(console.log)
  }, []);

  const onSearch = () => {
    let path = '/etudes/recherche/';
    if (cityQuery)
      path += `?lieu=${cityQuery}&`;

    if (nameQuery) {
      path += !cityQuery ? '?' : '';
      path += `ecole=${nameQuery}`;
    }

    window.location.href = path;
  }

  return (
    <>
      <Covers page="studies_home" />

      <div className="container">
        <div className="search-container">
          <div className="row">
            <div className="col-md-5">
              <input onChange={e => setNameQuery(e.target.value)} placeholder="Ecole Élémentaire, Secondaire, Supérieur" className="form-control" type="text" />
            </div>
            <div className="col-md-5">
              <input onChange={e => setCityQuery(e.target.value)} placeholder="Où ?" className="form-control" type="text" />

            </div>
            <div className="col-md-2">
              <button onClick={onSearch} className="search-button btn-rounded">Rechercher</button>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <br />
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Accueil</a></li>
                <li className="breadcrumb-item"><a href="/etudes">Études</a></li>
                {/* <li className="breadcrumb-item active" aria-current="page">Data</li> */}
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <br />
            <Wrapper>
              <Title>{sheet?.name}</Title>
              <Date>{moment(sheet?.createdAt).format('MMMM DD, YYYY')}</Date>
              {
                sheet?.description &&
                <Desc>{sheet?.description}</Desc>
              }

              {
                sheet?.cover_image &&
                <ArticleCover style={{ backgroundImage: `url(${sheet?.cover_image})` }} />
              }
              <br />
              <div dangerouslySetInnerHTML={{ __html: sheet?.presentation }}></div>
            </Wrapper>
            <br />
          </div>
          <div className="col-md-4">
            <Ads sticky page="studies_home" />
          </div>
        </div>
      </div>
    </>
  )
}
