import React from "react";
import { RouteComponentProps } from "@reach/router";
import axios from "axios";
import JobItem from "./JobItem";
import ShowMoreOfferBtn from "ui/Button/ShowMoreOffer";

const TopJobOffers: React.FC<RouteComponentProps> = () => {
  const [data, setData] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    axios.get('/api/v1/joboffers/ontop')
      .then(res => {
        if (res.status === 200) {
          setData(res.data)
        }
      });
  }, []);

  return (
    <div className="card-simple sticky-top">
      <div>
        <h4 className="page-title">Offres d'emploi à la une</h4>
      </div>
      <hr />
      <div style={{ overflow: "scroll", height: "500px" }}>
        <div className="row">
          {
            data &&
            data.map((item, key) =>
              <JobItem item={item} key={key} />
            )
          }
        </div>

      </div>
      <div className="row">
        <div className="col-md-12">
          <ShowMoreOfferBtn />
        </div>
      </div>
    </div>
  )
}

export default TopJobOffers;