
import React from "react";
import HeaderJobOffer from 'ui/Header/HeaderJobOffer'
import TinyFooter from 'ui/Footer/TinyFooter'
import styled from "styled-components";
import "./style.css";
import { Header } from "./style";

const Cover = styled.div`
  width: 100%;
  background-color: white;
  height: 150px;
  background-image: url(/images/profile1.jpg);
  background-position: 50% 1%;
  background-size: cover;
`;

const ParticulierLayout = (props: any) => {
  const { children } = props;

  return (
    <div id="page">
      <HeaderJobOffer redirectPath="/soutien-scolaire" />
      <Header>
        <Cover />
        <div className="container" >
          <div className="row">
            <div className="col-md-12 fh5co-nav">
              <div className=" menu-1">
                <ul>
                  <li className={window.location.pathname.includes('interventions') ? 'active' : ''}>
                    <a href="/soutien-scolaire/e/enseignant/interventions">Interventions</a>
                  </li>

                  <li className={window.location.pathname.includes('profil') ? 'active' : ''}>
                    <a href="/soutien-scolaire/e/enseignant/profil">Domaine d'intervention</a>
                  </li>

                  <li className={window.location.pathname.includes('cv') ? 'active' : ''}>
                    <a href="/soutien-scolaire/e/enseignant/cv">CV</a>
                  </li>
                  <li className={window.location.pathname.includes('contacts') ? 'active' : ''}><a href="/soutien-scolaire/e/enseignant/contacts">Coordonnées</a></li>
                  <li className={window.location.pathname.includes('parmaretres') ? 'active' : ''}>
                    <a href="/soutien-scolaire/e/enseignant/parmaretres">Paramètres</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Header>
      <div style={{ paddingBottom: "90px" }}>
        {children}
      </div>
      <TinyFooter />
    </div>
  )
}

export default ParticulierLayout;