import { navigate, RouteComponentProps } from "@reach/router";
import axios from "axios";
import clsx from "clsx";
import { useAuthToken } from "hooks/useAuthToken";
import moment from "moment";
import { OverviewCard } from "pages/style";
import React from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import TextField from "ui/TextField";
import * as yup from "yup";

const formSchema = yup.object().shape({
  title: yup.string().required(),
  reference: yup.string().required(),
  contract_type: yup.string().required(),
  contract_length: yup.string().required(),
  contract_start_date: yup.string().required(),
  region: yup.string().required(),
  city: yup.object().required(),
  address: yup.string().required(),
  description: yup.string().required(),
  profile: yup.string().required(),
  required_training: yup.array().required(),
  required_experience: yup.array().required(),
  study_level: yup.array().required(),
});

interface IForm {
  id?: string;
  title: string;
  reference: string;
  contract_type: string;
  contract_length: string;
  contract_start_date: string;
  remuneration: string;
  region: string;
  city: any;
  address: string;
  description: string;
  profile: string;
  required_training: any;
  required_experience: any;
  study_level: any;
}

const defaultValues: IForm = {
  title: "",
  reference: "",
  contract_type: "",
  contract_length: "",
  contract_start_date: moment(new Date()).format("yyyy-MM-DD"),
  // contract_start_date: "",
  remuneration: "",
  region: "",
  city: "",
  address: "",
  description: "",
  profile: "",
  required_training: [],
  required_experience: [],
  study_level: [],
};

interface ApplicationProps extends RouteComponentProps {
  jobid?: string;
}

const NewApplication = ({ jobid }: ApplicationProps) => {
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [submitted, setSubmited] = React.useState(false);
  const [cities, setCities] = React.useState([]);

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );
  const [setting, setSetting] = React.useState(null);

  const [authToken] = useAuthToken();

  const stringToArray = (str: string): any[] => {
    if (str.length > 0) {
      return str
        .split(",")
        .map((item: string) => ({ value: item, label: item }));
    }
    return [];
  };

  React.useEffect(() => {
    if (jobid) {
      axios
        .get("/api/v1/joboffers/jobid/" + jobid, {
          headers: {
            ...(Boolean(authToken)
              ? { Authorization: `Bearer ${authToken}` }
              : {}),
          },
        })
        .then((res) => {
          if (res.status === 200 && res.data) {
            const data = res.data;
            setFormValues({
              id: data.id,
              title: data.title,
              reference: data.reference,
              contract_type: data.contract_type,
              contract_length: data.contract_length,
              contract_start_date: data.contract_start_date,
              remuneration: data.remuneration,
              region: data.region,
              city: { value: data.city, label: data.city },
              address: data.address,
              description: data.description,
              profile: data.profile,
              required_training: stringToArray(data.required_training),
              required_experience: stringToArray(data.required_experience),
              study_level: stringToArray(data.required_study_level),
            });
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            toast.error("Cette offre n'existe pas");
            navigate("/stage-et-emploi/e/entreprise/candidatures/", {
              replace: true,
            });
          } else {
            toast.error("Une erreur s'est produite");
          }
        });
    }

    axios("/api/v1/settings/stage-et-emploi").then((res) => {
      setSetting(res.data);

      setFormValues((prev) => ({
        ...prev,
        contract_type: res.data?.contractType[0]?.name || "",
      }));
    });
    fetch("/api/v1/studies/cities")
      .then((res) => res.json())
      .then((data) => {
        setCities(data);
      });
  }, [jobid]);

  const onSubmit = (event: any) => {
    event.preventDefault();
    setSubmited(true);

    if (!formSchema.isValidSync(formValues)) return;

    if (jobid) {
      axios
        .put(
          "/api/v1/joboffers/" + formValues.id,
          {
            ...formValues,
            city: formValues.city?.value,
            required_experience: formValues.required_experience
              .map((item: any) => item.value)
              .join(","),
            required_training: formValues.required_training
              .map((item: any) => item.value)
              .join(","),
            study_level: formValues.study_level
              .map((item: any) => item.value)
              .join(","),
          },
          {
            headers: {
              ...(Boolean(authToken)
                ? { Authorization: `Bearer ${authToken}` }
                : {}),
            },
          }
        )
        .then((res) => {
          toast.success("Offre editée avec succès");
          setTimeout(() => {
            window.location.href = "/stage-et-emploi/e/entreprise/candidatures";
          }, 1500);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404)
            toast.error("Cette offre n'exixte pas");
          else toast.error("Erreur lors de l'edition de l'offre");
        });
    } else {
      axios
        .post(
          "/api/v1/joboffers",
          {
            ...formValues,
            city: formValues.city?.value,
            required_experience: formValues.required_experience
              .map((item: any) => item.value)
              .join(","),
            required_training: formValues.required_training
              .map((item: any) => item.value)
              .join(","),
            study_level: formValues.study_level
              .map((item: any) => item.value)
              .join(","),
          },
          {
            headers: {
              ...(Boolean(authToken)
                ? { Authorization: `Bearer ${authToken}` }
                : {}),
            },
          }
        )
        .then((res) => {
          toast.success("Offre créée avec succès");
          setTimeout(() => {
            window.location.href = "/stage-et-emploi/e/entreprise/candidatures";
          }, 1500);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404)
            toast.error("Cette offre n'exixte pas");
          else toast.error("Erreur lors de l'edition de l'offre");
        });
    }
  };

  return (
    <div className="container">
      <div className="row">
        <OverviewCard>
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-lg-12 col-md-6">
                <h4 className="page-title">
                  {jobid
                    ? `Edition d'offre : ${formValues.title}`
                    : `Nouvelle offre : ${formValues.title}`}
                </h4>
              </div>
              <div className="col-lg-12"></div>
              <div className="col-lg-4">
                <TextField
                  label="Titre *"
                  name="title"
                  type="text"
                  value={formValues.title}
                  error={submitted && !Boolean(formValues.title)}
                  helpText={
                    submitted &&
                    !Boolean(formValues.title) &&
                    "Veuillez saisir le titre de l'offre"
                  }
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-4">
                <TextField
                  label="Réference"
                  name="reference"
                  type="text"
                  value={formValues.reference}
                  onChange={handleInputChange}
                  error={submitted && !Boolean(formValues.reference)}
                  helpText={
                    submitted &&
                    !Boolean(formValues.reference) &&
                    "Veuillez saisir la reference de l'offre"
                  }
                />
              </div>
              <div className="col-lg-4">
                <label>Type de contrat</label>
                <select
                  name="contract_type"
                  className={"form-control"}
                  onChange={handleInputChange}
                >
                  {setting?.contractType.map((c: any, key: any) => (
                    <option key={key} value={c.name}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-4">
                <TextField
                  label="Durée du contrat"
                  name="contract_length"
                  value={formValues.contract_length}
                  onChange={handleInputChange}
                  error={submitted && !Boolean(formValues.contract_length)}
                  helpText={
                    submitted &&
                    !Boolean(formValues.contract_length) &&
                    "Veuillez saisir la durée du contrat"
                  }
                />
              </div>
              <div className="col-lg-4">
                <TextField
                  label="Date de debut"
                  name="contract_start_date"
                  type="date"
                  value={formValues.contract_start_date}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-4">
                <TextField
                  label="Rémuneration"
                  name="remuneration"
                  type="text"
                  value={formValues.remuneration}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-4">
                <TextField
                  label="Région"
                  name="region"
                  type="text"
                  value={formValues.region}
                  onChange={handleInputChange}
                  error={submitted && !Boolean(formValues.region)}
                  helpText={
                    submitted &&
                    !Boolean(formValues.region) &&
                    "Veuillez saisir la region"
                  }
                />
              </div>
              <div className="col-lg-4">
                <label>Ville</label>
                <Select
                  options={cities?.map((item: any) => ({
                    value: item.name,
                    label: item.name,
                  }))}
                  name="city"
                  value={formValues.city}
                  onChange={(value, action) => {
                    setFormValues({
                      ...formValues,
                      city: value,
                    });
                  }}
                  className={clsx({
                    "is-invalid": submitted && !Boolean(formValues.city),
                  })}
                />
                <div className="invalid-feedback">
                  {submitted &&
                    !Boolean(formValues.city) &&
                    "Veuillez choisir la ville"}
                </div>
              </div>
              <div className="col-lg-4">
                <TextField
                  label="Adresse"
                  name="address"
                  type="text"
                  value={formValues.address}
                  onChange={handleInputChange}
                  error={submitted && !Boolean(formValues.address)}
                  helpText={
                    submitted &&
                    !Boolean(formValues.address) &&
                    "Veuillez saisir l'adresse"
                  }
                />
              </div>
              <div className="col-lg-6">
                <fieldset>
                  <label>Description</label>
                  <textarea
                    name="description"
                    cols={30}
                    rows={5}
                    value={formValues.description}
                    onChange={handleInputChange}
                    className={clsx("form-control", {
                      "is-invalid":
                        submitted && !Boolean(formValues.description),
                    })}
                  ></textarea>
                  <div className="invalid-feedback">
                    {submitted &&
                      !Boolean(formValues.description) &&
                      "Veuillez saisir une description"}
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-6">
                <fieldset>
                  <label>Profil recherché</label>
                  <textarea
                    name="profile"
                    cols={30}
                    rows={5}
                    value={formValues.profile}
                    onChange={handleInputChange}
                    className={clsx("form-control", {
                      "is-invalid": submitted && !Boolean(formValues.profile),
                    })}
                  ></textarea>
                  <div className="invalid-feedback">
                    {submitted &&
                      !Boolean(formValues.profile) &&
                      "Veuillez décrire le profile rechercher"}
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-6">
                <label>Formation requise</label>
                <Select
                  options={setting?.requiredTraining?.map((item: any) => ({
                    value: item.name,
                    label: item.name,
                  }))}
                  isMulti
                  name="required_training"
                  value={formValues.required_training}
                  onChange={(value, action) => {
                    setFormValues({
                      ...formValues,
                      required_training: value,
                    });
                  }}
                  className={clsx({
                    "is-invalid":
                      submitted &&
                      !Boolean(formValues.required_training.length),
                  })}
                />
                <div className="invalid-feedback">
                  {submitted &&
                    !Boolean(formValues.required_training.length) &&
                    "Veuillez choisir la formation réquise"}
                </div>
              </div>

              <div className="col-lg-6">
                <label>Experience requise</label>
                <Select
                  options={setting?.experienceLevel?.map((item: any) => ({
                    value: item.name,
                    label: item.name,
                  }))}
                  isMulti
                  name="required_experience"
                  value={formValues.required_experience}
                  onChange={(value, action) => {
                    setFormValues({
                      ...formValues,
                      required_experience: value,
                    });
                  }}
                  className={clsx({
                    "is-invalid":
                      submitted &&
                      !Boolean(formValues.required_experience.length),
                  })}
                />
                <div className="invalid-feedback">
                  {submitted &&
                    !Boolean(formValues.required_experience.length) &&
                    "Veuillez choisir le niveau d'experience"}
                </div>
              </div>

              <div className="col-md-6">
                <fieldset>
                  <label>Niveau d'études</label>
                  <Select
                    options={setting?.schoolLevel?.map((item: any) => ({
                      value: item.name,
                      label: item.name,
                    }))}
                    isMulti
                    name="study_level"
                    value={formValues.study_level}
                    onChange={(value, action) => {
                      setFormValues({
                        ...formValues,
                        study_level: value,
                      });
                    }}
                    className={clsx({
                      "is-invalid":
                        submitted && !Boolean(formValues.study_level.length),
                    })}
                  />
                  <div className="invalid-feedback">
                    {submitted &&
                      !Boolean(formValues.study_level.length) &&
                      "Veuillez choisir le niveau d'études"}
                  </div>
                </fieldset>
              </div>

              <div className="col-lg-12 text-right">
                <br />
                <button type="reset" className="btn btn-default">
                  Annuler
                </button>
                <button type="submit" className="btn btn-primary">
                  Valider
                </button>
              </div>
            </div>
          </form>
        </OverviewCard>
      </div>
    </div>
  );
};

export default NewApplication;
