import styled from "styled-components";

export const TabsWapper = styled.div`
  padding: 24px;
`

export const TabWapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const TabHeader = styled.div`
  margin-bottom: 40px;
  color: #BE1823;

  & h4 {
    font-weight: bold;
    margin-bottom: 0;
    color: #0BA094
  }
`

export const Main = styled.div`
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`

export const TabFooter = styled.div`
  margin-top: auto;
  padding-top: 40px;
  display: flex;

  & .btn {
    padding: 0 20px;
    height: 48px;
    font-weight: bold;
    width: 125px;
  }

  & .btn.back {
    border: 1px solid #777;
    color: #777;
  }

  & .btn.next {
    background-color: var(--primary-bg);
    color: #fff;
    margin-left: auto;
  }
`

export const Button = styled.button`

`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 2rem;
  color: var(--primary-color);
  padding-top: 5px;
`
export const Overview = styled.div`
  padding: 50px 25px;
  min-height: 100vh;
  color: #fff;
  background-image: url("/images/teacher-signup-bg.jpg");
  background-size: cover;
  background-position: 50% 50%;
  z-index: 2;

  p {
    color: #fff;
  }

  :after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(35, 50, 102, 0.4);
    z-index: -1;
  } 
`;


export const Flow = styled.div`
  padding: 0;
`;