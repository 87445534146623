import React from "react";

export interface ITeacherCreation {
  courses: Array<any>;
  courseLevels: Array<any>;
  tabValue: number;
  info: any;
  profile: any;
  resume: any;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  setInfo: React.Dispatch<React.SetStateAction<any>>;
  setProfile: React.Dispatch<React.SetStateAction<any>>;
  setResume: React.Dispatch<React.SetStateAction<any>>;
  setCourses: React.Dispatch<React.SetStateAction<Array<any>>>;
  setCourseLevels: React.Dispatch<React.SetStateAction<Array<any>>>;
}
export const TeacherCreationContext =
  React.createContext<ITeacherCreation>(null);

const TeacherCreationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [tabValue, setTabValue] = React.useState<number>(0);
  const [info, setInfo] = React.useState<any>(null);
  const [courses, setCourses] = React.useState<Array<any>>([]);
  const [courseLevels, setCourseLevels] = React.useState<Array<any>>([]);
  const [profile, setProfile] = React.useState<any>(null);
  const [resume, setResume] = React.useState<any>(null);
  return (
    <TeacherCreationContext.Provider
      value={{
        profile,
        courseLevels,
        courses,
        tabValue,
        info,
        resume,
        setInfo,
        setTabValue,
        setCourses,
        setCourseLevels,
        setProfile,
        setResume,
      }}
    >
      {children}
    </TeacherCreationContext.Provider>
  );
};

export default TeacherCreationProvider;
