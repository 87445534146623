import React from "react";
import { navigate } from "@reach/router";
import styled from "styled-components";
import ApplicationItem from "./ApplicationItem";
import axios from "axios";
import { useAuthToken } from "hooks/useAuthToken";
import { OverviewCard, OverviewCardHeader } from "pages/style";
import TextField from "ui/TextField";
import { toast } from "react-toastify";
import { format } from "date-fns";

export const INTRV_STATUS_COLOR = {
  TO_START: {
    bg: "#0D5593",
    text: "À Debuter",
  },
  START: {
    bg: "#098664",
    text: "En cours",
  },
  CANCEL: {
    bg: "#098664",
    text: "Annuler",
  },
  CONTENTIOUS: {
    bg: "#098664",
    text: "Litigieux",
  },
  ABORT: {
    bg: "#098664",
    text: "Abandon",
  },
  END: {
    bg: "#098664",
    text: "Terminer",
  },
};

interface ApplicationsProps {
  code?: string;
}

const defaultValues = {
  start_time: "",
  end_time: "",
  start_date: format(new Date(), "yyyy/MM/dd"),
  code: "",
};

const Applications = ({ code }: ApplicationsProps) => {
  const [data, setData] = React.useState<Array<any>>([]);
  const [authToken] = useAuthToken();
  const [current, setCurrent] = React.useState(null);
  const [hours, setHours] = React.useState(null);
  const [hoursP, setHoursP] = React.useState(null);

  let cancelRef = React.useRef<HTMLButtonElement>(null);
  const [formValues, setFormValues] = React.useState(defaultValues);

  React.useEffect(() => {
    axios
      .get("/api/v1/teachers/my/interventions/followups/" + code, {
        headers: {
          ...(Boolean(authToken)
            ? { Authorization: `Bearer ${authToken}` }
            : {}),
        },
      })
      .then((res) => {
        setData(res.data);
        let _p = 0;
        for (let i = 0; i < res.data.length; i++) {
          _p += res.data[i].number_of_hours;
        }
        setHoursP(_p);
      });

    axios
      .get("/api/v1/tutoring/interventions/bycode/" + code, {
        headers: {
          ...(Boolean(authToken)
            ? { Authorization: `Bearer ${authToken}` }
            : {}),
        },
      })
      .then((res) => {
        setCurrent(res.data);
        setHours(res.data.hours_affected);
      });
  }, []);

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  const handleSend = () => {
    axios
      .post(
        `/api/v1/teachers/my/interventions/${code}/add-followups/`,
        {
          ...formValues,
        },
        {
          headers: {
            ...(Boolean(authToken)
              ? { Authorization: `Bearer ${authToken}` }
              : {}),
          },
        }
      )
      .then(async (res) => {
        // handleClose();
        toast.success("Intervention ajoutée avec succès");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  };

  return (
    <div className="container">
      <div className="row">
        <OverviewCard className="col-lg-12">
          <OverviewCardHeader>
            <h4 className="page-title animate-box">
              Suivi intervention : {code}
            </h4>
            {current?.status === "START" && (
              <button
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Ajouter un suivi
              </button>
            )}
          </OverviewCardHeader>
          {current &&
            current?.status === "START" &&
            hoursP > (current.hours_affected * 3) / 4 && (
              <div className="alert alert-danger" role="alert">
                Alerte consommation : Le crédit d'heures de cette intervention
                est bientôt terminé
              </div>
            )}

          {data.length > 0 ? (
            <table className="table table-bordered table-hover">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Date d'intervention</th>
                  <th scope="col">Nbr d'heures</th>
                  <th scope="col">Heure de début</th>
                  <th scope="col">Heure de fin</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item: any, key: number) => (
                  <tr key={key}>
                    <td>{item.start_date} </td>
                    <td>{item.number_of_hours} </td>
                    <td>{item.start_time} </td>
                    <td>{item.end_time} </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <>Rien à afficher</>
          )}
        </OverviewCard>
      </div>
      <br />
      <br />

      <div
        className="modal fade"
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Ajouter une intervention
              </h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div>
                <TextField
                  value={formValues.code}
                  onChange={handleInputChange}
                  label="Code de validation *"
                  name="code"
                  type="text"
                  required
                  // error={submitted && !Boolean(formValues.firstname)}
                  // helpText={
                  //   submitted &&
                  //   !Boolean(formValues.firstname) &&
                  //   "Veuillez saisir votre nom"
                  // }
                />

                <br />
                <TextField
                  value={formValues.start_date}
                  onChange={handleInputChange}
                  label="Date d'intervention *"
                  name="start_date"
                  type="date"
                  required
                />

                <br />
                <TextField
                  value={formValues.start_time}
                  onChange={handleInputChange}
                  label="Heure début *"
                  name="start_time"
                  type="time"
                  required
                />

                <br />
                <TextField
                  value={formValues.end_time}
                  onChange={handleInputChange}
                  label="Heure de fin *"
                  name="end_time"
                  type="time"
                  required
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                ref={cancelRef}
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Annuler
              </button>
              <button
                onClick={handleSend}
                type="button"
                className="btn btn-primary"
              >
                Envoyer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applications;
