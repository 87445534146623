import React from "react";
import { OverviewCard, OverviewCardHeader } from "pages/style";
import axios from "axios";
import { useAuthToken } from "hooks/useAuthToken";
import { INTRV_STATUS_COLOR } from "../Teacher/Interventions";
import StatusInfo from "ui/StatusInfo";

export default function Interventions({}) {
  const [data, setData] = React.useState<Array<any>>([]);
  const [authToken] = useAuthToken();

  React.useEffect(() => {
    axios
      .get("/api/v1/families/my/interventions", {
        headers: {
          ...(Boolean(authToken)
            ? { Authorization: `Bearer ${authToken}` }
            : {}),
        },
      })
      .then((res) => {
        setData(res.data);
      });
  }, []);

  return (
    <div className="container">
      <OverviewCard>
        <div className="row">
          <OverviewCardHeader className="col-md-12">
            <h4 className="page-title">Interventions</h4>
          </OverviewCardHeader>

          <div className="col-md-12">
            {data.length > 0 ? (
              <table className="table table-bordered table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Code</th>
                    <th scope="col">Date de début</th>
                    <th scope="col">Fréquence d'intervention</th>
                    <th scope="col">Enseignement</th>
                    <th scope="col">Elève</th>
                    <th scope="col">Matière(s)</th>
                    <th scope="col">Nbre d'heures affecté</th>
                    <th scope="col">Nbre d'heures effectuées</th>
                    <th scope="col">Statut</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item: any, key: number) => (
                    <tr key={key}>
                      <td>{item.code} </td>
                      <td>{item.start_date} </td>
                      <td>{item.frequency} </td>
                      <td>
                        {item.teacher.firstname} {item.teacher.lastname}
                      </td>
                      <td>{item?.student?.lastname}</td>
                      <td>
                        {item?.courses.map(
                          (st: any, i: any) =>
                            st.course.name +
                            (i + 1 !== item?.courses.length ? ", " : "")
                        )}
                      </td>
                      <td>{item.hours_affected}</td>
                      <td>{item.number_of_hours || 0}</td>
                      <td>
                        <StatusInfo
                          width="80px"
                          background={
                            INTRV_STATUS_COLOR[
                              item.status === "START" &&
                              item.number_of_hours >=
                                (item.hours_affected * 3) / 4
                                ? "START_ALERT"
                                : item.status
                            ]["bg"]
                          }
                          status={INTRV_STATUS_COLOR[item.status]["text"]}
                        />
                      </td>
                      <td>
                        <a
                          href={
                            "/soutien-scolaire/e/famille/interventions/" +
                            item.code
                          }
                          className="btn btn-primary"
                        >
                          Suivi
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <>Rien à afficher</>
            )}
          </div>
        </div>
      </OverviewCard>
    </div>
  );
}
