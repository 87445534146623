import React, { useEffect } from "react";
import Routes from "./pages/Routes";
import "./App.css";
import { LocationProvider } from "@reach/router";
import { CookiesProvider } from "react-cookie";
import { ToastContainer } from "react-toastify";
import ReactGA from "react-ga4";
import "react-toastify/dist/ReactToastify.css";

const TRACKING_ID = "G-7LVXPH4P2E";
function App() {
  // ReactGA.initialize("G-WRMEHLJF1P");

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({
      // hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }, []);

  return (
    <CookiesProvider>
      <LocationProvider>
        <Routes />
        <ToastContainer
          position="top-center"
          autoClose={10000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </LocationProvider>
    </CookiesProvider>
  );
}

export default App;
