import React from "react";
import { RouteComponentProps } from "@reach/router";
import CourseInfo from "./CourseInfo";
import CvInfo from "./CvInfo";
import ProfileInfo from "./ProfileInfo";
import AccountInfo from "./AccountInfo";
import { TeacherCreationContext } from "context/TeacherCreation";
import SuccessInfo from "./SuccessInfo";
import Header from "ui/Header/SignupTeacher";
import axios from "axios";
import { Flow, Main, Overview, TabsWapper } from "pages/Auth/style";

interface TabProps {
  value: number;
  index: number;
  children?: React.ReactNode;

}

const Tab = ({ index, value, children }: TabProps) => {
  return (
    <>
      {
        value === index && (
          <>
            { children}
          </>
        )
      }
    </>
  )
}

const steps = [
  {
    label: 'Enseignement',
    index: 0
  },
  {
    label: 'Parcours',
    index: 1
  },
  {
    label: 'Informations',
    index: 2
  },
  {
    label: 'Compte',
    index: 3
  },
]
const Auth: React.FC<RouteComponentProps> = () => {
  const { tabValue } = React.useContext(TeacherCreationContext);

  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    axios('/api/v1/tutoring/page-text/3')
      .then(res => {
        setData(res.data)
      })
  }, []);

  return (
    <Main>
      <div className="row">
        <Flow className="col-md-8">
          <Header steps={steps} current={tabValue} />
          <TabsWapper className="col-md-9 mx-auto">
            <Tab value={tabValue} index={0}>
              <CourseInfo />
            </Tab>
            <Tab value={tabValue} index={1}>
              <CvInfo />
            </Tab>
            <Tab value={tabValue} index={2}>
              <ProfileInfo />
            </Tab>
            <Tab value={tabValue} index={3}>
              <AccountInfo />
            </Tab>
            <Tab value={tabValue} index={4}>
              <SuccessInfo />
            </Tab>
          </TabsWapper>
        </Flow>
        <Overview className="col-md-4">
          <div dangerouslySetInnerHTML={{ __html: data?.presentation }}></div>
          <a href="/soutien-scolaire/enseignant" className="btn btn-primary">En savoir plus</a>
        </Overview>
      </div>
    </Main>
  )
}

export default Auth;