import React from "react";
import Item from "./Item";
import styled from "styled-components";
export const Count = styled.span`
  color: #70757a;
  padding: 10px 0;
  display: inline-block;
`;
interface IProps {
  data: any;
}

export default function SearchResults({ data }: IProps) {
  return (
    <>
      <div className="row">
        {
          data.length > 0 &&
          <div className="col-md-12">
            <Count>
              {data.length} résultat{data.length > 1 && "s"}
            </Count>
          </div>
        }
        {
          data.map((item: any, key: string) => (
            <div key={key} className="col-md-12">
              <Item item={item} />
            </div>
          ))
        }

        {
          !data.length && (
            <div className="col-md-12">
              <br />
              <div className="card-simple text-center">
                <br />
                <img src="/images/search-empty-icon.png" alt="" />
                <p>
                  <br />
                  <h4>Aucun résultat</h4>
                </p>
                <br />
              </div>
            </div>
          )
        }
      </div>
    </>
  )
}
