import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Ul } from "pages/JobOffer/JobDetails";
import Covers from "pages/Covers";
import { FloatTitle, SubTitle, Title, Wrapper } from "./Sector";
import SearchBar from "./SearchBar";
import styled from "styled-components";
import Ads from "pages/Ads";
import Adser from "pages/Adser";

export const Cover = styled.div`
  position: relative;
  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;
  border-radius: 5px;

   :before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(8,25,43,0.15);
    background-image: -webkit-linear-gradient(rgba(8,25,43,0.15),rgba(8,25,43,0.85));
    background-image: linear-gradient(rgba(8,25,43,0.15),rgba(8,25,43,0.85));
    width: 100%;
    height: 100%;
    content: '';
    z-index: 1;
    border-radius: 5px;
  }

  .container {
    z-index: 2
  }
`

interface IProps extends RouteComponentProps {
  slug?: string;
}

export default function SheetPage({ slug }: IProps) {
  const [sheet, setSheet] = React.useState(null);

  React.useEffect(() => {
    fetch('/api/v1/sheets/slug/' + slug)
      .then(res => res.json())
      .then((data) => {
        setSheet(data);
      })
      .catch(console.log)
  }, []);

  return (
    <>

      {sheet ?
        (
          <>
            <div id="fh5co-blog" style={{ paddingTop: "2em" }}>
              <Wrapper className="container">
                <div className="row">
                  <Wrapper className="col-md-12">
                    {
                      (sheet?.cover_image) ?
                        <>
                          <Cover style={{ backgroundImage: `url(${sheet?.cover_image})` }} />
                          <FloatTitle>{sheet.name}</FloatTitle>
                        </>
                        :
                        <Title>{sheet.name}</Title>
                    }

                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                              <li className="breadcrumb-item"><a href="/">Accueil</a></li>
                              <li className="breadcrumb-item"><a href="/fiches-metiers">Fiches Métiers</a></li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <SearchBar />
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-md-12">
                        <Ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                          <li className="nav-item">
                            <a className="nav-link" href="#presentation">Présentation</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#cursus" aria-controls="pills-profile" >Cursus</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#remuneration" >Rémunération</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#debouches" >Débouchés</a>
                          </li>
                        </Ul>
                      </div>
                    </div>
                  </Wrapper>
                </div>
                <br />

                <div className="row">
                  <div className="col-md-8">
                    <div id="presentation" className="row">
                      <div className="col-md-12">
                        <SubTitle>Présentation</SubTitle>
                      </div>
                      <div className="col-md-12 slider-text">
                        <div className="slider-text-inner">
                          <div dangerouslySetInnerHTML={{ __html: sheet.presentation }}>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />

                    <div id="cursus" className="row">
                      <div className="col-md-12">
                        <SubTitle>Cursus</SubTitle>
                      </div>
                      <div className="col-md-8 slider-text">
                        <div className="slider-text-inner">
                          <div dangerouslySetInnerHTML={{ __html: sheet.curriculum }}>
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />

                    <div id="remuneration" className="row">
                      <div className="col-md-12">
                        <SubTitle>Rémunération</SubTitle>
                      </div>
                      <div className="col-md-8 slider-text">
                        <div className="slider-text-inner">
                          <div dangerouslySetInnerHTML={{ __html: sheet.remuneration }}>
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />

                    <div id="debouches" className="row">
                      <div className="col-md-12">
                        <SubTitle>Débouchés</SubTitle>
                      </div>
                      <div className="col-md-8 slider-text">
                        <div className="slider-text-inner">
                          <div dangerouslySetInnerHTML={{ __html: sheet.opportunities }}>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-md-4">
                    <Adser />
                    <br />
                    <Ads sticky page="sheet_home" />
                  </div>
                </div>

              </Wrapper>
            </div>
          </>
        )
        : " "
      }
    </>
  )
}
