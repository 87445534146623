import React from "react";
import axios from "axios";
import TextField from "ui/TextField";
import { useAuthToken } from "hooks/useAuthToken";
import useCurrentParticular from "hooks/useCurrentParticular";
import { toast } from 'react-toastify';
import Select from 'react-select';
import { OverviewCard, OverviewCardHeader } from "../../style";

interface IForm {
  firstname: string;
  lastname: string;
  phonenumber: string;
  email?: string;
  country: any;
  address: string;
  city: any;
}

const defaultValues: IForm = {
  firstname: "",
  lastname: "",
  phonenumber: "",
  country: "",
  address: "",
  city: "",
}

export default function Application({ }) {
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [submitted, setSubmited] = React.useState(false);

  const [countries, setCountries] = React.useState([]);
  const [cities, setCities] = React.useState([]);


  const [authToken] = useAuthToken();
  const [currentUser] = useCurrentParticular();

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  React.useEffect(() => {
    axios.get("/api/v1/settings/countries").then(res => {
      setCountries(res.data);
    });

    axios.get("/api/v1/studies/cities").then(res => {
      setCities(res.data);
    });
  }, []);

  React.useEffect(() => {
    if (currentUser) {
      setFormValues({
        firstname: currentUser.firstname,
        lastname: currentUser.lastname,
        phonenumber: currentUser.phonenumber,
        email: currentUser.email,
        address: currentUser.address,
        city: { value: currentUser.city, label: currentUser.city },
        country: { value: currentUser.country, label: currentUser.country },
      })
    }
  }, [currentUser]);

  const onSubmit = (event: any) => {
    event.preventDefault();
    setSubmited(true);

    axios.put("/api/v1/candidats/contact", {
      ...formValues,
      city: formValues.city?.value,
      country: formValues.country?.value,
    }, {
      headers: {
        ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {}),
      }
    }).then(res => {
      toast.success("Coordonnées modifiées avec succès");
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    })
      .catch(error => {
        toast.error("Erreur!");
      });
  }

  return (
    <div className="container">
      <OverviewCard>
        <div className="row">
          <OverviewCardHeader className="col-md-12">
            <h4 className="page-title">Coordonnées</h4>
          </OverviewCardHeader>

          <div className="col-lg-12">
            <form className="row" onSubmit={onSubmit}>
              <div className="col-md-6">
                <fieldset>
                  <TextField
                    label="Nom"
                    name="firstname"
                    type="text"
                    required
                    value={formValues.firstname}
                    onChange={handleInputChange}
                  />
                </fieldset>
              </div>

              <div className="col-md-6">
                <fieldset>
                  <TextField
                    label="Prénom"
                    name="lastname"
                    type="text"
                    required
                    value={formValues.lastname}
                    onChange={handleInputChange}
                  />
                </fieldset>
              </div>
              <div className="col-md-6">
                <fieldset>
                  <TextField
                    label="Email"
                    type="text"
                    value={formValues.email}
                    disabled
                  />
                </fieldset>
              </div>
              <div className="col-md-6">
                <fieldset>
                  <TextField
                    label="Téléphone"
                    name="phonenumber"
                    type="text"
                    required
                    value={formValues.phonenumber}
                    onChange={handleInputChange}
                  />
                </fieldset>
              </div>
              <div className="col-md-6">
                <fieldset>
                  <label htmlFor="pays">Pays</label>
                  <Select
                    options={countries?.map((item: any) => ({ value: item.name, label: item.name }))}
                    name="city"
                    placeholder=""
                    value={formValues.country}
                    onChange={(value, action) => {
                      setFormValues({
                        ...formValues,
                        country: value
                      })
                    }}
                  />
                </fieldset>
              </div>
              <div className="col-md-6">
                <fieldset>
                  <label>Ville</label>
                  <Select
                    options={cities?.map((item: any) => ({ value: item.name, label: item.name }))}
                    name="city"
                    placeholder=""
                    value={formValues.city}
                    onChange={(value, action) => {
                      setFormValues({
                        ...formValues,
                        city: value
                      })
                    }}
                  />
                </fieldset>
              </div>
              <div className="col-md-6">
                <fieldset>
                  <TextField
                    label="Adresse"
                    name="address"
                    type="text"
                    required
                    value={formValues.address}
                    onChange={handleInputChange}
                  />
                </fieldset>
              </div>
              <div className="col-md-12">
                <fieldset>
                  <button className="btn btn-primary">Mettre à jour</button>
                </fieldset>
              </div>
            </form>
          </div>
        </div>
      </OverviewCard>
    </div>
  )
}
