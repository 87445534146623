import React from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import moment from "moment";
import Covers from "pages/Covers";
import OnTop from "pages/OnTop";

const Wrapper = styled.div`
  background: #f1f1f1;
  padding: 20px;

  min-height: 90vh;

  border-radius: 5px;

  .img-responsive {
    height: 250px;
    width: 100%;
  }

  img {
    width: 100%;
  }
`

const Title = styled.div`
  font-size: 30px;
  color: var(--primary-color);
  font-weight: bold;
`

const Date = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: var(--secondary-color);
  padding: 15px 0;
`

export const ArticleCover = styled.div`
  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;
`

const Desc = styled.p`
  background-color: #fff;
  padding: 10px 10px;
  border-left: 3px solid #FF4C28;
`

interface IProps extends RouteComponentProps {
  slug?: string;
}

export default function SheetPage({ slug }: IProps) {
  const [sheet, setSheet] = React.useState(null);

  React.useEffect(() => {
    fetch('/api/v1/articles/orientation/public/slug/' + slug)
      .then(res => res.json())
      .then((data) => {
        setSheet(data);
      })
      .catch(console.log)
  }, []);

  return (
    <>
      <Covers page="orientation_home" />

      <div className="container">
        <br />
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Accueil</a></li>
                <li className="breadcrumb-item"><a href="/orientation">Orientation</a></li>
                {/* <li className="breadcrumb-item active" aria-current="page">Data</li> */}
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <br />
            <Wrapper>
              <Title>{sheet?.name}</Title>
              <Date>{moment(sheet?.createdAt).format('MMMM DD, YYYY')}</Date>
              {
                sheet?.description &&
                <Desc>{sheet?.description}</Desc>
              }

              {
                sheet?.cover_image &&
                <ArticleCover style={{ backgroundImage: `url(${sheet?.cover_image})` }} />
              }
              <br />
              <div dangerouslySetInnerHTML={{ __html: sheet?.presentation }}></div>
            </Wrapper>
            <br />
          </div>
          <div className="col-md-4">
            <br />
            <OnTop section="orientation" />
            <br />
          </div>
        </div>
      </div>
    </>
  )
}
