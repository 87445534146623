import React from "react";
import axios from "axios";
import { useAuthToken } from "hooks/useAuthToken";
import { OverviewCard, OverviewCardHeader } from "pages/style";
import StatusInfo from "ui/StatusInfo";

export const INTRV_STATUS_COLOR = {
  TO_VALIDATE: {
    bg: "#0D5593",
    text: "À Valider",
  },
  VALIDATE: {
    bg: "#17a2b8",
    text: "Validée",
  },
  START: {
    bg: "#098664",
    text: "En cours",
  },
  START_ALERT: {
    bg: "#93840E",
    text: "En cours",
  },
  CANCEL: {
    bg: "#dc3545",
    text: "Suspendu",
  },
  CONTENTIOUS: {
    bg: "#098664",
    text: "Litigieux",
  },
  ABORT: {
    bg: "#098664",
    text: "Abandon",
  },
  END: {
    bg: "#098664",
    text: "Terminer",
  },
};

const Applications = ({}) => {
  const [data, setData] = React.useState<Array<any>>([]);
  const [authToken] = useAuthToken();

  React.useEffect(() => {
    axios
      .get("/api/v1/teachers/my/interventions", {
        headers: {
          ...(Boolean(authToken)
            ? { Authorization: `Bearer ${authToken}` }
            : {}),
        },
      })
      .then((res) => {
        setData(res.data);
      });
  }, []);

  return (
    <div className="container">
      <div className="row">
        <OverviewCard className="col-lg-12">
          <OverviewCardHeader>
            <h4 className="page-title animate-box">Mes interventions</h4>
          </OverviewCardHeader>
          {data.length > 0 ? (
            <table className="table table-bordered table-hover">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Date de début</th>
                  <th scope="col">Fréquence d'intervention</th>
                  <th scope="col">Elève</th>
                  <th scope="col">Matière(s)</th>
                  <th scope="col">Nbre d'heures affecté</th>
                  <th scope="col">Nbre d'heures effectuées</th>
                  <th scope="col">Statut</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {data.map((item: any, key: number) => (
                  <tr key={key}>
                    <td>{item.start_date} </td>
                    <td>{item.frequency} </td>
                    <td>{item?.student?.lastname}</td>
                    <td>
                      {item?.courses.map(
                        (st: any, i: any) =>
                          st.course.name +
                          (i + 1 !== item?.courses.length ? ", " : "")
                      )}
                    </td>
                    <td>{item.hours_affected}</td>
                    <td>{item.number_of_hours || 0}</td>
                    <td>
                      <StatusInfo
                        width="80px"
                        background={
                          INTRV_STATUS_COLOR[
                            item.status === "START" &&
                            item.number_of_hours > (item.hours_affected * 3) / 4
                              ? "START_ALERT"
                              : item.status
                          ]["bg"]
                        }
                        status={INTRV_STATUS_COLOR[item.status]["text"]}
                      />
                    </td>
                    <td>
                      <a
                        href={
                          "/soutien-scolaire/e/enseignant/interventions/" +
                          item.code
                        }
                        className="btn btn-primary"
                      >
                        Suivi
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <>Rien à afficher</>
          )}
        </OverviewCard>
      </div>
      <br />
      <br />
    </div>
  );
};

export default Applications;
