

import React from "react";
import styled from "styled-components";
import clsx from "clsx";

const Wrapper = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  input ~ .checkmark {
  background-color: #ccc;
}

input:checked ~ .checkmark {
  background-color: #DD2421;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

input:checked ~ .checkmark:after {
  display: block;
}

.checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
`;



interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  inputRef?: any;
  error?: boolean;
  helpText?: string;
}

const Checkbox = ({ label, inputRef, helpText, error, ...props }: TextFieldProps) => {
  return (
    <Wrapper>
      <input
        {...props}
        ref={inputRef}
        type="checkbox"
        className={clsx("", {
          "is-invalid": error
        })}
      />
      {label}
      <span className="checkmark"></span>
      {/* <label>{label}</label>
      <div className="invalid-feedback">
        {helpText}
      </div> */}
    </Wrapper>
  )
}
export default Checkbox;
