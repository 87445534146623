import React from "react";
import styled from "styled-components";
import moment from "moment";
import clsx from "clsx";
import { navigate } from "@reach/router";
import { isMobile } from "utils";

moment.locale('fr', {
  months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
  monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
  monthsParseExact: true,
  weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
  weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
  weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd D MMMM YYYY HH:mm'
  },
  calendar: {
    sameDay: '[Aujourd’hui à] LT',
    nextDay: '[Demain à] LT',
    nextWeek: 'dddd [à] LT',
    lastDay: '[Hier à] LT',
    lastWeek: 'dddd [dernier à] LT',
    sameElse: 'L'
  },
  relativeTime: {
    future: 'dans %s',
    past: 'il y a %s',
    s: 'quelques secondes',
    m: 'une minute',
    mm: '%d minutes',
    h: 'une heure',
    hh: '%d heures',
    d: 'un jour',
    dd: '%d jours',
    M: 'un mois',
    MM: '%d mois',
    y: 'un an',
    yy: '%d ans'
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: function (number) {
    return number + (number === 1 ? 'er' : 'e');
  },
  meridiemParse: /PD|MD/,
  isPM: function (input) {
    return input.charAt(0) === 'M';
  },
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem: function (hours, minutes, isLower) {
    return hours < 12 ? 'PD' : 'MD';
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4  // Used to determine first week of the year.
  }
});

const Wrapper = styled.div`
  width: 100%;
  padding: 10px;
  cursor: pointer;
  display: grid;
  border-bottom: 1px solid #eeeeee;
  grid-template-columns: 70px auto;
  grid-template-rows: 120px;
  grid-column-gap: 10px;

  &.selected {
    border-left: 3px solid #DD2421;
    background-color: rgba(128,128,128,0.1);
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const GridWrapper = styled.div`
  width: 100%;
  padding: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 1px solid #eeeeee;
  justify-content: space-between;
  height: 224px;
  border-radius: 15px;
`;

const ImageWrapper = styled.div`
  text-align: center;
`;

const InfosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #777;

  .subtitle {
    text-transform: uppercase;
  }
`;

const Title = styled.h4`
  color: #512581;
  font-weight: 400;
  font-size: 16px;
  margin: 0;

  :hover {
    color: var(--secondary-color);
  }
`;

const GridTitle = styled.h1`
  font-size: 1.7rem;
  margin: 0;

  :hover {
    color: var(--secondary-color);
  }
`;

const Date = styled.span`
  color: #777;
  font-size: 13px;
`;

const Address = styled.span`
  color: #777;
  font-size: 14px;
`;

interface JobItemProps {
  item: any;
  selected?: boolean;

  type?: "list" | "grid"
}
const JobItem: React.FC<JobItemProps> = ({ item, selected, type = "list" }) =>
  <>
    {
      type === "list" &&
      <Wrapper
        onClick={() => {
          isMobile ?
            navigate(`/stage-et-emploi/offres/${encodeURIComponent(item.jobId)}`)
            :
            navigate(`/stage-et-emploi/offres?jobid=${item.jobId}`)
        }}
        className={clsx({ selected: selected })}>
        <ImageWrapper>
          {
            item?.entreprise?.profile_image ?
              <img src={item.entreprise.profile_image} className="img-responsive" />
              :
              <i className="fas fa-building" style={{ fontSize: "40px", color: "#512581" }}></i>
          }
        </ImageWrapper>
        <InfosWrapper>
          <div>
            <Title>{item.title}</Title>
            <span className="subtitle">{item.entreprise?.name}</span>
          </div>
          <Address><i className="fas fa-map-marker-alt"></i> {item.city} {item.address}</Address>
          <Date><i className="far fa-clock"></i> Il y a {moment(item.createdAt).fromNow(true)}</Date>
        </InfosWrapper>
      </Wrapper>
    }
    {
      type === "grid" &&
      <GridWrapper
        onClick={() => {
          navigate("/stage-et-emploi/offres?jobid=" + item.jobId)
        }}
      >
        <div>
          <GridTitle>{item.title}</GridTitle>
          <span className="subtitle">{item.entreprise?.name}</span>
          <br />
          <Address><i className="fas fa-map-marker-alt"></i> {item.city} {item.address}</Address>
        </div>

        <Date><i className="far fa-clock"></i> Il y a {moment(item.createdAt).fromNow(true)}</Date>
      </GridWrapper>
    }
  </>

export default JobItem;