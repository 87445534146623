import React from "react";

export default function NavMenuTop() {

  return (
    <div className="top-menu">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-xs-12 text-right">
            <p className="site" style={{ paddingLeft: 0 }}>
              <a href="/stage-et-emploi/offres" className="btn btn-primary button-top-menu-left">Offres d'emploi</a>
            </p>
          </div>
          <div className="col-xs-12 text-right">
            <p className="num">
              <a href="/stage-et-emploi/e/entreprise" className="btn btn-primary button-top-menu-right">
                Mon espace
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
