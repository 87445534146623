import React from "react";
import { OverviewCard, OverviewCardHeader } from "pages/style";
import axios from "axios";
import { useAuthToken } from "hooks/useAuthToken";

export default function Childrens({ }) {
  const [data, setData] = React.useState<Array<any>>([]);
  const [authToken] = useAuthToken();

  React.useEffect(() => {
    axios.get("/api/v1/families/my/students",
      {
        headers: {
          ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {}),
        }
      }
    ).then(res => {
      setData(res.data);
    });
  }, []);

  return (
    <div className="container">
      <div className="row">
        <OverviewCard className="col-md-12">
          <OverviewCardHeader>
            <h4 className="page-title">Enfants</h4>
          </OverviewCardHeader>

          {
            data.length > 0 ?
              <table className="table table-bordered table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Code</th>
                    <th scope="col">Prénom</th>
                    <th scope="col">Niveau</th>
                    <th scope="col">Classe</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.map((item: any, key: number) =>
                      <tr key={key}>
                        <td>{item.code} </td>
                        <td>{item.lastname} </td>
                        <td>{item.level.name} </td>
                        <td>{item.classe?.name} </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
              :
              <>Rien à afficher</>
          }
        </OverviewCard>
      </div>
    </div>
  )
}
