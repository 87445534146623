import React, { useRef } from "react";
import { RouteComponentProps } from "@reach/router";
import axios from "axios";
import NewItem from "./NewItem";

import styled from "styled-components";
import ArticleItem from "pages/ArticleItem";

const NewsWrapper = styled.div`
  .owl-item:nth-child(1n + 1) .item {
    background-color: #673e9d;
  }

  .owl-item:nth-child(2n + 2) .item {
    background-color: #965100;
  }

  .owl-item:nth-child(3n + 3) .item {
    background-color: #36b8ad;
  }

  .owl-item:nth-child(4n + 4) .item {
    background-color: #f8b3a1;
  }
`;

const Overview: React.FC<RouteComponentProps> = () => {
  const ref = useRef(null);
  const [articles, setArticles] = React.useState([]);

  const initSlider = () => {
    if ((window as any).$) {
      (window as any).$("#studies.owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        dots: false,
        animateOut: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 3,
          },
        },
      });
    }
  };

  React.useEffect(() => {
    axios("/api/v1/articles/studies_news/onhome").then((res) => {
      setArticles(res.data);
      initSlider();
    });
  }, []);

  return (
    <NewsWrapper id="studies" ref={ref} className="owl-carousel">
      {articles.map((article, key) => (
        <ArticleItem
          key={key}
          path="/etudes/ne-pas-manquer"
          article={article}
        />
      ))}
    </NewsWrapper>
  );
};

export default Overview;
