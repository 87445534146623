import React from "react";
import { RouteComponentProps } from "@reach/router";
import { SearchType, SEARCH_PATH_BY_TYPE } from "./SearchBar";

interface IProps extends RouteComponentProps {
  slug?: string;
  type: SearchType;
  educationTypes?: any[];
  sectors?: any[];
  educationLevels?: any[];
  cities?: any[];

}

export default function FilterOptions({ type, educationTypes, sectors, educationLevels, cities }: IProps) {

  const RenderFilter = (
    <>
      {
        (type === "all") &&
        <div className="search-filter">
          <div className="search-filter-title">
            Type d'école
          </div>
          <hr />
          <div className="row">
            <div className="col-md-12">
              <a href="/etudes/elementaire/">Élémentaire</a>
            </div>
            <div className="col-md-12">
              <a href="/etudes/secondaire/">Secondaire</a>
            </div>
            <div className="col-md-12">
              <a href="/etudes/superieur/">Supérieur</a>
            </div>
          </div>
        </div>
      }

      {/* <div className="d-none d-lg-block"> */}
      {
        (type === "elementary_primary" || type === "secondary") &&

        <div className="search-filter">
          <div className="search-filter-title">
            Statut
          </div>
          <hr />
          <div className="row">
            <div className="col-md-12">
              <a href={`${SEARCH_PATH_BY_TYPE[type]}?filtre-statut=private`}>Privé</a>
            </div>
            <div className="col-md-12">
              <a href={`${SEARCH_PATH_BY_TYPE[type]}?filtre-statut=public`}>Public</a>
            </div>
          </div>
        </div>
      }

      {
        (type === "secondary" || type === "high") &&
        <div className="search-filter">
          <div className="search-filter-title">
            Type d'enseignement
          </div>
          <hr />
          <div className="row">
            {educationTypes.map((level, key) =>
              <div className="col-md-12">
                <a href={`${SEARCH_PATH_BY_TYPE[type]}?filtre-enseignement=${level.name}`}>{level.name}</a>
              </div>
            )}
          </div>
        </div>
      }

      {
        type === "high" &&
        <>
          <div className="search-filter">
            <div className="search-filter-title">
              Filières
            </div>
            <hr />
            <div className="row">
              {sectors.map((sector, key) =>
                <div key={key} className="col-md-12">
                  <a href={`${SEARCH_PATH_BY_TYPE[type]}?filtre-filiere=${sector.name}`}>{sector.name}</a>
                </div>
              )}
            </div>
          </div>

          <div className="search-filter">
            <div className="search-filter-title">
              Niveau d'étude
            </div>
            <hr />
            <div className="row">
              {educationLevels.map((level, key) =>
                <div className="col-md-12">
                  <a href={`${SEARCH_PATH_BY_TYPE[type]}?filtre-niveau=${level.name}`}>{level.name}</a>
                </div>
              )}
            </div>
          </div>
        </>
      }

      <div className="search-filter">
        <div className="search-filter-title">
          Ville
        </div>
        <hr />
        <div className="row">
          {
            cities.map((city, key) =>
              <div key={key} className="col-md-12">
                <a href={`${SEARCH_PATH_BY_TYPE[type]}?lieu=${city.name}`}>{city.name}</a>
              </div>
            )
          }
        </div>
      </div>
      {/* </div> */}
    </>
  )

  return (
    <>
      <>
        <div className=" d-block d-lg-none">
          <a href="#filter" data-toggle="drawer" className="btn-default">Filter</a>
        </div>
        <div className="d-none d-lg-block">

          {RenderFilter}
        </div>

      </>


      <div id="filter" className="drawer dw-xs-10 dw-sm-6 dw-md-4 fold" aria-labelledby="drawerExample">
        <div className="drawer-contents">
          <div className="drawer-heading">
            <h2 className="drawer-title">Filter</h2>
          </div>
          <div className="drawer-body">
            <div className="row">
              <div className="col-md-2">
                <br />
                <a href="#filter" data-toggle="drawer" className="btn btn-default">Fermer</a>
              </div>
              <div className="col-md-12">
                {RenderFilter}
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
