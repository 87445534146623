import React from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import useAuth from "hooks/useAuth";

interface PrivateRouteProps extends RouteComponentProps {
  component: React.FC<any>;
  children?: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {

  const [isAuth, _, authData] = useAuth();


  return isAuth && authData?.role === "candidat" ? <Component {...rest} /> : <Redirect noThrow to="/auth/~p/signin" />;
}

export default PrivateRoute;