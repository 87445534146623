import React from "react";
import { RouteComponentProps } from "@reach/router";
import SearchBar from "./SearchBar";
import Covers from "pages/Covers";
import Item from "./Item";

export default function AllSectorPage({ location }: RouteComponentProps) {
  const [sectors, setSectors] = React.useState([]);
  const [sheets, setSheets] = React.useState([]);
  const [query, setQuery] = React.useState(null);
  const [loading, setLoading] = React.useState(true);


  React.useEffect(() => {
    const query = new URLSearchParams(location.search).get('query');
    if (query) {
      setQuery(query);
      fetch('/api/v1/sheets/public/?query=' + query)
        .then(res => res.json())
        .then((data) => {
          setLoading(false);
          setSheets(data)
        })
        .catch(console.log);

    } else {
      fetch('/api/v1/sheets/public/sectors')
        .then(res => res.json())
        .then((data) => {
          setLoading(false);
          setSectors(data)
        })
        .catch(console.log);
    }
  }, []);

  return (
    <>
      <Covers page="sheet_home" />

      <div className="container">
        <br />
        <SearchBar />
      </div>

      <div id="fh5co-blog">
        <div className="container">

          <div className="row">
            <div className="col-md-12">
              <h1 className="page-title">
                {
                  query ? `Résultat de recherche pour '${query}'` : "Tous les secteurs"
                }

              </h1>
            </div>

            {
              loading &&
              <div className="col-md-12 text-center">
                <br />
                <div className="spinner-grow" style={{ width: "4rem", height: "4rem" }} role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <br />
              </div>

            }

            {
              !loading && sectors.map((sector, key) => {
                return (
                  <div key={key} className="col-lg-3 col-md-3">
                    <div className="fh5co-blog card-sector">
                      <a href={`/fiches-metiers/${sector.slug}`} className="blog-img-holder" style={{ backgroundImage: `url(${sector.cover_image || '/images/project-1.jpg'})` }}></a>
                      <h3 className="card-sector-title">{sector.name}</h3>
                    </div>
                  </div>
                )
              })
            }



            {
              query && sheets.length <= 0 &&
              <div className="col-md-12">
                <div className="card-simple text-center">
                  <br />
                  <img src="/images/search-empty-icon.png" alt="" />
                  <p>
                    <br />
                    <h4>Aucun résultat</h4>
                  </p>
                  <br />
                </div>
              </div>
            }

            {
              sheets.length > 0 &&
              <div className="col-md-12">
                <p>
                  {sheets.length} résultats trouver pour votre recherche
                </p>
              </div>
            }
            {
              sheets.map((item, key) => {
                return (
                  <div className="col-lg-3 col-md-3">
                    <Item article={item} />
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

    </>
  )
}
