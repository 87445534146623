import React from "react";
import styled from "styled-components";

export interface StatusProps {
  background?: string;
  color?: string;
  status?: string;
  width?: string;
}

const Status = styled.div<StatusProps>`
  background-color: ${(props) => (props.background ? props.background : "#ddd")};
  border-radius: 30px;
  color: ${(props) => (props.color ? props.color : "#fff")};
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  height: 21px;
  line-height: 21px;
  padding: 0 5px;
  text-align: center;
  width: ${(props) => (props.width ? props.width : "auto")};
`;

const StatusInfo = ({ background, color, status, width }: StatusProps) => {
  return (
    <Status background={background} color={color} width={width}>
      {status}
    </Status>
  );
};
export default StatusInfo;
