import React from "react";
import { StudiesLogo } from "./style";
import styled from "styled-components";
import './style.css';
import LogoutBtn from "ui/Button/Logout";

export const Nav = styled.nav`
  background-color: #fff;
  position: fixed;
`

const Header = (props: any) => {
  return (
    <Nav className="fh5co-nav" role="navigation">
      {props.top}
      <div className="top-menu">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-2">
              <div id="fh5co-logo">
                <a href="/">
                  <StudiesLogo src="/logo-studies.png" alt="Logo Studies" />
                </a>
              </div>
            </div>
            <div className="col-md-9 text-right menu-1" style={{ display: "flex", alignItems: "center" }}>
              <ul style={{ display: 'flex', justifyContent: 'space-between', width: "100%", }}>
                <li>
                  <a href={props.redirectPath}> <i className="fas fa-long-arrow-alt-left"></i> Retour vers le site</a>
                </li>
                <li><LogoutBtn redirectPath={props.redirectPath} /></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Nav>
  )
}

export default Header;