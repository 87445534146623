import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import axios from "axios";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from "ui/TextField";
import AuthWrapper from "../../Wrapper";
import { Title } from "../../style";
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  email: yup.string()
    .email("Veuillez saisir une adresse email valide.")
});

const Auth: React.FC<RouteComponentProps> = () => {
  const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data: any) => {
    axios.post("/api/v1/teachers/reset-password-request", {
      email: data.email,
    }).then(res => {
      toast.success("Vous allez recevoir les instructions de réinitialisation du mot de passe dans quelques instants");
      navigate("/auth/~t/signin");
    })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          toast.error("L'adresse email n'existe pas");
        }
      })
  }


  return (
    <AuthWrapper profile="teacher">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Title className="text-center" style={{ fontSize: 20 }}>
            Modifier mon mot de passe
          </Title>
        </div>
        <div className="form-group">
          <TextField
            label="Adresse email"
            name="email"
            type="email"
            required
            inputRef={register}
            error={!!errors.email}
            helpText={errors.email?.message}
          />
        </div>

        <div className="form-group text-center">
          <button
            type="submit" className="btn btn-primary">
            Envoyer
					</button>
        </div>

        <div className="form-group text-center">
          Vous n'avez pas encore de compte ? <a href="/auth/~t/signup">S'inscrire</a>
        </div>

        <div className="form-group text-center">
          <a href="/auth/~t/signin">Page de connexion</a>
        </div>
      </form>
    </AuthWrapper>
  )
}

export default Auth;