import React from "react";
import { StudiesLogo } from "ui/Header/style";

const Header = (props: any) => {
  return (
    <section className="w3l-header-6-main mobile-header">
      <div className="header-section-hny">
        {props.top}
        <div className="header-top">
          <div className="container">
            <div className="header-inn-top row">
              <div className="logo-brand col-6">
                <a className="navbar-brand" href="/">
                  <StudiesLogo src="/logo-studies.png" alt="Logo Studies" />
                </a>
              </div>
              <div className="menu-overlay-left col-6 ml-auto">
                <div className="two-pops d-flex">
                  <div
                    className="search-right"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {/* <a href="#search" title="search"><span className="fas fa-search" aria-hidden="true"></span></a> */}
                    <form
                      action="/recherche"
                      method="get"
                      className="search-box full"
                    >
                      <input
                        className="form-control top-search-input"
                        type="text"
                        placeholder="Recherche sur tout le site : école, formation, job..."
                        name="q"
                      />
                    </form>

                    <div id="search" className="pop-overlay">
                      <div className="popup">
                        <form action="re" method="post" className="search-box">
                          <input
                            type="search"
                            placeholder="Rechercher"
                            name="search"
                          />
                          <button type="submit" className="btn mt-3">
                            Rechercher
                          </button>
                        </form>
                      </div>

                      <a className="close" href="#">
                        ×
                      </a>
                    </div>
                  </div>
                  <div className="overlay-menuv-hny">
                    <input type="checkbox" id="op"></input>
                    <div className="side-menu-hny">
                      <label htmlFor="op" className="btn btn-primary menuopen">
                        <span className="fas fa-user" aria-hidden="true"></span>{" "}
                        Connexion
                      </label>
                    </div>
                    <div className="overlay-menuv overlay-menuv-hugeinc">
                      <label htmlFor="op" className="menuclose">
                        <span className="fa fa-times" aria-hidden="true"></span>
                      </label>
                      <div className="side-show-content text-left">
                        <div className="quick-links-side gap-top">
                          <h3 className="side-title">
                            Connetez-vous à votre espace
                          </h3>
                        </div>

                        <div className="quick-links-side gap-top">
                          <h4 className="side-title">Stages et Emplois</h4>
                          <ul>
                            <li>
                              <a href="/stage-et-emploi/e/particulier">
                                Espace candidat
                              </a>
                            </li>
                            <li>
                              <a href="/stage-et-emploi/e/entreprise">
                                Espace Entreprise
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="quick-links-side gap-top">
                          <h3 className="side-title">Soutien scolaire</h3>
                          <ul>
                            <li>
                              <a href="/soutien-scolaire/e/famille">
                                Espace famille
                              </a>
                            </li>
                            <li>
                              <a href="/soutien-scolaire/e/enseignant">
                                Espace enseignant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <header className="header-hny-block">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav mr-lg-auto">
                  <li className="nav-item">
                    <a className="nav-link" href="/">
                      Accueil
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="/orientation">
                      Orientation
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="/etudes">
                      Études
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/vie-etudiante">
                      Vie Étudiante
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/stage-et-emploi">
                      Stages et Emplois
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="/fiches-metiers">
                      Fiches Métiers
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/soutien-scolaire">
                      Soutien Scolaire
                    </a>
                  </li>
                </ul>
                <div className="social-right">
                  <ul className="social-icons-top">
                    <li>
                      <a className="facebook" href="https://fb.me/Studies21">
                        <span
                          className="fab fa-facebook-f"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </li>
                    <li>
                      <a className="twitter" href="#">
                        <span
                          className="fab fa-twitter"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="instagram"
                        href="https://www.instagram.com/studies2005/?hl=fr "
                      >
                        <span
                          className="fab fa-instagram"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </li>
                  </ul>
                </div>
                <form
                  action="/recherche"
                  method="get"
                  className="search-box mobile"
                >
                  <input
                    className="form-control top-search-input"
                    type="text"
                    placeholder="Recherche sur tout le site : école, formation, job..."
                    name="q"
                  />
                </form>
              </div>
            </nav>
          </div>
        </header>
      </div>
    </section>
  );
};

export default Header;
