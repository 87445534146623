import React from "react";

const SearchBar: React.FC = () => {
  const [searchQuery, setSearchQuery] = React.useState(null);

  React.useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    setSearchQuery(params.get('q'))
  }, [])

  const search = () => {
    window.location.href = `/recherche/?q=${searchQuery}`;
  }

  return (
    <div className="search-container">
      <div className="row">
        <div className="col-md-10">
          <input onChange={e => setSearchQuery(e.target.value)} value={searchQuery} className="form-control" type="text" placeholder="Mot-clé" />
        </div>
        <div className="col-md-2">
          <button onClick={search} className="search-button btn-rounded">Rechercher</button>
        </div>
      </div>
    </div>
  )
}

export default SearchBar;