import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useAuthToken } from "hooks/useAuthToken";
import useCurrentParticular from "hooks/useCurrentParticular";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import RemoveChip from "ui/Chip/RemoveChip";
import SelectField from "ui/SelectField";
import TextField from "ui/TextField";
import * as yup from "yup";
import { OverviewCard, OverviewCardHeader } from "../../style";

const schema = yup.object().shape({
  // availability: yup.string().required(),
  // current_job_level: yup.string().required(),
  // study_level: yup.string().required(),
  // search_contract_type: yup.string().required(),

  firstname: yup.string().required(),
  lastname: yup.string().required(),
  phonenumber: yup.string().required(),
  country: yup.string().required(),
  address: yup.string().required(),
  city: yup.string().required(),
});

export default function Application({}) {
  const { handleSubmit, errors, register } = useForm({
    resolver: yupResolver(schema),
  });

  const [availability, setAvailability] = React.useState("");
  const [current_job_level, setCurrentJobLevel] = React.useState("");
  const [study_level, setStudyLevel] = React.useState("");
  const [search_contract_type, setContractType] = React.useState("");
  const [professional_situation, setProfessionalSituation] = React.useState("");

  const [setting, setSetting] = React.useState(null);
  const [authToken] = useAuthToken();
  const [currentUser] = useCurrentParticular();

  const [sectors, setSectors] = React.useState([]);
  const [sheets, setSheets] = React.useState([]);
  const [editSectors, setEditSectors] = React.useState([]);

  const [sheet, setSheet] = React.useState("");
  const [sector, setSector] = React.useState("");

  let cancelRef = React.useRef<HTMLButtonElement>(null);

  const [countries, setCountries] = React.useState([]);

  React.useEffect(() => {
    axios.get("/api/v1/settings/countries").then((res) => {
      setCountries(res.data);
    });
  }, []);

  React.useEffect(() => {
    if (currentUser) {
      setAvailability(currentUser.availability);
      setCurrentJobLevel(currentUser.current_job_level);
      setStudyLevel(currentUser.study_level);
      setContractType(currentUser.search_contract_type);
      setProfessionalSituation(currentUser.professional_situation);
      let _sectors = [];
      if (currentUser.sought_sectors) {
        _sectors = currentUser.sought_sectors.split(",");
        setEditSectors(_sectors);
      }
    }
  }, [currentUser]);

  React.useEffect(() => {
    axios.get("/api/v1/settings/stage-et-emploi").then((res) => {
      setSetting(res.data);
    });

    axios("/api/v1/sheets/sectors").then((res) => {
      setSectors(res.data);
    });
  }, []);

  const sectorChange = (e: any) => {
    setSector(e.target.value);
    const _c = sectors.find((level) => level.id == e.target.value);
    if (_c) {
      setSheets(_c.sheets);
    }
  };

  const sheetChange = (e: any) => {
    setSheet(e.target.value);
  };

  const onAdd = (e: any) => {
    if (sheet) {
      setEditSectors([...editSectors, sheet]);
      setSheet("");
      setSector("");
      cancelRef.current.click();
    }
  };

  const onSubmit = (data: any) => {
    axios
      .put(
        "/api/v1/candidats/contact",
        {
          availability,
          search_contract_type,
          current_job_level,
          study_level,
          professional_situation,
          sought_sectors: editSectors.join(","),
          ...data,
        },
        {
          headers: {
            ...(Boolean(authToken)
              ? { Authorization: `Bearer ${authToken}` }
              : {}),
          },
        }
      )
      .then((res) => {
        toast.success("Informations modifiéss avec succès");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        toast.error("Erreur!");
      });
  };

  const remove = (sector: number) => {
    setEditSectors(editSectors.filter((i, index) => index != sector));
  };

  return (
    <div className="container">
      <div className="">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-12">
                <OverviewCard>
                  <OverviewCardHeader>
                    <h4 className="page-title">Profil</h4>
                  </OverviewCardHeader>

                  <div className="col-lg-12">
                    <form className="row" onSubmit={handleSubmit(onSubmit)}>
                      <div className="col-md-6">
                        <fieldset>
                          <TextField
                            label="Nom"
                            name="firstname"
                            type="text"
                            required
                            inputRef={register}
                            error={!!errors.firstname}
                            helpText={errors.firstname?.message}
                            defaultValue={currentUser?.firstname}
                          />
                        </fieldset>
                      </div>

                      <div className="col-md-6">
                        <fieldset>
                          <TextField
                            label="Prénom"
                            name="lastname"
                            type="text"
                            required
                            inputRef={register}
                            error={!!errors.lastname}
                            helpText={errors.lastname?.message}
                            defaultValue={currentUser?.lastname}
                          />
                        </fieldset>
                      </div>

                      <div className="col-md-6">
                        <fieldset>
                          <TextField
                            label="Email"
                            type="text"
                            defaultValue={currentUser?.email}
                            disabled
                          />
                        </fieldset>
                      </div>
                      <div className="col-md-6">
                        <fieldset>
                          <TextField
                            label="Téléphone"
                            name="phonenumber"
                            type="text"
                            required
                            inputRef={register}
                            error={!!errors.phonenumber}
                            helpText={errors.phonenumber?.message}
                            defaultValue={currentUser?.phonenumber}
                          />
                        </fieldset>
                      </div>
                      <div className="col-md-6">
                        <fieldset>
                          <label htmlFor="pays">Pays</label>
                          {countries.length > 0 && (
                            <select
                              defaultValue={currentUser?.country}
                              ref={register}
                              name="country"
                              className={"form-control"}
                              required
                            >
                              {countries.map((country, key) => (
                                <option key={key} value={country.name}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                          )}
                        </fieldset>
                      </div>
                      <div className="col-md-6">
                        <fieldset>
                          <TextField
                            label="Ville"
                            name="city"
                            type="text"
                            required
                            inputRef={register}
                            error={!!errors.city}
                            helpText={errors.city?.message}
                            defaultValue={currentUser?.city}
                          />
                        </fieldset>
                      </div>
                      <div className="col-md-6">
                        <fieldset>
                          <TextField
                            label="Adresse"
                            name="address"
                            type="text"
                            required
                            inputRef={register}
                            error={!!errors.address}
                            helpText={errors.address?.message}
                            defaultValue={currentUser?.address}
                          />
                        </fieldset>
                      </div>

                      <div className="col-md-6">
                        <fieldset>
                          <label>Disponibilité</label>
                          <select
                            onChange={(e: any) => {
                              setAvailability(e.target.value);
                            }}
                            name="availability"
                            className={"form-control"}
                            required
                            value={availability}
                          >
                            <option value="Immédiate">Immédiate</option>
                            <option value="dans 01 mois">dans 01 mois</option>
                            <option value="dans 03 mois">dans 03 mois</option>
                          </select>
                        </fieldset>
                      </div>
                      <div className="col-md-6">
                        <fieldset>
                          <label>Type de contrat recherché</label>
                          <select
                            onChange={(e: any) => {
                              setContractType(e.target.value);
                            }}
                            name="search_contract_type"
                            className={"form-control"}
                            required
                            value={search_contract_type}
                          >
                            {setting?.contractType.map((c: any, key: any) => (
                              <option key={key} value={c.name}>
                                {c.name}
                              </option>
                            ))}
                          </select>
                        </fieldset>
                      </div>

                      <div className="col-md-6">
                        <fieldset>
                          <label>Situation professionnelle</label>
                          <select
                            onChange={(e: any) => {
                              setProfessionalSituation(e.target.value);
                            }}
                            name="professional_situation"
                            className={"form-control"}
                            required
                            value={professional_situation}
                          >
                            {setting?.professionalSituation.map(
                              (c: any, key: any) => (
                                <option key={key} value={c.name}>
                                  {c.name}
                                </option>
                              )
                            )}
                          </select>
                        </fieldset>
                      </div>

                      <div className="col-md-6">
                        <fieldset>
                          <label>Experience professionnelle</label>
                          <select
                            onChange={(e: any) => {
                              setCurrentJobLevel(e.target.value);
                            }}
                            name="current_job_level"
                            className={"form-control"}
                            required
                            value={current_job_level}
                          >
                            {setting?.experienceLevel.map(
                              (c: any, key: any) => (
                                <option key={key} value={c.name}>
                                  {c.name}
                                </option>
                              )
                            )}
                          </select>
                        </fieldset>
                      </div>

                      <div className="col-md-6">
                        <fieldset>
                          <label>Niveau d'études</label>
                          <select
                            onChange={(e: any) => {
                              setStudyLevel(e.target.value);
                            }}
                            name="study_level"
                            className={"form-control"}
                            required
                            value={study_level}
                          >
                            {setting?.schoolLevel.map((c: any, key: any) => (
                              <option key={key} value={c.name}>
                                {c.name}
                              </option>
                            ))}
                          </select>
                        </fieldset>
                      </div>

                      <div className="col-md-6">
                        <fieldset>
                          <label>Metiers recherché</label>
                          <div>
                            {editSectors.map((sector, key) => (
                              <RemoveChip
                                label={sector}
                                onRemove={() => {
                                  remove(key);
                                }}
                              ></RemoveChip>
                            ))}
                            {editSectors.length < 4 && (
                              <button
                                type="button"
                                className="btn btn-default"
                                data-toggle="modal"
                                data-target="#exampleModal"
                              >
                                Ajouter
                              </button>
                            )}
                          </div>
                        </fieldset>
                      </div>

                      <div className="col-md-12">
                        <fieldset>
                          <button className="btn btn-primary">
                            Mettre à jour
                          </button>
                        </fieldset>
                      </div>
                    </form>
                  </div>
                </OverviewCard>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="exampleModalLabel">
                    Metier
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <div className="col-md-12">
                    <fieldset>
                      <SelectField
                        label="Secteur"
                        onChange={sectorChange}
                        name="level"
                        required
                        value={sector}
                      >
                        <option></option>
                        {sectors.map((c, key) => (
                          <option key={key} value={c.id}>
                            {c.name}
                          </option>
                        ))}
                      </SelectField>
                    </fieldset>
                  </div>

                  <div className="col-md-12">
                    <fieldset>
                      <SelectField
                        label="Metier"
                        onChange={sheetChange}
                        name="level"
                        required
                        value={sheet}
                      >
                        <option></option>
                        {sheets.map((c, key) => (
                          <option key={key} value={c.name}>
                            {c.name}
                          </option>
                        ))}
                      </SelectField>
                    </fieldset>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    ref={cancelRef}
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Annuler
                  </button>
                  <button
                    onClick={onAdd}
                    type="button"
                    className="btn btn-primary"
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
