import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useLogout } from "hooks/useLogout";

const Logout: React.FC<RouteComponentProps> = ({ location }) => {
  const logout = useLogout();

  React.useEffect(() => {
    logout();
    let params = new URLSearchParams(location.search);
    const redirectPath = params.get('redirectPath');
    window.location.href = redirectPath;
  }, []);

  return (<></>);
}

export default Logout;