import React from "react";
import useAuth from "hooks/useAuth";

export default function NavMenuTop() {
  const [isAuth, _, authData] = useAuth();

  return (
    <div className="top-menu">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-xs-12 text-right">
            <p className="site">
              <a
                href="/stage-et-emploi/offres"
                className="btn btn-primary button-top-menu-left"
              >
                Offres d'emploi
              </a>
              {!isAuth && (
                <a
                  href="/auth/~p/signup"
                  className="btn btn-outline btn-primary button-top-menu-left"
                >
                  Déposez votre CV
                </a>
              )}
              <a
                href="https://www.moncvpro.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn  button-top-menu-left cligner"
                style={{
                  backgroundColor: "#be1823",
                  color: "white",
                  borderColor: "#be1823",
                }}
              >
                Créez votre CV
              </a>
            </p>
          </div>
          <div className="col-xs-12 text-right">
            <p className="num">
              {(!isAuth ||
                (isAuth &&
                  authData?.role !== "candidat" &&
                  authData?.role !== "entreprise")) && (
                <>
                  <a
                    href="/stage-et-emploi/e/particulier"
                    className="btn btn-outline btn-primary button-top-menu-right"
                  >
                    Espace Candidat
                  </a>
                  <a
                    href="/stage-et-emploi/e/entreprise"
                    className="btn btn-primary  button-top-menu-right"
                  >
                    Espace Entreprise
                  </a>
                </>
              )}

              {isAuth && authData?.role === "candidat" && (
                <a
                  href="/stage-et-emploi/e/particulier"
                  className="btn btn-outline btn-primary button-top-menu-right"
                >
                  Mon espace
                </a>
              )}

              {isAuth && authData?.role === "entreprise" && (
                <a
                  href="/stage-et-emploi/e/entreprise"
                  className="btn btn-primary  button-top-menu-right"
                >
                  Mon espace
                </a>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
