import styled from "styled-components";

export const Header = styled.div`
  background-color: #fff;
  padding-bottom: 10px;
  box-shadow: 0 0.3rem 0.94rem rgba(128,128,128,0.06);
`;

export const Cover = styled.div`
  width: 100%;
  background-color: white;
  height: 150px;
  background-image: url(/images/profile1.jpg);
  background-position: 50% 1%;
  background-size: cover;
`;

export const IconWrapper = styled.div`
  margin-top: -75px;
  border-radius: 5px;
  width: 100px;
  height: 100px;
  background-color: #233266;
  display: flex;
  justify-content:  center;
  align-items: center;
`;

export const Name = styled.h1`
  margin-top: 8px;
  font-size: 2.5rem;
  font-weight: bold;
`;

export const Address = styled.h6`
  margin-top: 8px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #828282;
`;