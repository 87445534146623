import React from "react";
import { Router } from "@reach/router";
import Overview from "./Overview";
import Orientation from "./Orientation";
import JobOffer from "./JobOffer";
import StudentLife from "./StudentLife";
import Job from "./Job";
import Studies from "./Studies";
import Auth from "./Auth";
import Tutoring from "./Tutoring";
import Logout from "./Logout";

const Routes = () => (
  <Router>
    <Overview path="/*" />
    <Orientation path="/orientation/*" />
    <StudentLife path="/vie-etudiante/*" />
    <JobOffer path="/stage-et-emploi/*" />
    <Job path="/fiches-metiers/*" />
    <Studies path="/etudes/*" />
    <Tutoring path="/soutien-scolaire/*" />
    <Auth path="/auth/*" />
    <Logout path="/logout" />
  </Router>
);

export default Routes;