import React from "react";
import MainLayout from "ui/Layout";
import { Router, RouteComponentProps } from "@reach/router";
import ArticlesPage from "./Articles";
import ArticlePage from "./Article";

const StudentLifePage: React.FC<RouteComponentProps> = () => {
  return (
    <MainLayout>
      <Router>
        <ArticlesPage path="/" />
        <ArticlePage path="/:slug" />
      </Router>
    </MainLayout>
  )
}

export default StudentLifePage;