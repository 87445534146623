
import React from "react";
import { RouteComponentProps } from "@reach/router";
import ArticleItem from "pages/ArticleItem";

interface Ar extends RouteComponentProps {
  articles: any[];
}
export default function HomeArticle(props: Ar) {

  const initSlider = () => {
    if ((window as any).$) {
      (window as any).$("#home.owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        dots: false,
        animateOut: true,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 4
          }
        }
      })
    }
  }

  React.useEffect(() => {
    if (props?.articles.length > 0)
      initSlider();
  }, [props?.articles.length]);

  return (
    <>
      <div id="home" className="owl-carousel">
        {
          props?.articles.map((article, key) => {
            return (
              <ArticleItem path="/articles" article={article} />
            )
          })
        }
      </div>
    </>
  )
}
