import React, { Fragment } from "react";

// import { UploadIcon } from "icons";

const fileButtonStyles = {
  height: "70px",
  width: "100%",
  borderRadius: "4px",
  paddingTop: "3px",
  paddingRight: "4%",
  paddingBottom: "3px",
  border: "dotted 1px rgba(112, 112, 112, 0.5)",
  backgroundColor: "rgba(196, 196, 196, 0.3)",
  display: "flex",
  alignItems: "center",
};

const labelStyles = {
  height: "42px",
  width: "100%",
  color: "white",
  borderRadius: "4px",
  backgroundColor: "var(--tertiary-bg)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

const dragZoneStyles = {
  height: "100%",
  color: "#616161",
  paddingLeft: "8%",
  flexBasis: "66%",
  display: "flex",
  alignItems: "center",
};

const selectZoneStyles = {
  flexBasis: "30%",
  marginLeft: "auto",
};

type fileType = ".pdf" | ".png" | ".jpg";

interface fileButtonProps {
  id: string;
  name?: string;
  file?: any;
  children?: any;
  acceptedTypes?: Array<fileType>;
  onChange: (file: any) => void;
}

const FileButton = ({
  id,
  name,
  file,
  acceptedTypes,
  children,
  onChange,
}: fileButtonProps) => {
  const [indication, setIndication] = React.useState("Drag and drop ou");

  React.useEffect(() => {
    if (file) {
      setIndication(file.name);
    }
  }, [file]);

  const handleFileSelect = (event: any) => {
    const selectedFile: File = event.target.files[0];
    const fileName = selectedFile.name;
    setIndication(fileName);
    onChange(selectedFile);
  };

  const handleFileDragOver = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };

  const handleFileDrop = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const dropedFile: File = event.dataTransfer.files[0];
    const fileName = dropedFile.name;
    setIndication(fileName);
  };

  const randInt = Math.floor(Math.random() * 1000);
  const dragZoneDomId = id.concat(randInt + "-dragzone");
  const selectFileDomId = id.concat(randInt + "-selectbtn");

  return (
    <Fragment>
      {acceptedTypes?.includes(".pdf") && (
        <div style={{ textAlign: "right", paddingBlock: 14 }}>
          <div>
            <a
              href="https://www.moncvpro.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn  button-top-menu-left"
              style={{
                backgroundColor: "#be1823",
                color: "white",
                borderColor: "#be1823",
              }}
            >
              Créez votre CV
            </a>
          </div>
          <span
            style={{
              fontSize: 14,
              paddingBlock: 8,
              color: "#be1823",
              fontStyle: "italic",
            }}
          >
            Réalisez votre CV à partir de 500 FCFA
          </span>
        </div>
      )}
      <div style={fileButtonStyles}>
        <div
          id={dragZoneDomId}
          style={dragZoneStyles}
          onDragOver={handleFileDragOver}
          onDrop={handleFileDrop}
        >
          <span>{/* <UploadIcon /> */}</span>
          &nbsp;
          <small>
            <i>{indication}</i>
          </small>
        </div>

        <div id={selectFileDomId} style={selectZoneStyles}>
          <label htmlFor={id} style={labelStyles}>
            <small>Choisir un fichier ({acceptedTypes.join(",")})</small>
          </label>
        </div>
      </div>

      <input
        id={id}
        type="file"
        name={name}
        accept={acceptedTypes?.join(",")}
        onChange={handleFileSelect}
        style={{ display: "none" }}
      />
    </Fragment>
  );
};

export default FileButton;
