import React from "react";
import { RouteComponentProps } from "@reach/router";
import Ads from "pages/Ads";
import Covers from "pages/Covers";
import axios from "axios";
import NewItem from "./NewItem";
import SearchBar from "./SearchBar";
import Line from "./Line";
import HomeNewsList from "./HomeNewsList";
import Adser from "pages/Adser";

export default function Overview(props: RouteComponentProps) {
  const [news, setNews] = React.useState<Array<any>>([]);
  const [page, setPage] = React.useState(null);

  React.useEffect(() => {
    axios('/api/v1/articles/studies_news/public')
      .then(res => {
        setNews(res.data)
      });

    axios('/api/v1/tutoring/page-text/14')
      .then(res => {
        setPage(res.data)
      });
  }, []);

  return (
    <>
      <Covers page="studies_home" />

      <Line />

      <div className="container">
        <br />
        <SearchBar type="all" />
      </div>

      <div id="fh5co-about">
        <div className="container">
          <div className="row">
            <div className="col-md-8 animate-box">
              <div className="row">
                <div className="col-md-12">
                  <HomeNewsList />
                </div>
                <div className="col-md-12">
                  <br />
                  <h2 className="search-filter-title">{page?.name}</h2>
                  <div className="animate-box">
                    {
                      page?.cover_image &&
                      <img className="img-responsive" src={page?.cover_image} alt="" />
                    }
                    <br />
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: page?.presentation }}></div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <Adser />
              <br />
              <Ads sticky page="studies_home" />
            </div>
          </div>
        </div>
      </div>

      <br /><br />

    </>
  )
}
