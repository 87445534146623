import React from "react";
import { RouteComponentProps, Router } from "@reach/router";
import MainLayout from "ui/Layout";
import Overview from "./Overview";
import Request from "./Request/";
import OverviewParticular from "./OverviewParticular";
import OverviewTeacher from "./OverviewTeacher";
import OverviewCourse from "./OverviewCourse";
import NavMenuTop from "./NavMenuTop";
import Teacher from "./Teacher";
import Family from "./Family";
import TeacherCreationProvider from "context/TeacherCreation";
import Article from "./Article";

const Private: React.FC<RouteComponentProps> = () => {
  return (
    <Router>
      <Teacher path="/enseignant/*" />
      <Family path="/famille/*" />
    </Router>
  )
}

const Public: React.FC<RouteComponentProps> = () => {
  return (
    <MainLayout
      top={<NavMenuTop />}
    >
      <Router>
        <Overview path="/" />
        <OverviewTeacher path="/enseignant" />
        <OverviewParticular path="/famille" />
        <OverviewCourse path="/:slug" />
        <Article path="/articles/:slug" />

        <Private path="/e/*" />
      </Router>

    </MainLayout>
  )
}

const TutoringPage: React.FC<RouteComponentProps> = () => {
  return (
    <Router>
      <Public path="/*" />
      <PoPage path="/renseignement" />
      <Private path="/e/*" />
    </Router>
  )
}

const PoPage: React.FC<RouteComponentProps> = () => {
  return (
    <TeacherCreationProvider>
      <Router>
        <Request path="/" />
      </Router>
    </TeacherCreationProvider>

  )
}

export default TutoringPage;