import { CandidatCreationContext } from "context/CandidatCreation";
import React from "react";
import FileButton from "ui/FileButton";
import { Button, TabFooter, TabHeader, TabWapper } from "pages/Auth/style";

const CourseTab = () => {
  const { tabValue, setTabValue, resume, setResume } = React.useContext(
    CandidatCreationContext
  );

  const back = () => {
    setTabValue(tabValue - 1);
  };

  const next = () => {
    setTabValue(tabValue + 1);
  };

  function onDrop(picture: any) {
    setResume(picture);
  }

  return (
    <TabWapper>
      <TabHeader>
        <h4>Votre CV</h4>
      </TabHeader>
      <div>
        <FileButton
          file={resume}
          onChange={onDrop}
          id="d"
          name="s"
          acceptedTypes={[".pdf"]}
        />
      </div>

      <TabFooter>
        <Button onClick={back} className="btn back">
          Précédent
        </Button>
        <Button onClick={next} className="btn next" disabled={!Boolean(resume)}>
          Suivant
        </Button>
      </TabFooter>
    </TabWapper>
  );
};

export default CourseTab;
