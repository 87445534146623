
import React from "react";
import HeaderJobOffer from 'ui/Header/HeaderJobOffer'
import TinyFooter from 'ui/Footer/TinyFooter'
import styled from "styled-components";
import "./style.css";
import { Header } from "./style";

const Cover = styled.div`
  width: 100%;
  background-color: white;
  height: 150px;
  background-image: url(/images/cover-entreprise.jpg);
  background-position: 50% 1%;
  background-size: cover;
`;

const IconWrapper = styled.div`
  margin-top: -75px;
  border-radius: 5px;
  width: 100px;
  height: 100px;
  background-color: #233266;
  display: flex;
  justify-content:  center;
  align-items: center;
`;

const Name = styled.h1`
  margin-top: 8px;
  font-size: 2.5rem;
  font-weight: bold;
`;

const Address = styled.h6`
  margin-top: 8px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #828282;
`;


const ParticulierLayout = (props: any) => {
  const { children } = props;
  const user = JSON.parse(localStorage.getItem("studies_auth"));

  return (
    <div id="page">
      <HeaderJobOffer redirectPath="/stage-et-emploi" />
      <Header>
        <Cover />
        <div className="container" >
          <div className="row">
            <div className="col-md-12">
              <IconWrapper>
                <i className="fas fa-user" style={{ fontSize: "50px", color: "white" }}></i>
              </IconWrapper>
            </div>
            <div className="col-md-12">
              <Name>{user?.data?.firstname}</Name>
              <Name>{user?.data?.job}</Name>
              {
                user?.data?.address &&
                <Address> <i className="fas fa-map-marker-alt"></i>{user?.data?.address}</Address>
              }
            </div>
            <br />
            <div className="col-md-12 fh5co-nav">
              <div className=" menu-1">
                <ul>
                  <li className={window.location.pathname.includes('candidatures') ? 'active' : ''}>
                    <a href="/stage-et-emploi/e/particulier/candidatures">Candidatures</a>
                  </li>

                  <li className={window.location.pathname.includes('profil') ? 'active' : ''}>
                    <a href="/stage-et-emploi/e/particulier/profil">Profil</a>
                  </li>

                  <li className={window.location.pathname.includes('cv') ? 'active' : ''}>
                    <a href="/stage-et-emploi/e/particulier/cv">CV</a>
                  </li>
                  <li className={window.location.pathname.includes('contacts') ? 'active' : ''}><a href="/stage-et-emploi/e/particulier/contacts">Coordonnées</a></li>
                  <li className={window.location.pathname.includes('parmaretres') ? 'active' : ''}>
                    <a href="/stage-et-emploi/e/particulier/parmaretres">Paramètres</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Header>
      <div style={{ paddingBottom: "90px" }}>
        {children}
      </div>
      <TinyFooter />
    </div>
  )
}

export default ParticulierLayout;