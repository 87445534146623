import React from "react";
import styled from "styled-components";
import axios from "axios";
import { useAuthToken } from "hooks/useAuthToken";
import { OverviewCard } from "../../style";
import moment from "moment";
import StatusInfo from "ui/StatusInfo";
import { STATUS_COLOR } from "../Entreprise/Application";

const OverviewCardHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Applications = ({ }) => {
  const [data, setData] = React.useState<Array<any>>([]);
  const [authToken] = useAuthToken();

  React.useEffect(() => {
    axios.get("/api/v1/candidats/myapplication",
      {
        headers: {
          ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {}),
        }
      }
    ).then(res => {
      setData(res.data);
    });
  }, []);

  return (
    <div className="container">
      <div className="row">
        <OverviewCard className="col-lg-12">
          <OverviewCardHeader>
            <h4 className="page-title">Mes Candidatures</h4>
          </OverviewCardHeader>

          {
            data.length > 0 ?
              <table className="table table-bordered table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Titre de l'offre</th>
                    <th scope="col">Entreprise</th>
                    <th scope="col">Statut</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.map((item: any, key: number) =>
                      <tr key={key}>
                        <td>
                          <a href={`/stage-et-emploi/offres?jobid=${item?.joboffer.jobId}`} target="_blank">
                            {item.joboffer.title}
                          </a>

                        </td>
                        <td>{item.entreprise?.name} </td>
                        <td>
                          <StatusInfo width="80px" background={STATUS_COLOR[item.status]['bg']} status={STATUS_COLOR[item.status]['text']} />
                        </td>
                        <td>{moment(item.createdAt).format('MMMM D YYYY, h:mm')} </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
              :
              <>Rien à afficher</>
          }
        </OverviewCard>
      </div>
    </div>
  )
}

export default Applications;
