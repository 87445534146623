import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import TinyHeader from "ui/Header/TinyHeader";
import TinyFooter from "ui/Footer/TinyFooter";
import axios from "axios";
import styled from "styled-components";


const AuthWrapper = styled.div`
  background-color: #fff;
  padding: 30px 50px;
  width: 90%;
  margin: auto;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  position: relative;

  :before {
    content: '';
    position: absolute;
    width: 5px;
    height: 48px;
    left: -3px;
    border-radius: 5px;
  }
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 2.5rem;
  color: #233266;
`
interface IProps extends RouteComponentProps {
  token?: string;
}
const Auth: React.FC<IProps> = ({ token }) => {
  const [isSuccess, setIsSuccess] = React.useState(false);

  React.useEffect(() => {
    axios.post("/api/v1/teachers/active-account/" + token).then(res => {
      setIsSuccess(true);
      setTimeout(() => {
        navigate("/auth/~t/signin", { replace: true });
      }, 2000)

    })
      .catch(error => {
      })
  }, []);

  return (
    <div id="page" style={{ backgroundColor: "#F6F5FA" }}>
      {
        isSuccess &&
        <div className="alert alert-success" role="alert">
          Votre compte a été activé avec succès!
          Veuillez vous connecter pour acceder à votre espace
        </div>
      }
    </div>
  )
}

export default Auth;