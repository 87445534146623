import React from "react";
import { StudiesLogo } from "ui/Header/style";
import styled from "styled-components";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`

const Wrapper = styled.div`
  background-color: #fff;
  padding: 30px 50px;
  width: 90%;
  margin: auto;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  position: relative;

  :before {
    content: '';
    position: absolute;
    width: 5px;
    height: 48px;
    left: -3px;
    border-radius: 5px;
  }
`

const page = {
  candidat: {
    home: "/stage-et-emploi"
  },
  teacher: {
    home: "/soutien-scolaire"
  }
}

interface AuthWrapperProps {
  profile: string;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children, profile }) =>
  <Page style={{ backgroundImage: `url(/images/cover-entreprise.jpg)` }}>
    <div className="container">
      <div className="row">
        <div className="col-sm-6 mx-auto">
          <Wrapper className="animate-box">
            <div className="text-center">
              <a href={page[profile].home}>
                <StudiesLogo src="/logo-studies.png" alt="Logo Studies" />
              </a>
            </div>
            {children}
          </Wrapper>
        </div>
      </div>
    </div>
  </Page>

export default AuthWrapper;