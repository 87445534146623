import React from 'react';
import styled from 'styled-components';

const Link = styled.a`
  color: var(--secondary-color)!important;
  padding: 15px 0!important;
  border-radius: 30px;

  :hover {
    background-color: var(--secondary-color);
    color: #fff!important;
    padding: 15px!important;
  }
`;
const LogoutBtn = ({ redirectPath }: { redirectPath: string }) => {
  return (
    <Link href={"/logout?redirectPath=" + redirectPath}><i className="fas fa-sign-out-alt"></i> Se déconnecter</Link>
  )
}

export default LogoutBtn;