import React from "react";
import { navigate } from "@reach/router";
import ApplicationItem, { JOB_OFFER_STATUS, Status } from "./ApplicationItem";
import axios from "axios";
import moment from "moment";
import { OverviewCard, OverviewCardHeader } from "../../style";
import { useAuthToken } from "hooks/useAuthToken";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Icon } from "pages/Studies/School";

const Wrapper = styled.div`
  margin-bottom: 10px;
  border: 1px solid #eeeeee;
  padding: 20px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 60px 50px;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  border-radius: 30px;
  height: 50px;
  width: 50px;

  i {
    color: rgba(81, 37, 129, .7);
    font-size: 30px;
  }
`;

const DescWrapper = styled.div`
  display: grid;
  grid-template-columns: 70px auto;
  grid-template-rows: 100%;

  .subtitle {
    color: #777;
    font-size: 15px;
  }
`;

const InfosWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #777;

  a {
    color: #512581;
  }
`;

const InfosItem = styled.span`
  color: #000;
  background-color: rgba(81, 37, 129, .1);
  padding: 3px 5px;
  border-radius: 3px;
  margin-right: 5px;
`;

const Title = styled.h4`
  color: #000;
  font-weight: bold;
  font-size: 18px;
  margin: 0;

  :hover {
    color: var(--secondary-color);
  }
`;


interface ICandidatItem {
  candidat: any;
  hasDelete?: boolean;
  onDetails: (candidat: any) => void;
}
const CandidatItem = ({ candidat, hasDelete, onDetails }: ICandidatItem) => {
  const [authToken] = useAuthToken();


  const save = () => {
    axios.post('/api/v1/entreprises/cvtheque/save', {
      candidat_id: candidat.id,
    }, {
      headers: {
        ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {})
      }
    })
      .then(res => {
        toast.success("Candidat enrégistré");
      })
      .catch(error => {
        if (error.response && error.response.status === 409) {
          toast.success("Candidat enrégistré");
        }
      })
  }

  const del = () => {
    axios.post('/api/v1/entreprises/cvtheque/delete', {
      candidat_id: candidat.id,
    }, {
      headers: {
        ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {})
      }
    })
      .then(res => {
        toast.success("Candidat supprimé");
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      })
      .catch(error => {
      })
  }

  return (
    <>
      <Wrapper>
        <DescWrapper>
          <ImageWrapper>
            <i className="far fa-user"></i>
          </ImageWrapper>
          <div>
            <Title>{candidat.firstname} {candidat.lastname}</Title>
            <span className="subtitle">{candidat.address}</span>

            <p>
              {
                candidat?.email &&
                <span> <a href={"mailto:" + candidat?.email} target="_blank">{candidat?.email}</a> </span>
              }
              {
                candidat?.phonenumber &&
                <span> <a href={"tel:" + candidat?.phonenumber} target="_blank">{candidat?.phonenumber}</a></span>
              }
            </p>
          </div>
        </DescWrapper>
        <InfosWrapper>
          <div>
            <InfosItem>
              Disponibilité: {candidat.availability}
            </InfosItem>

            <InfosItem>
              Type de contrat recherché: {candidat.search_contract_type}
            </InfosItem>

            <InfosItem>
              Niveau d'étude: {candidat.study_level}
            </InfosItem>

          </div>
          <div>
            <button className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={() => onDetails(candidat)}>Détails</button>
            {
              hasDelete ?
                <button className="btn btn-danger" onClick={del} >Supprimer</button>
                :
                <button className="btn btn-primary" onClick={save} >Garder</button>
            }
          </div>

        </InfosWrapper>
      </Wrapper>
    </>
  )
}

export default CandidatItem;
