import React from "react";
import { RouteComponentProps } from "@reach/router";
import { OrientationCategorie } from "__types__";
import axios from "axios";
import Covers from "pages/Covers";
import OnTop from "pages/OnTop";
export default function OrientationPage(props: RouteComponentProps) {
  const [data, setData] = React.useState<Array<OrientationCategorie>>([]);


  React.useEffect(() => {
    axios.get('/api/v1/articles/categories/orientation/public')
      .then(res => {
        setData(res.data)
      });
  }, []);
  return (
    <>
      <Covers page="orientation_home" />

      <div id="fh5co-blog">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                {
                  data.map((sector, key) => {
                    return (
                      <div className="col-lg-4 col-md-4" key={key}>
                        <div className="fh5co-blog card-sector">
                          <a href={`orientation/${sector.slug}`} className="blog-img-holder" style={{ backgroundImage: `url(${sector.cover_image || '/images/project-1.jpg'})` }}></a>
                          <h3 className="card-sector-title">{sector.name}</h3>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="col-md-4">
              <OnTop section="orientation" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
