import React from "react";
import { navigate } from "@reach/router";
import styled from "styled-components";
import axios from "axios";
import { useAuthToken } from "hooks/useAuthToken";
import { OverviewCard, OverviewCardHeader } from "pages/style";
import StatusInfo from "ui/StatusInfo";

export const INTRV_STATUS_COLOR = {
  TO_START: {
    bg: "#0D5593",
    text: "À Debuter",
  },
  START: {
    bg: "#098664",
    text: "En cours",
  },
  CANCEL: {
    bg: "#098664",
    text: "Annuler",
  },
  CONTENTIOUS: {
    bg: "#098664",
    text: "Litigieux",
  },
  ABORT: {
    bg: "#098664",
    text: "Abandon",
  },
  END: {
    bg: "#098664",
    text: "Terminer",
  },
};

export const COUPON_COLOR = {
  UNUSED: {
    bg: "#0D5593",
    text: "Valide",
  },
  USED: {
    bg: "#098664",
    text: "Utilisé",
  },
};

interface ApplicationsProps {
  code?: string;
}

const Applications = ({ code }: ApplicationsProps) => {
  const [data, setData] = React.useState<Array<any>>([]);
  const [current, setCurrent] = React.useState(null);
  const [hours, setHours] = React.useState(null);
  const [hoursP, setHoursP] = React.useState(null);
  const [authToken] = useAuthToken();

  React.useEffect(() => {
    axios
      .get("/api/v1/families/my/interventions/followups/" + code, {
        headers: {
          ...(Boolean(authToken)
            ? { Authorization: `Bearer ${authToken}` }
            : {}),
        },
      })
      .then((res) => {
        setData(res.data);
        let _p = 0;
        for (let i = 0; i < res.data.length; i++) {
          _p += res.data[i].number_of_hours;
        }
        setHoursP(_p);
      });
    axios
      .get("/api/v1/tutoring/interventions/bycode/" + code, {
        headers: {
          ...(Boolean(authToken)
            ? { Authorization: `Bearer ${authToken}` }
            : {}),
        },
      })
      .then((res) => {
        setCurrent(res.data);
        setHours(res.data.hours_affected);
      });
  }, []);

  console.log(current);

  return (
    <div className="container">
      <div className="row">
        <OverviewCard className="col-lg-12">
          <OverviewCardHeader>
            <h4 className="page-title">Suivi intervention : {code} </h4>
          </OverviewCardHeader>
          {current &&
            current?.status === "START" &&
            hoursP >= (current.hours_affected * 3) / 4 && (
              <div className="alert alert-danger" role="alert">
                Alerte consommation : Le crédit d'heures de cette intervention
                est bientôt terminé
              </div>
            )}
          {data.length > 0 ? (
            <table className="table table-bordered table-hover">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Date d'intervention</th>
                  <th scope="col">Nbr d'heures</th>
                  <th scope="col">Heure de début</th>
                  <th scope="col">Heure de fin</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item: any, key: number) => (
                  <tr key={key}>
                    <td>{item.start_date} </td>
                    <td>{item.number_of_hours} </td>
                    <td>{item.start_time} </td>
                    <td>{item.end_time} </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <>Rien à afficher</>
          )}

          <h6 className="page-title">Code de validation </h6>

          {current?.coupons?.length > 0 ? (
            <table className="table table-bordered table-hover">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Code</th>
                  <th scope="col">Statut</th>
                </tr>
              </thead>
              <tbody>
                {current?.coupons.map((item: any, key: number) => (
                  <tr key={key}>
                    <td>{item.code} </td>
                    <td>
                      <StatusInfo
                        width="90px"
                        background={COUPON_COLOR[item.status]["bg"]}
                        status={COUPON_COLOR[item.status]["text"]}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <>Rien à afficher</>
          )}
        </OverviewCard>
      </div>
    </div>
  );
};

export default Applications;
