
import React from "react";
import { RouteComponentProps } from "@reach/router";
import axios from "axios";
import HomeItem from "./HomeItem";
import ArticleItem from "pages/ArticleItem";

export default function HomeArticle(props: RouteComponentProps) {
  const [articles, setArticles] = React.useState([]);

  React.useEffect(() => {
    axios('/api/v1/sheets/onhome')
      .then(res => {
        setArticles(res.data);
        initSlider();
      });
  }, []);

  const initSlider = () => {
    if ((window as any).$) {
      (window as any).$("#homesheet.owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        dots: false,
        animateOut: true,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 3
          }
        }
      })
    }
  }

  return (
    <div id="homesheet" className="owl-carousel">
      {
        articles.map((article, key) => {
          return (
            <>
              <HomeItem article={article} />
              {article.articles.map((item: any) => <ArticleItem path="/fiches-metiers/articles" article={item} />)}
            </>
          )
        })
      }
    </div>
  )
}
