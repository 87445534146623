import React from "react";
import MainLayout from "ui/Layout";
import { RouteComponentProps } from "@reach/router";
import axios from "axios";
import NavMenuTop from "./components/NavMenuTop";
import TopJobOffers from "./TopJobOffers";
import Covers from "pages/Covers";
import SearchBar from "./SearchBar";
import ArticleItem from "pages/ArticleItem";
import NewItem from "./NewItem";

import styled from "styled-components";
import NewsList from "./NewsList";
import Card from "./Card";

const NewsWrapper = styled.div`
  .col-md-3:nth-child(1n+1) .item {
    background-color: #512581;
  }

  .col-md-3:nth-child(2n+2) .item {
    background-color: #BE1823;
  }

  .col-md-3:nth-child(3n+3) .item {
    background-color: #0BA094;
  }
`;

const Overview: React.FC<RouteComponentProps> = () => {
  const [data, setData] = React.useState<Array<any>>([]);
  const [page1, setPage1] = React.useState(null);
  const [page2, setPage2] = React.useState(null);
  const [page3, setPage3] = React.useState(null);
  const [articles, setArticles] = React.useState([]);




  React.useEffect(() => {
    axios('/api/v1/articles/joboffers/public')
      .then(res => {
        setArticles(res.data)
      });

    axios.get('/api/v1/joboffers/ontop')
      .then(res => {
        if (res.status === 200) {
          setData(res.data)
        }
      });

    axios('/api/v1/tutoring/page-text/13')
      .then(res => {
        setPage1(res.data)
      })

    axios('/api/v1/tutoring/page-text/11')
      .then(res => {
        setPage2(res.data)
      })

    axios('/api/v1/tutoring/page-text/12')
      .then(res => {
        setPage3(res.data)
      })
  }, []);

  return (
    <MainLayout
      top={<NavMenuTop />}
    >
      <Covers page="joboffers_home" />

      <div className="container">
        <br />
        <SearchBar />
        <br />
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <NewsList />
          </div>
        </div>
      </div>

      <aside id="fh5co-course" style={{ padding: "2em" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <Card title={page1?.name} image={page1?.cover_image} description={page1?.description} moreLink={"/stage-et-emploi/a-propos"} />

              <Card title={page3?.name} image={page3?.cover_image} description={page3?.description} moreLink={"/stage-et-emploi/entreprise"} />

              <Card title={page2?.name} image={page2?.cover_image} description={page2?.description} moreLink={"/stage-et-emploi/candidats"} />

            </div>
            <div className="col-md-4 animate-box">
              <TopJobOffers />
            </div>
          </div>
        </div>
      </aside>

    </MainLayout >
  )
}

export default Overview;