import React, { useRef } from "react";
import { RouteComponentProps } from "@reach/router";
import axios from "axios";
import NewItem from "./NewItem";

import styled from "styled-components";



export const NewsWrapper = styled.div`
  .owl-item:nth-child(1n+1) .item {
    background-color: #673E9D;
  }

  .owl-item:nth-child(2n+2) .item {
    background-color: #965100;
  }

  .owl-item:nth-child(3n+3) .item {
    background-color: #36B8AD;
  }

  .owl-item:nth-child(4n+4) .item {
    background-color: #F8B3A1;
  }
`;

const Overview: React.FC<RouteComponentProps> = () => {
  const ref = useRef(null)
  const [articles, setArticles] = React.useState([]);


  const initSlider = () => {
    if ((window as any).$) {
      (window as any).$("#offer.owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        dots: false,
        animateOut: true,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 3
          },
          1000: {
            items: 5
          }
        }
      })
    }
  }

  React.useEffect(() => {
    axios('/api/v1/articles/joboffers/public')
      .then(res => {
        setArticles(res.data);
        initSlider();
      });
  }, []);

  return (
    <NewsWrapper id="offer" ref={ref} className="owl-carousel">
      {
        articles.map((article, key) => {
          return (
            <NewItem key={key} item={article} />
          )
        })
      }
    </NewsWrapper>
  )
}

export default Overview;