import React from "react";
import { handleErrors } from "utils/http";
import { RouteComponentProps } from "@reach/router";
import axios from "axios";
import SearchBar from "./SearchBar";
import SearchArticle from "./SearchArticle";
import SearchSheet from "./SearchSheet";
import SearchOffers from "./SearchOffers";

const defaultValues = {
  name: "",
  lastnme: "",
  email: "",
  subject: "",
  message: "",
};

export default function ContactPage(props: RouteComponentProps) {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    let path = "/api/v1/search";

    let params = new URLSearchParams(window.location.search);
    const query = params.get("q");

    if (query) {
      axios.get(path + "?search=" + query).then((res) => {
        if (res.status === 200) setData(res.data);
      });
    }
  }, []);

  return (
    <>
      <section className="w3l-mag-main">
        <div className="mag-content-inf">
          <div className="container">
            <br />
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Accueil</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/recherche">Recherche</a>
                  </li>
                </ol>
              </nav>
            </div>

            <br />
            <br />

            <h1 className="text-center">Recherche</h1>
            <br />
            <SearchBar />

            {data && Object.keys(data).length === 0 ? (
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <br />
                    <div className="card-simple text-center">
                      <br />
                      <img src="/images/search-empty-icon.png" alt="" />
                      <p>
                        <br />
                        <h4>Votre recherche n'a pas donné de résultat.</h4>
                      </p>
                      <br />
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            ) : (
              <div className="blog-inner-grids py-md-4 row">
                <div className="mag-content-left-hny col-lg-12">
                  {data?.studentLife?.length > 0 && (
                    <div className="mag-hny-content">
                      <br />
                      <h3 className="hny-title clrs-2">Articles</h3>
                      <div className="maghny-grids-inf">
                        <SearchArticle articles={data?.studentLife || []} />
                      </div>
                    </div>
                  )}

                  {data?.jobs?.length > 0 && (
                    <div className="mag-hny-content">
                      <br />
                      <h3 className="hny-title clrs-1">Les métiers</h3>
                      <div className="maghny-grids-inf">
                        <SearchSheet data={data?.jobs || []} />
                      </div>
                    </div>
                  )}

                  {data?.offers?.length > 0 && (
                    <div className="mag-hny-content">
                      <br />
                      <h3 className="hny-title clrs-1">Offres d'emploi</h3>
                      <div className="maghny-grids-inf">
                        <SearchOffers data={data?.offers || []} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>

    // <div id="fh5co-contact">
    //   <div className="container">
    //     <div className="row">
    //       <div className="col-md-12">
    //         <nav aria-label="breadcrumb">
    //           <ol className="breadcrumb">
    //             <li className="breadcrumb-item"><a href="/">Accueil</a></li>
    //             <li className="breadcrumb-item"><a href="/recherche">Recherche</a></li>
    //           </ol>
    //         </nav>
    //       </div>
    //     </div>
    //     <br />
    //     <br />

    //     <h1 className="text-center">Recherche</h1>
    //     <br />
    //     <SearchBar />

    //     <h3 className="hny-title clrs-3">Vie étudiante</h3>

    //   </div>
    // </div>
  );
}
