import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: var(--primary-color);
  position: absolute!important;
  bottom: 0;
  width: 100%;
  color: #fff;
  text-align: center;
  padding: 15px;
`;

const TinyFooter: React.FC = () =>
  <Wrapper role="contentinfo">
    <small className="block">&copy; 2020 Studies. Tous droits réservés.</small>
  </Wrapper >

export default TinyFooter;