import axios from "axios";
import { useEffect, useState } from "react";
import { useAuthToken } from "./useAuthToken";

const useCurrentParticular = () => {
  const [authToken] = useAuthToken();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get("/api/v1/teachers/current", {
      headers: {
        ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {}),
      }
    }).then(res => {
      setData(res.data);
    })
      .catch(error => {
        setError(error);
      })

  }, []);

  return [data, error];
};



export default useCurrentParticular;