import React from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import moment from "moment";
import Covers from "pages/Covers";
import Adser from "pages/Adser";

const Wrapper = styled.div`
  background: #f1f1f1;
  padding: 20px;
  min-height: 90vh;


  .img-responsive {
    height: 250px;
    width: 100%;
  }
`

const Title = styled.div`
  font-size: 30px;
  color: var(--title-color);
`

const Date = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: var(--secondary-color);
  padding: 15px 0;
`

const ArticleCover = styled.div`
  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
`

const Desc = styled.p`
  background-color: #fff;
  padding: 10px 10px;
  border-left: 3px solid #FF4C28;
`

interface IProps extends RouteComponentProps {
  slug?: string;
}

export default function SheetPage({ slug }: IProps) {
  const [sheet, setSheet] = React.useState(null);

  React.useEffect(() => {
    fetch('/api/v1/articles/public/slug/' + slug)
      .then(res => res.json())
      .then((data) => {
        setSheet(data);
      })
      .catch(console.log)
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <br />
            <Wrapper>
              <Title>{sheet?.name}</Title>
              <Date>{moment(sheet?.createdAt).format('MMMM DD, YYYY')}</Date>
              {
                sheet?.description &&
                <Desc>{sheet?.description}</Desc>
              }

              {
                sheet?.cover_image &&
                <ArticleCover style={{ backgroundImage: `url(${sheet?.cover_image})` }} />
              }
              <br />
              <div dangerouslySetInnerHTML={{ __html: sheet?.presentation }}></div>
            </Wrapper>
            <br />
          </div>
          <div className="col-md-4">
            <br />
            <Adser />
            <br />
          </div>
        </div>
      </div>
    </>
  )
}
