import React from 'react';
import styled from 'styled-components';

const Link = styled.a`
  background-color: var(--secondary-color);
  color: #fff;
  width: 100%;

  :hover {
    background-color: var(--primary-color);
    color: #fff;
  }
`;
const ShowMoreOfferBtn = () => {
  return (
    <Link href="/stage-et-emploi/offres" className="btn school-card-button" style={{ padding: "8px 20px" }}>
      Voir plus d'offres
    </Link>
  )
}

export default ShowMoreOfferBtn;