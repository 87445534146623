import { CandidatCreationContext } from "context/CandidatCreation";
import LabelRadio from "pages/Tutoring/LabelRadio";
import React from "react";
import TextField from "ui/TextField";
import { Button, TabFooter, TabHeader, TabWapper } from "pages/Auth/style";
import Select from "react-select";
import axios from "axios";
import clsx from "clsx";
const defaultValues = {
  civility: "",
  firstname: "",
  lastname: "",
  birthday: "",
  email: "",
  phonenumber: "",
  city: null as { value: string; label: string },
  address: "",

  condition: false,
};

const CourseTab = () => {
  const { tabValue, setTabValue, info, setInfo } = React.useContext(
    CandidatCreationContext
  );
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [submitted, setSubmitted] = React.useState(false);
  const [cities, setCities] = React.useState([]);

  React.useEffect(() => {
    if (info) {
      setFormValues(info);
    }

    axios.get("/api/v1/studies/cities").then((res) => {
      setCities(res.data);
    });
  }, []);

  const next = () => {
    setSubmitted(true);

    if (
      !Boolean(formValues.city) ||
      !Boolean(formValues.address) ||
      !Boolean(formValues.firstname) ||
      !Boolean(formValues.lastname) ||
      !Boolean(formValues.civility) ||
      !Boolean(formValues.birthday) ||
      !Boolean(formValues.email) ||
      !Boolean(formValues.city) ||
      !Boolean(formValues.phonenumber)
    )
      return;

    setInfo(formValues);

    setTabValue(tabValue + 1);
  };

  const handleInputChange = React.useCallback(
    (event) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  return (
    <TabWapper>
      <TabHeader>
        <h4>Informations</h4>
        Remplissez votre profil (tous les champs sont obligatoires).
      </TabHeader>
      <div>
        <div className="col-md-12s">
          <label htmlFor="">VOTRE CIVILITÉ *</label>
          <br />
          <LabelRadio
            onChange={handleInputChange}
            id="Madame"
            label="Madame"
            name="civility"
            value="Mme"
            checked={formValues.civility === "Mme"}
          />
          <LabelRadio
            onChange={handleInputChange}
            id="Monsieur"
            label="Monsieur"
            name="civility"
            value="Mrs"
            checked={formValues.civility === "Mrs"}
          />
          {submitted && !Boolean(formValues.civility) && (
            <div className="text-error">
              Veuillez sélectionner votre civilité
            </div>
          )}
        </div>

        <div className="">
          <fieldset>
            <TextField
              value={formValues.firstname}
              onChange={handleInputChange}
              label="Nom *"
              name="firstname"
              type="text"
              required
              error={submitted && !Boolean(formValues.firstname)}
              helpText={
                submitted &&
                !Boolean(formValues.firstname) &&
                "Veuillez saisir votre nom"
              }
            />
          </fieldset>
        </div>

        <div className="">
          <fieldset>
            <TextField
              value={formValues.lastname}
              onChange={handleInputChange}
              label="Prénom *"
              name="lastname"
              type="text"
              required
              error={submitted && !Boolean(formValues.lastname)}
              helpText={
                submitted &&
                !Boolean(formValues.lastname) &&
                "Veuillez saisir votre prénom"
              }
            />
          </fieldset>
        </div>

        <div className="col-md-6d">
          <fieldset>
            <TextField
              value={formValues.birthday}
              onChange={handleInputChange}
              label="Date de naissance *"
              name="birthday"
              type="date"
              required
              error={submitted && !Boolean(formValues.birthday)}
              helpText={
                submitted &&
                !Boolean(formValues.birthday) &&
                "Veuillez entrer votre date de naissance"
              }
            />
          </fieldset>
        </div>

        <div className="col-md-s6">
          <fieldset>
            <TextField
              value={formValues.email}
              onChange={handleInputChange}
              label=" Email *"
              name="email"
              type="email"
              required
              error={submitted && !Boolean(formValues.email)}
              helpText={
                submitted &&
                !Boolean(formValues.email) &&
                "Veuillez saisir votre email"
              }
            />
          </fieldset>
        </div>

        <div className="col-md-6s">
          <fieldset>
            <TextField
              value={formValues.phonenumber}
              onChange={handleInputChange}
              label="Téléphone *"
              name="phonenumber"
              type="text"
              required
              error={submitted && !Boolean(formValues.phonenumber)}
              helpText={
                submitted &&
                !Boolean(formValues.phonenumber) &&
                "Veuillez saisir votre numéro de téléphone"
              }
            />
          </fieldset>
        </div>

        <div className="col-md-6s">
          <fieldset>
            <label>Ville *</label>
            <Select
              options={cities?.map((item: any) => ({
                value: item.name,
                label: item.name,
              }))}
              name="city"
              value={formValues.city}
              onChange={(value, action) => {
                setFormValues({
                  ...formValues,
                  city: value,
                });
              }}
              className={clsx({
                "is-invalid": submitted && !Boolean(formValues.city),
              })}
            />
            <div className="invalid-feedback">
              Veuillez sélectionner votre ville
            </div>
          </fieldset>
        </div>

        <div className="col-md-6s">
          <fieldset>
            <TextField
              value={formValues.address}
              onChange={handleInputChange}
              label="Adresse *"
              name="address"
              type="text"
              required
              error={submitted && !Boolean(formValues.address)}
              helpText={
                submitted &&
                !Boolean(formValues.address) &&
                "Veuillez saisir votre Adresse"
              }
            />
          </fieldset>
        </div>
      </div>
      <TabFooter>
        <Button onClick={next} className="btn next">
          Suivant
        </Button>
      </TabFooter>
    </TabWapper>
  );
};

export default CourseTab;
