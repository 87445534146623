import React from "react";
import styled from "styled-components";
import moment from "moment";

const Wrapper = styled.div`
  border: 0 0.3rem 0.94rem rgba(128,128,128,0.06);
  border-radius: 5px;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  margin: 15px 0;
  height: 100px;
  cursor: pointer;

  :hover {
    box-shadow: 0 2px 6px 4px rgba(0,0,0,0.1);
  }
`;

const Title = styled.h4`
  color: #233266;
  font-weight: 600;
  font-size: 20px;
  margin: 0;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Date = styled.span`
  font-style: italic;
  font-size: 14px;
`;

export const Status = styled.span`
  font-style: italic;
  font-size: 14px;
  margin-left: 15px;
  background-color: #2A51B7;
  padding: 2px 5px;
  border-radius: 100px;
  color: #fff;
  font-weight: normal;
  padding: 3px 10px;
`;

const Butt = styled.span`
  font-style: italic;
  font-size: 12px;
  margin-left: 15px;
  background-color: #2A51B7;
  padding: 2px 5px;
  border-radius: 100px;
  color: #fff;
  font-weight: normal;
`;

interface ApplicationItemProps {
  item: any;
}

export const JOB_OFFER_STATUS = {
  TO_VALIDATE: "À valider",
  VALIDATE: "Validé"
}


const ApplicationItem = ({ item }: ApplicationItemProps) => {

  return (
    <Wrapper className="col-lg-12">
      <Top>
        <Title>
          {item.title}
          <Status style={{ backgroundColor: item.status === "TO_VALIDATE" ? "#B77400" : "" }}>
            {JOB_OFFER_STATUS[item.status]}
          </Status>
        </Title>
        <Date>
          Créer le {moment(item.createdAt).format('MMMM D YYYY, h:mm')}
        </Date>
      </Top>
      <br />
      <Top>
        <a href={`/stage-et-emploi/e/entreprise/candidatures/${item?.jobId}`}>Candidatures ({item.applicationsCount})</a>

        <a href={`/stage-et-emploi/offres?jobid=${item?.jobId}`} target="_blank">Voir l'offre en ligne</a>
      </Top>
    </Wrapper>
  )
}

export default ApplicationItem;
