import React from "react";
import Rating from "react-rating";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  padding: 15px;
  display: grid;
  grid-template-columns: 130px auto;
  grid-template-rows: 130px;
  cursor: pointer;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 15px;
  color: #000;

  :hover {
    box-shadow: 0 8px 10px 5px rgb(0 0 0 / 10%);
  }

  .btn {
    font-size: 14px;
    padding: 5px 10px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  border-radius: 8px;

  i {
    color: rgba(81, 37, 129, .7);
    font-size: 50px;
  }
`;

const InfosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 15px;
  color: #777;

  a {
    color: #512581;
  }
`;

const Title = styled.h4`
  color: #512581;
  font-weight: bold;
  font-size: 20px;
  margin: 0;
`;

interface IProps {
  item: any;
}
export default function StudiesPage({ item }: IProps) {

  return (
    <a href={`/etudes/${item.slug}`}>
      <Wrapper>
        <ImageWrapper>
          {
            item?.logo_image ?
              <img src={item.logo_image} className="img-responsive" />
              :
              <i className="fas fa-school"></i>
          }
        </ImageWrapper>
        <InfosWrapper>
          <div>
            <Title><a href={`/etudes/${item.slug}`}>{item.name}</a></Title>
            <span><i className="fas fa-map-marker-alt"></i> {item.city} {item.address}</span>
          </div>
          <div>
            {item.phonenumber}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Rating
              className="average"
              emptySymbol={<i className="far fa-star"></i>}
              fullSymbol={<i className="fas fa-star" style={{ color: "#512581" }}></i>}
              initialRating={item.rating || 0}
              readonly
            />
            {
              item?.contact_option &&
              <a href={`/etudes/${item.slug}`}>Je m'informe <i className="fas fa-chevron-right"></i></a>
            }
          </div>
        </InfosWrapper>
      </Wrapper>
    </a>
  )
}