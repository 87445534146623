import React from "react";
import { RouteComponentProps } from "@reach/router";
import Covers from "pages/Covers";
import Ads from "pages/Ads";
import SearchBar, { SearchType } from "./SearchBar";
import SearchResults from "./SearchResults";
import Line from "./Line";
import Adser from "pages/Adser";
import FilterOptions from "./FilterOptions";
import axios from "axios";

interface IProps extends RouteComponentProps {
  slug?: string;
  type: SearchType;
}

const TITLE_BY_TYPE = {
  all: "Toutes les écoles",
  elementary_primary: "Écoles Élementaires",
  secondary: "Écoles Secondaires",
  high: "Écoles Supérieures"

}

const SearchKeyMap = {
  lieu: "city",
  ecole: "name",
  "filtre-statut": "school_status",
  "filtre-enseignement": "education_type",
  "filtre-filiere": "education_sectors",
  "filtre-niveau": "education_levels",
};

const getParams = (search: string): object =>
  Array.from(new URLSearchParams(search).entries())
    .reduce((prev, [key, value]) => ({ ...prev, ...(SearchKeyMap[key] && { [SearchKeyMap[key]]: value }) }),
      {});

export default function StudiesPage({ location, slug, type }: IProps) {
  const [data, setData] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [educationTypes, setEducationTypes] = React.useState([]);
  const [educationLevels, setEducationLevels] = React.useState([]);
  const [sectors, setSectors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);


  React.useEffect(() => {
    const path = '/api/v1/studies/public';

    const params = getParams(location.search)

    axios(path, {
      params: {
        ...params,
        school_type: type !== "all" ? type : ""
      }
    })
      .then(res => {
        setData(res.data);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      })

    axios('/api/v1/studies/cities')
      .then(res => {
        setCities(res.data);
      });

    axios('/api/v1/sheets/sectors')
      .then(res => {
        setSectors(res.data);
      });

    axios('/api/v1/settings/education-type')
      .then(res => {
        setEducationTypes(res.data.filter((item: any) => item.level === type));
      });

    axios('/api/v1/settings/school-level')
      .then(res => {
        setEducationLevels(res.data)
      });
  }, []);

  return (
    <>
      <Covers page="studies_home" />

      <Line />

      <div className="container">
        <br />
        <SearchBar
          type={type}
          cities={cities}
          educationTypes={educationTypes}
          sectors={sectors}
          educationLevels={educationLevels}
        />
      </div>

      <div id="fh5co-blog" >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="row">

                <div className="col-md-4">
                  <FilterOptions
                    type={type}
                    cities={cities}
                    educationTypes={educationTypes}
                    sectors={sectors}
                    educationLevels={educationLevels}
                  />
                </div>

                <div className="col-lg-8 col-md-12">
                  <div className="search-results">
                    <div className="search-results-title">
                      <h2 className="search-filter-title"> {TITLE_BY_TYPE[type]} </h2>
                      <hr />
                    </div>

                    <div>

                      {
                        loading &&
                        <div className="col-md-12 text-center">
                          <br />
                          <div className="spinner-grow" style={{ width: "4rem", height: "4rem" }} role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          <br />
                        </div>

                      }

                      {
                        !loading &&
                        <SearchResults data={data} />
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <br />
              <Adser />
              <br />
              <Ads sticky page="studies_home" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
