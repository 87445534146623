import React from "react";
import clsx from "clsx";

interface TextFieldProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  label?: string;
  inputRef?: any;
  error?: boolean;
  helpText?: string;
}

const SelectField = ({ label, inputRef, helpText, error, ...props }: TextFieldProps) => {
  return (
    <>
      <label>{label}</label>
      <select
        {...props}
        ref={inputRef}
        className={clsx("form-control", {
          "is-invalid": error
        })}
      >
        {props.children}
      </select>
      <div className="invalid-feedback">
        {helpText}
      </div>
    </>
  )
}
export default SelectField;
