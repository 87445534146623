
import React from "react";
import HeaderJobOffer from 'ui/Header/HeaderJobOffer'
import TinyFooter from 'ui/Footer/TinyFooter'
import styled from "styled-components";
import "./style.css";
import useCurrentEntreprise from "hooks/useCurrentEntreprise";
import { Header } from "./style";
import axios from "axios";
import { useAuthToken } from "hooks/useAuthToken";

const Cover = styled.div`
  width: 100%;
  background-color: white;
  height: 150px;
  background-image: url(/images/cover-entreprise.jpg);
      background-position: 50% 1%;
    background-size: cover;
`;

const IconWrapper = styled.div`
  margin-top: -75px;
  border-radius: 5px;
  width: 100px;
  height: 100px;
  background-color: #e1eaef;
  color: #233266;
  display: flex;
  justify-content:  center;
  align-items: center;
`;

const Name = styled.h1`
  margin-top: 8px;
  font-size: 2.5rem;
  font-weight: bold;
`;

const Address = styled.h6`
  margin-top: 8px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #828282;
`;


const EntrepriseLayout = (props: any) => {
  const { children } = props;
  const [currentUser] = useCurrentEntreprise();
  const [hasCvthequeOption, setHasCvthequeOption] = React.useState(false);

  const [authToken] = useAuthToken();

  React.useEffect(() => {
    axios.get("/api/v1/entreprises/cvtheque/has-option", {
      headers: {
        ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {}),
      }
    }).then(res => {
      if (res.status === 200) {
        setHasCvthequeOption(res.data.has_cvtheque)
      }
    })
  }, []);

  return (
    <div id="page">
      <HeaderJobOffer redirectPath="/stage-et-emploi" />
      <Header>
        <Cover />
        <div className="container" >
          <div className="row">
            <div className="col-md-12">
              <IconWrapper>
                {
                  currentUser?.profile_image ?
                    <img src={currentUser.profile_image} className="img-responsive" style={{ height: "100%" }} />
                    :
                    <i className="fas fa-building" style={{ fontSize: "50px" }}></i>
                }
              </IconWrapper>
            </div>
            {/* <div className="col-md-12">
              <Name>{user?.data?.name}</Name>
              {user?.data?.address && <Address> <i className="fas fa-map-marker-alt"></i>{user?.data?.address}</Address>}
            </div> */}
            <br />
            <div className="col-md-12 fh5co-nav">
              <div className=" menu-1">
                <ul>
                  <li className={window.location.pathname.includes('candidatures') ? 'active' : ''}>
                    <a href="/stage-et-emploi/e/entreprise/candidatures">Gestion Offres</a>
                  </li>
                  {
                    hasCvthequeOption &&
                    <li className={window.location.pathname.includes('cvtheque') ? 'active' : ''}>
                      <a href="/stage-et-emploi/e/entreprise/cvtheque">CVthèque</a>
                    </li>
                  }
                  <li className={window.location.pathname.includes('presentation') ? 'active' : ''}>
                    <a href="/stage-et-emploi/e/entreprise/presentation">Présentation</a>
                  </li>
                  <li className={window.location.pathname.includes('coordonnees') ? 'active' : ''}>
                    <a href="/stage-et-emploi/e/entreprise/coordonnees">Coordonnées</a>
                  </li>
                  <li className={window.location.pathname.includes('parmaretres') ? 'active' : ''}>
                    <a href="/stage-et-emploi/e/entreprise/parmaretres">Paramètres</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Header>
      <div style={{ paddingBottom: "90px" }}>
        {children}
      </div>
      <TinyFooter />
    </div>
  )
}


export default EntrepriseLayout;