import React from "react";
import { OverviewCard, OverviewCardHeader } from "../../style";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import axios from "axios";
import TextField from "ui/TextField";
import { useAuthToken } from "hooks/useAuthToken";
import useCurrentFamily from "hooks/useCurrentFamily";
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  name: yup.string().required(),
  in_charge: yup.string().required(),
  phonenumber: yup.string().required(),
  city: yup.string().required(),
  district: yup.string().required(),
});

export default function Application({ }) {

  const { register, handleSubmit, errors, formState } = useForm({ resolver: yupResolver(schema) });

  const [authToken] = useAuthToken();
  const [currentUser] = useCurrentFamily();

  const onSubmit = (data: any) => {
    axios.put("/api/v1/families/profile", {
      ...data
    }, {
      headers: {
        ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {}),
      }
    }).then(res => {
      toast.success("Profil modifié avec succès!");
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    })
      .catch(error => {
        toast.error("Erreur!");
      });
  }

  return (
    <div className="container">
      <OverviewCard>
        <div className="row">
          <OverviewCardHeader className="col-md-12">
            <h4 className="page-title">Profil</h4>
          </OverviewCardHeader>

          <div className="col-lg-12">
            <form className="row" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-6">
                <fieldset>
                  <TextField
                    label="Nom de la famille"
                    name="name"
                    type="text"
                    required
                    inputRef={register}
                    error={!!errors.name}
                    helpText={errors.name?.message}
                    defaultValue={currentUser?.name}
                  />
                </fieldset>
              </div>

              <div className="col-md-6">
                <fieldset>
                  <TextField
                    label="Responsable"
                    name="in_charge"
                    type="text"
                    required
                    inputRef={register}
                    error={!!errors.in_charge}
                    helpText={errors.in_charge?.message}
                    defaultValue={currentUser?.in_charge}
                  />
                </fieldset>
              </div>
              <div className="col-md-6">
                <fieldset>
                  <TextField
                    label="Email"
                    type="text"
                    defaultValue={currentUser?.email}
                    disabled
                  />
                </fieldset>
              </div>
              <div className="col-md-6">
                <fieldset>
                  <TextField
                    label="Téléphone"
                    name="phonenumber"
                    type="text"
                    required
                    inputRef={register}
                    error={!!errors.phonenumber}
                    helpText={errors.phonenumber?.message}
                    defaultValue={currentUser?.phonenumber}
                  />
                </fieldset>
              </div>
              <div className="col-md-6">
                <fieldset>
                  <TextField
                    label="Ville"
                    name="city"
                    type="text"
                    required
                    inputRef={register}
                    error={!!errors.city}
                    helpText={errors.city?.message}
                    defaultValue={currentUser?.city}
                  />
                </fieldset>
              </div>

              <div className="col-md-6">
                <fieldset>
                  <TextField
                    label="Quartier"
                    name="district"
                    type="text"
                    required
                    inputRef={register}
                    error={!!errors.district}
                    helpText={errors.district?.message}
                    defaultValue={currentUser?.district}
                  />
                </fieldset>
              </div>

              <div className="col-md-12">
                <fieldset>
                  <button className="btn btn-primary">Mettre à jour</button>
                </fieldset>
              </div>
            </form>
          </div>
        </div>
      </OverviewCard>
    </div>
  )
}
