import axios from "axios";
import clsx from "clsx";
import { TeacherCreationContext } from "context/TeacherCreation";
import { Button, TabFooter, TabHeader, TabWapper } from "pages/Auth/style";
import LabelRadio from "pages/Tutoring/LabelRadio";
import React from "react";
import Select from "react-select";
import Checkbox from "ui/Checkbox";
import TextField from "ui/TextField";
const defaultValues = {
  civility: "",
  firstname: "",
  lastname: "",
  birthday: "",
  email: "",
  phonenumber: "",
  city: null as { value: string; label: string },
  district: "",
  interventionTypes: ["onsite"],
  condition: false,
};

const CourseTab = () => {
  const { tabValue, setTabValue, profile, setProfile } = React.useContext(
    TeacherCreationContext
  );
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [submitted, setSubmitted] = React.useState(false);
  const [cities, setCities] = React.useState([]);

  React.useEffect(() => {
    if (profile) {
      setFormValues(profile);
    }
  }, [profile]);
  React.useEffect(() => {
    axios.get("/api/v1/studies/cities").then((res) => {
      setCities(res.data);
    });
  }, []);

  const back = () => {
    setTabValue(tabValue - 1);
  };

  const next = () => {
    setSubmitted(true);

    if (
      !Boolean(formValues.city) ||
      !Boolean(formValues.district) ||
      !Boolean(formValues.firstname) ||
      !Boolean(formValues.lastname) ||
      !Boolean(formValues.civility) ||
      !Boolean(formValues.birthday) ||
      !Boolean(formValues.email) ||
      !Boolean(formValues.city) ||
      !Boolean(formValues.phonenumber)
    )
      return;

    setProfile(formValues);

    setTabValue(tabValue + 1);
  };

  const handleInputChange = React.useCallback(
    (event) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  const handleIntervention = (e: any) => {
    const types = e.target.value;
    if (formValues.interventionTypes?.includes(types)) {
      setFormValues((prev) => ({
        ...prev,
        interventionTypes: prev.interventionTypes?.filter(
          (value) => value !== types
        ),
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        interventionTypes: [...prev.interventionTypes, types],
      }));
    }
  };

  return (
    <TabWapper>
      <TabHeader>
        <h4>Informations</h4>
        Remplissez votre profil afin que nous puissions vous contacter (tous les
        champs sont obligatoires).
      </TabHeader>
      <div>
        <div className="col-md-12s">
          <label htmlFor="">VOTRE CIVILITÉ *</label>
          <br />
          <LabelRadio
            onChange={handleInputChange}
            id="Madame"
            label="Madame"
            name="civility"
            value="Mme"
            checked={formValues.civility === "Mme"}
          />
          <LabelRadio
            onChange={handleInputChange}
            id="Monsieur"
            label="Monsieur"
            name="civility"
            value="Mrs"
            checked={formValues.civility === "Mrs"}
          />
          {submitted && !Boolean(formValues.civility) && (
            <div className="text-error">
              Veuillez sélectionner votre civilité
            </div>
          )}
        </div>

        <div className="">
          <fieldset>
            <TextField
              value={formValues.firstname}
              onChange={handleInputChange}
              label="Nom *"
              name="firstname"
              type="text"
              required
              error={submitted && !Boolean(formValues.firstname)}
              helpText={
                submitted &&
                !Boolean(formValues.firstname) &&
                "Veuillez saisir votre nom"
              }
            />
          </fieldset>
        </div>

        <div className="">
          <fieldset>
            <TextField
              value={formValues.lastname}
              onChange={handleInputChange}
              label="Prénom *"
              name="lastname"
              type="text"
              required
              error={submitted && !Boolean(formValues.lastname)}
              helpText={
                submitted &&
                !Boolean(formValues.lastname) &&
                "Veuillez saisir votre prénom"
              }
            />
          </fieldset>
        </div>

        <div className="col-md-6d">
          <fieldset>
            <TextField
              value={formValues.birthday}
              onChange={handleInputChange}
              label="Date de naissance *"
              name="birthday"
              type="date"
              required
              error={submitted && !Boolean(formValues.birthday)}
              helpText={
                submitted &&
                !Boolean(formValues.birthday) &&
                "Veuillez entrer votre date de naissance"
              }
            />
          </fieldset>
        </div>

        <div className="col-md-s6">
          <fieldset>
            <TextField
              value={formValues.email}
              onChange={handleInputChange}
              label=" Email *"
              name="email"
              type="email"
              required
              error={submitted && !Boolean(formValues.email)}
              helpText={
                submitted &&
                !Boolean(formValues.email) &&
                "Veuillez saisir votre email"
              }
            />
          </fieldset>
        </div>

        <div className="col-md-6s">
          <fieldset>
            <TextField
              value={formValues.phonenumber}
              onChange={handleInputChange}
              label="Téléphone *"
              name="phonenumber"
              type="text"
              required
              error={submitted && !Boolean(formValues.phonenumber)}
              helpText={
                submitted &&
                !Boolean(formValues.phonenumber) &&
                "Veuillez saisir votre numéro de téléphone"
              }
            />
          </fieldset>
        </div>

        <div className="col-md-6s">
          <fieldset>
            <label>Ville *</label>
            <Select
              options={cities?.map((item: any) => ({
                value: item.name,
                label: item.name,
              }))}
              name="city"
              placeholder=""
              value={formValues.city}
              onChange={(value, action) => {
                setFormValues({
                  ...formValues,
                  city: value,
                });
              }}
              className={clsx({
                "is-invalid": submitted && !Boolean(formValues.city),
              })}
            />
            <div className="invalid-feedback">
              Veuillez sélectionner votre ville
            </div>
          </fieldset>
        </div>

        <div className="col-md-6s">
          <fieldset>
            <TextField
              value={formValues.district}
              onChange={handleInputChange}
              label="Quartier *"
              name="district"
              type="text"
              required
              error={submitted && !Boolean(formValues.district)}
              helpText={
                submitted &&
                !Boolean(formValues.district) &&
                "Veuillez saisir votre quartier"
              }
            />
          </fieldset>
        </div>

        <div className="col-md-6s">
          <fieldset>
            <label>Types d'intervention souhaités *</label>

            <div
              className={clsx("row", {
                "is-invalid":
                  submitted && !formValues.interventionTypes?.length,
              })}
            >
              <div className="col-md-2">
                <Checkbox
                  onChange={handleIntervention}
                  name="interventionTypes"
                  value="onsite"
                  label="À domicile"
                  checked={formValues.interventionTypes?.includes("onsite")}
                />
              </div>
              <div className="col-md-2">
                <Checkbox
                  onChange={handleIntervention}
                  name="interventionTypes"
                  value="online"
                  label="En ligne"
                  checked={formValues.interventionTypes?.includes("online")}
                />
              </div>
            </div>

            <div className="invalid-feedback">
              Veuillez sélectionner les Types d'intervention souhaités
            </div>
          </fieldset>
        </div>
      </div>
      <TabFooter>
        <Button onClick={back} className="btn back">
          Précédent
        </Button>
        <Button onClick={next} className="btn next">
          Suivant
        </Button>
      </TabFooter>
    </TabWapper>
  );
};

export default CourseTab;
