import React from "react";
import Layout from "ui/Layout";
import { Router, RouteComponentProps } from "@reach/router";
import HomePage from "./Home";
import AboutPage from "./About";
import ContactPage from "./Contact";
import Advertisers from "./Advertisers";
import Article from "./Article";
import AdvertisersStudies from "./Advertisers/pages/Studies";
import AdvertisersEntreprise from "./Advertisers/pages/Entreprises";
import AdvertisersHome from "./Advertisers/pages/Advertisers";
import Searching from "./Searching";

const Overview: React.FC<RouteComponentProps> = () =>
  <Layout>
    <Router>
      <HomePage path="/" />
      <AboutPage path="/a-propos" />
      <ContactPage path="nous-contacter" />
      <Searching path="recherche" />
      <Advertisers path="/espace-annonceurs" />
      <AdvertisersHome path="/espace-annonceurs/annonceurs" />
      <AdvertisersStudies path="/espace-annonceurs/etudes" />
      <AdvertisersEntreprise path="/espace-annonceurs/entreprises" />
      <Article path="/articles/:slug" />
    </Router>
  </Layout>

export default Overview;