import { useEffect } from "react";
import { isAfter } from "date-fns";
import { useLogout } from "./useLogout";
import { useAuthToken } from "./useAuthToken";
import { navigate } from "@reach/router";

const getData = (jwtToken?: string) => {
  let jwt = null;
  if (jwtToken)
    jwt = JSON.parse(atob(jwtToken.split(".")[1]));
  return jwt;
}
const getExpirationDate = (jwtToken?: string): number | null => {
  if (!jwtToken) {
    return null;
  }
  const jwt = JSON.parse(atob(jwtToken.split(".")[1]));
  return (jwt && jwt.exp && jwt.exp * 1000) || null;
};

const useAuth = () => {
  const [authToken] = useAuthToken();
  const logout = useLogout();

  useEffect(() => {
    if (Boolean(authToken)) {
      if (isAfter(new Date(), new Date(Number(getExpirationDate(authToken))))) {
        logout();
        navigate("/auth/~p/signin");
      }
    }
  });

  return [Boolean(authToken), authToken, getData(authToken)];
};

export default useAuth;