import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Desc } from "pages/StudentLife/Article";
export default function AdvertisersHome(props: RouteComponentProps) {


  return (
    <>
      <aside id="fh5co-hero" className="joboffer">
        <div className="flexslider">
          <ul className="slides">
            <li style={{ backgroundImage: `url(/images/works.jpg)` }}>
              <div className="overlay-gradient"></div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-md-offset-2 slider-text">
                    <div className="slider-text-inner">
                      <h1 style={{ fontSize: 35 }}>
                        Vous souhaitez promouvoir votre activité?
                        <br /> profitez de nos audiences pour booster votre activité? découvrez nos tarifs annonceurs
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </aside>
      <div className="container">
        <br />
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Accueil</a></li>
                <li className="breadcrumb-item"><a href="/espace-annonceurs">Espace annonceurs</a></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 style={{ color: "#DD2421", textAlign: "center" }}>Contenu de l'offre</h3>
            <ol className="bull-list">
              <li className="bull-itdem">
                <Desc style={{ background: "#f1f1f1", fontSize: "15px", color: "#000" }}>
                  En tant qu’annonceur, recourir à l’offre digitale de Studies vous permet d’atteindre des objectifs
                  variés. Nous avons développé un ensemble de solutions pour vous permettre
                  <br />
                  . D'augmenter votre audience et gagner en visibilité
                  <br />
                  . De positionner et développer votre image de marque
                  <br />
                  . D'informer; travailler votre notoriété et optimiser votre référencement, le tout dans un
                  contexte brand safety.

                </Desc>
              </li>
              <li className="bull-item">
                <h2 className="bull-headline">OFFRE EMAILING ET NEWSLETTERS</h2><span>
                  Ciblez directement votre public via l’emailing et les newsletters (classiques, régionales ou
                  dédiées). Une base fraîche et une politique d'envois régulés auprès de contacts opt-in qualifiés.
offre renouvelable valable entre 02 semaines et 01 mois</span>
              </li>
              <li className="bull-item">
                <h2 className="bull-headline">OPTION DIGITAL CONTENT ECRIT</h2><span>
                  Les équipes de Studies rédigent pour vous des articles sur votre thématique.
                  Comptez également le publi info avec relais social ainsi que les jeux-concours dédiés. Les
                  articles rédigés (annonces, journées portes-ouvertes, remise de diplômes, ouverture
                  concours, communications d'entreprises, etc...) sont publiés en page d'accueil du site Studies
pendant 02semaines à 01 mois reconductible.</span>
              </li>
              <li className="bull-item">
                <h2 className="bull-headline">OPTION Digital CONTENT VIDEO</h2><span>
                  Un reportage filmé de votre établissement réalisé par les équipes de Studies en collaboration
                  avec vous, qui sera ensuite diffusé sur Studies et/ou sur votre propre site pendant une durée
                  de 02 semaines à 01 mois reconductible.
</span>
              </li>
              <li className="bull-item">
                <h2 className="bull-headline">OPTION Digital CONTENT RESEAUX SOCIAUX</h2><span>
                  Pour développer votre image de marque, augmenter votre rayonnement, et garantir l'affinité
                  avec un public ciblé, vos contenus font l'objet de Facebook Lives et de stories Instagram. Ils
                  profitent également de la puissance de Studies pendant une durée de 02 semaines à 01 mois
                  reconductible.
</span>
              </li>
            </ol>

            <h3 style={{ color: "#DD2421", textAlign: "center" }}>Tarifs</h3>
            <table className="table table-bordered table-hover table-striped">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Offre</th>
                  <th scope="col">(02semaines)</th>
                  <th scope="col">(01mois)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    BANNIERE PUBLICITAIRE PAGE D'ACCCUEIL
                  </td>
                  <td>70 000 FCFA</td>
                  <td>125 000 FCFA</td>
                </tr>

                <tr>
                  <td>
                    BANNIERE PUBLICITAIRE (autres pages)
                  </td>
                  <td>55 000 FCFA</td>
                  <td>100 000 FCFA</td>
                </tr>

                <tr>
                  <td>
                    ENCART PUBLICITAIRE FORMAT IMAGE (par insert)
                  </td>
                  <td>35 000 FCFA</td>
                  <td>60 000 FCFA</td>
                </tr>

                <tr>
                  <td>
                    ENCART PUBLICITAIRE FORMAT VIDEO
                  </td>
                  <td>45 000 FCFA</td>
                  <td>80 000 FCFA</td>
                </tr>

                <tr>
                  <td>
                    DIGITAL CONTENT VIDEO
                  </td>
                  <td>75 000 FCFA</td>
                  <td>150 000 FCFA</td>
                </tr>

                <tr>
                  <td>
                    DIGITAL CONTENT ECRIT
                  </td>
                  <td>45 000 FCFA</td>
                  <td>80 000 FCFA</td>
                </tr>

                <tr>
                  <td>
                    DIGITAL CONTENT ECRIT
                  </td>
                  <td>55 000 FCFA</td>
                  <td>105 000 FCFA</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
