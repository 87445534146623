import React from "react";
import { RouteComponentProps, Router } from "@reach/router";
import Signin from "./Signin";
import Signup from "./Signup";
import Activate from "./Activate";
import New from "./Reset/New";
import Reset from "./Reset/Reset";

const Particular: React.FC<RouteComponentProps> = () => {
  return (
    <Router>
      <New path="/reset/new" />
      <Reset path="/reset/password/:token" />
      <Signin path="/signin" />
      <Signup path="/signup" />
      <Activate path="/activate/:token" />
    </Router>
  )
}

export default Particular;