import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  /* background-color: #512581; */
  height: 300px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 50px;
  /* justify-content: center; */
  background-image: url("/images/adser.jpg");
  background-size: cover;
  border: 1px solid #eeeeee;

  h1 {
    margin: 0;
  }

  div {
    position: absolute;
    color: #512581;
    top: 75px;
    right: 20px;

    h1 {
      font-size: 22px;
    }
      h1:last-child {
        background: #0BA094;
        padding: 5px;
        display: inline-block;
        color: #fff;
      }
  }
`;

const Adser = () => {
  return (
    <a href="">
      <Wrapper>
        <div>
          {/* <h1 style={{ fontWeight: "bold" }}>Devenez</h1> */}
          {/* <h1>annonceurs</h1> */}
          <h1>BESOIN DE VISIBILITÉ?</h1>
          <h1>ESPACE LIBRE ANNONCEUR</h1>
          <h1>CONTACTEZ-NOUS</h1>
          {/* <h1></h1> */}
        </div>
      </Wrapper>
    </a>
  );
};
export default Adser;
