import { CandidatCreationContext } from "context/CandidatCreation";
import React from "react";
import TextField from "ui/TextField";
import { Button, TabFooter, TabHeader, TabWapper } from "pages/Auth/style";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const defaultValues = {
  password: "",
  conf_password: "",
};

const CourseTab = () => {
  const [submitted, setSubmitted] = React.useState(false);
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [loading, setLoading] = React.useState(false);

  const { tabValue, setTabValue, profile, resume, info } = React.useContext(
    CandidatCreationContext
  );

  React.useEffect(() => {
    if (loading && submitted) {
      Swal.fire({
        html: "",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }

    if (submitted && !loading) {
      Swal.close();
    }
  }, [submitted, loading]);

  const handleInputChange = React.useCallback(
    (event) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  const back = () => {
    setTabValue(tabValue - 1);
  };

  const next = () => {
    setSubmitted(true);

    if (
      !Boolean(formValues.password) ||
      !Boolean(formValues.conf_password) ||
      formValues.password !== formValues.conf_password
    )
      return;
    toast.dismiss();
    setLoading(true);

    const data = {
      ...info,
      ...profile,
      password: formValues.password,
      city: info.city.value,
      sought_sectors: profile.sought_sectors
        .map((item: any) => item.value)
        .join(","),
    };

    const formData = new FormData();
    formData.append("resume", resume);
    formData.append("data", JSON.stringify(data));

    axios
      .post("/api/v1/candidats/signup", formData)
      .then((res) => {
        setLoading(false);
        if (res.status === 201) {
          setTabValue(tabValue + 1);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.status === 409) {
          toast.error("L`adresse email que vous avez utiliser existe déjà.");
        } else {
          toast.error("Une erreur s'est produite");
        }
      });
  };

  return (
    <TabWapper>
      <TabHeader>
        <h4>Compte</h4>
        Configurer votre compte
      </TabHeader>

      <div>
        <div className="">
          <fieldset>
            <TextField
              value={formValues.password}
              onChange={handleInputChange}
              label="Mot de passe *"
              name="password"
              type="password"
              required
              error={submitted && !Boolean(formValues.password)}
              helpText={
                submitted &&
                !Boolean(formValues.password) &&
                "Veuillez saisir votre mot de passe"
              }
            />
          </fieldset>
        </div>
        <div className="">
          <fieldset>
            <TextField
              value={formValues.conf_password}
              onChange={handleInputChange}
              label="Confirmer le mot de passe *"
              name="conf_password"
              type="password"
              required
              error={
                submitted &&
                (!Boolean(formValues.conf_password) ||
                  formValues.password !== formValues.conf_password)
              }
              helpText={
                submitted && !Boolean(formValues.conf_password)
                  ? "Veuillez confirmer votre mot de passe"
                  : formValues.conf_password !== formValues.password
                  ? "Confirmation incorrect"
                  : ""
              }
            />
          </fieldset>
        </div>
      </div>
      <TabFooter>
        <Button onClick={back} className="btn back">
          Précédent
        </Button>
        <Button onClick={next} className="btn next">
          Envoyer
        </Button>
      </TabFooter>
    </TabWapper>
  );
};

export default CourseTab;
