import React from "react";
import { RouteComponentProps, Router } from "@reach/router";
import Particulier from "./Candidat";
import Entreprise from "./Entreprise";
import Teacher from "./Teacher";
import Family from "./Family";

const Auth: React.FC<RouteComponentProps> = () =>
  <Router>
    <Particulier path="/~p/*" />
    <Entreprise path="/~e/*" />
    <Teacher path="/~t/*" />
    <Family path="/~f/*" />
  </Router>

export default Auth;