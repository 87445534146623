import React from "react";
import axios from "axios";


interface CoversProps {
  page: string;
}

const Covers: React.FC<CoversProps> = ({ page }) => {
  const [covers, setCovers] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    axios('/api/v1/page-covers/public?page=' + page)
      .then(res => {
        setCovers(res.data)
      });
  }, []);

  return (
    <>
      {
        covers.length > 0 &&
        <aside id="fh5co-hero" className="joboffer">
          <div className="flexslider">
            <ul className="slides">
              {
                covers.map((item, key) =>
                  <li key={key} style={{ backgroundImage: `url(${item.cover_image})` }}>
                    <div className="overlay-gradient"></div>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12 col-md-offset-2 slider-text">
                          <div className="slider-text-inner">
                            <h1>{item.name}</h1>
                            <p style={{ color: "#fff" }}>
                              {item.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              }
            </ul>
          </div>
        </aside>
      }
    </>
  )
}

export default Covers;