import React from "react";
import { navigate } from "@reach/router";
import ApplicationItem, { JOB_OFFER_STATUS, Status } from "./ApplicationItem";
import axios from "axios";
import moment from "moment";
import { OverviewCard, OverviewCardHeader } from "../../style";
import { useAuthToken } from "hooks/useAuthToken";
import CandidatItem from "./CandidatItem";
import { InfoList, Ul } from "../JobDetails";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";

const Applications = ({}) => {
  const [data, setData] = React.useState<Array<any>>([]);
  const [currentCandidate, setCurrentCandidate] = React.useState(null);

  const [authToken] = useAuthToken();

  React.useEffect(() => {
    axios
      .get("/api/v1/entreprises/mycvtheque", {
        headers: {
          ...(Boolean(authToken)
            ? { Authorization: `Bearer ${authToken}` }
            : {}),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        }
      });
  }, []);

  return (
    <div className="container">
      <div className="row">
        <OverviewCard className="col-lg-12">
          <OverviewCardHeader>
            <h4 className="page-title">CVthèque</h4>
            <button
              className="btn btn-primary"
              onClick={() =>
                navigate("/stage-et-emploi/e/entreprise/cvtheque/trouver")
              }
            >
              Trouver des candidats
            </button>
          </OverviewCardHeader>
          {data && data.length > 0 ? (
            data.map((candidat, key) => (
              <CandidatItem
                key={key}
                candidat={candidat.candidat}
                hasDelete
                onDetails={setCurrentCandidate}
              />
            ))
          ) : (
            <>Rien à afficher</>
          )}
        </OverviewCard>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Candidat
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {currentCandidate ? (
              <div className="modal-body" style={{ minHeight: "70vh" }}>
                <Ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="pills-details-tab"
                      data-toggle="pill"
                      href="#pills-details"
                      role="tab"
                      aria-controls="pills-details"
                      aria-selected="true"
                    >
                      Profil
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-resume-tab"
                      data-toggle="pill"
                      href="#pills-resume"
                      role="tab"
                      aria-controls="pills-resume"
                      aria-selected="false"
                    >
                      CV
                    </a>
                  </li>
                </Ul>

                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade active show"
                    id="pills-details"
                    role="tabpanel"
                    aria-labelledby="pills-details-tab"
                  >
                    <InfoList>
                      <li className="item">
                        <span className="item-title">Nom : </span>
                        <span> {currentCandidate.firstname}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Prénom : </span>
                        <span>{currentCandidate.lastname}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Tel : </span>
                        <span>{currentCandidate.phonenumber}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Email : </span>
                        <span>{currentCandidate.email}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Adresse : </span>
                        <span>{currentCandidate.address}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Niveau d'étude : </span>
                        <span>{currentCandidate.study_level}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">
                          Type de contrat recherché :{" "}
                        </span>
                        <span>{currentCandidate.search_contract_type}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Disponibilité : </span>
                        <span>{currentCandidate.availability}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">
                          Expérience professionnelle :{" "}
                        </span>
                        <span>{currentCandidate.current_job_level}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">
                          Situation professionnelle :{" "}
                        </span>
                        <span>{currentCandidate.professional_situation}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Métier ciblé : </span>
                        <span>{currentCandidate.sought_sectors}</span>
                      </li>
                    </InfoList>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-resume"
                    role="tabpanel"
                    aria-labelledby="pills-resume-tab"
                  >
                    {currentCandidate?.resume && (
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                        <div style={{ height: "750px" }}>
                          <Viewer
                            fileUrl={currentCandidate?.resume?.replace(
                              "http://",
                              "https://"
                            )}
                          />
                        </div>
                      </Worker>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applications;
