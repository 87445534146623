import React from "react";
import { Button, TabFooter, TabHeader, TabWapper } from "pages/Auth/style";
import axios from "axios";
import { TeacherCreationContext } from "context/TeacherCreation";
import styled from "styled-components";
import clsx from "clsx";
import Select from "react-select";
import { toast } from "react-toastify";

export const Item = styled.div`
  width: 120px;
  height: 120px;
  background-color: #fff;
  padding: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  color: #000;

  &.level {
    height: 80px;
  }

  :hover {
    background-color: var(--primary-bg);
    color: #fff;
  }

  &.checked {
    background-color: var(--primary-bg);
    color: #fff;
  }
`;

export const Path = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 30% 10%;
`;
export const PathItem = styled.div`
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
  border: 1px solid #eeeeee;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
  background-color: #fff;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Body = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, 120px);
`;

const CourseTab = () => {
  const [data, setData] = React.useState([]);
  const {
    courses: path,
    setTabValue,
    setCourses: setPath,
  } = React.useContext(TeacherCreationContext);
  const [selectedCourses, setSelectedCourses] = React.useState(path);
  const [levels, setLevels] = React.useState<any[]>([]);
  const [courses, setCourses] = React.useState<any[]>([]);
  const [classes, setClasses] = React.useState<any[]>([]);

  const [level, setLevel] = React.useState(null);
  const [course, setCourse] = React.useState(null);
  const [classe, setClasse] = React.useState(null);

  const [intrvSubmitted, setIntrvSubmitted] = React.useState(false);

  const levelChange = (value: any) => {
    setLevel(value);
    if (value) {
      const _c = levels.find((level) => level.id == value.value);
      if (_c) {
        setClasses(_c.classes);
        setCourses(_c.courses);
      }
    }
    setClasse(null);
    setCourse(null);
  };

  React.useEffect(() => {
    axios("/api/v1/tutoring/levels/for-request").then((res) => {
      setLevels(res.data);
    });

    // axios.get("/api/v1/tutoring/teacher-signup/courses")
    //   .then((res) => {
    //     setData(res.data)
    //   })
  }, []);

  const addInt = () => {
    setIntrvSubmitted(true);
    if (!Boolean(level) || !Boolean(course) || !Boolean(classe)) return;

    setSelectedCourses([{ level, course, classe }, ...selectedCourses]);
    setIntrvSubmitted(false);
    setLevel(null);
    setCourse(null);
    setClasse(null);
  };

  const removeInterv = (key: number) => {
    setSelectedCourses(selectedCourses.filter((i, index) => index != key));
  };

  const next = () => {
    toast.dismiss();
    if (
      !Boolean(selectedCourses.length) &&
      Boolean(level) &&
      Boolean(course) &&
      Boolean(classe)
    ) {
      setSelectedCourses([{ level, course, classe }]);
      setPath([{ level, course, classe }]);
      setTabValue(1);
    } else {
      if (!Boolean(selectedCourses.length)) {
        toast.error("Veuillez sélectionner les disciplines enseigner");
        return;
      }
      setPath(selectedCourses);
      setTabValue(1);
    }
  };

  return (
    <TabWapper>
      <TabHeader>
        <h4>Enseignement</h4>
        Quelle(s) discipline(s) souhaitez-vous enseigner ? (plusieurs choix
        possibles)
      </TabHeader>
      <div className="container"></div>
      <div className="row">
        <div className="col-md-11">
          <div className="row">
            <div className="col-md-4">
              <fieldset>
                <Select
                  isClearable
                  options={levels?.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  placeholder="Niveau"
                  value={level}
                  onChange={(value, action) => {
                    levelChange(value);
                  }}
                  className={clsx("", {
                    "is-invalid": intrvSubmitted && !level,
                  })}
                />
                <div className="invalid-feedback">
                  Veuillez sélectionner le niveau
                </div>
              </fieldset>
            </div>
            <div className="col-md-4">
              <fieldset>
                <Select
                  isClearable
                  options={courses?.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  placeholder="Matiere"
                  value={course}
                  onChange={(value, action) => {
                    setCourse(value);
                  }}
                  className={clsx("", {
                    "is-invalid": intrvSubmitted && !course,
                  })}
                />
                <div className="invalid-feedback">
                  Veuillez selectionner la matiere
                </div>
              </fieldset>
            </div>

            <div className="col-md-4">
              <fieldset>
                <Select
                  isClearable
                  options={classes?.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  placeholder="Classe"
                  value={classe}
                  onChange={(value, action) => {
                    setClasse(value);
                  }}
                  className={clsx("", {
                    "is-invalid": intrvSubmitted && !classe,
                  })}
                />
                <div className="invalid-feedback">
                  Veuillez selectionner la classe
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <div className="col-md-1">
          <button
            type="button"
            onClick={addInt}
            className="btn btn-primary"
            style={{ width: "52px", height: "52px", padding: "0" }}
          >
            {" "}
            <span className="fa fa-plus"></span>
          </button>
        </div>
      </div>

      <br />
      {selectedCourses.map((item, key) => (
        <Path>
          <PathItem>{item.level.label}</PathItem>
          <PathItem>{item.course.label}</PathItem>
          <PathItem>{item.classe.label}</PathItem>
          <PathItem>
            <button
              onClick={() => removeInterv(key)}
              type="button"
              style={{ border: 0 }}
              className="btn btn-outline-danger"
            >
              <i className="far fa-times-circle"></i>
            </button>
          </PathItem>
        </Path>
      ))}

      <TabFooter>
        <Button onClick={next} className="btn next">
          Suivant
        </Button>
      </TabFooter>
    </TabWapper>
  );
};

export default CourseTab;
