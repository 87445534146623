import React from "react";
import { OverviewCard, OverviewCardHeader } from "../../style";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import axios from "axios";
import TextField from "ui/TextField";
import { useAuthToken } from "hooks/useAuthToken";
import useCurrentEntreprise from "hooks/useCurrentEntreprise";
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  address: yup.string().required(),
  phonenumber: yup.string().required(),
});

export default function Application({ }) {
  const { register, handleSubmit, errors, reset, formState } = useForm({ resolver: yupResolver(schema) });

  const [authToken] = useAuthToken();
  const [currentUser] = useCurrentEntreprise();
  const [address, setAddresss] = React.useState([]);
  const [addr, setAddr] = React.useState("");

  let cancelRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (currentUser) {
      let _sectors = [];
      if (currentUser.others_address) {
        _sectors = currentUser.others_address.split(";");
        setAddresss(_sectors);
      }
    }
  }, [currentUser]);

  const onSubmit = (data: any) => {
    axios.put("/api/v1/entreprises/contact", {
      ...data
    }, {
      headers: {
        ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {}),
      }
    }).then(res => {
      toast.success("Coordonnées modifié avec succès");
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    })
      .catch(error => {
        toast.error("Erreur!");
      });
  }

  const removeAddress = (index: number) => {
    const _d = [...address];
    _d.splice(index, 1);

    axios.put("/api/v1/entreprises/contact", {
      others_address: _d.join(";")
    }, {
      headers: {
        ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {}),
      }
    }).then(res => {
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    })
      .catch(error => {
        toast.error("Erreur!");
      });
  }

  const onAdd = (e: any) => {
    if (addr) {
      const ad = address.join(";");

      let x = "";
      if (ad) {
        x = ad + ";" + addr
      } else {
        x = addr
      }
      axios.put("/api/v1/entreprises/contact", {
        others_address: x
      }, {
        headers: {
          ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {}),
        }
      }).then(res => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
        .catch(error => {
          toast.error("Erreur!");
        });
    }
  }

  return (
    <div className="container">
      <OverviewCard>
        <div className="row">
          <OverviewCardHeader className="col-md-12">
            <h4 className="page-title">Coordonnées</h4>
          </OverviewCardHeader>
          <div className="col-lg-6">
            <form className="row" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-12">
                <fieldset>
                  <TextField
                    label="Email"
                    name="owner_name"
                    type="text"
                    required
                    disabled
                    defaultValue={currentUser?.login_email}
                  />
                </fieldset>
              </div>

              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <fieldset>
                      <TextField
                        label="Téléphone"
                        name="phonenumber"
                        type="text"
                        inputRef={register}
                        error={!!errors.phonenumber}
                        helpText={errors.phonenumber?.message}
                        defaultValue={currentUser?.phonenumber}
                      />
                    </fieldset>
                  </div>
                </div>

              </div>
              <div className="col-md-12">
                <fieldset>
                  <TextField
                    label="Adresse siege"
                    name="address"
                    type="text"
                    required
                    inputRef={register}
                    error={!!errors.address}
                    helpText={errors.address?.message}
                    defaultValue={currentUser?.address}
                  />
                </fieldset>
              </div>

              <div className="col-md-12">
                <fieldset>
                  <button className="btn btn-primary">Mettre à jour</button>
                </fieldset>
              </div>
            </form>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <h5> Autre adresse <button className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                  <i className="fas fa-plus"></i>
                </button></h5>
              </div>
              <div className="col-md-12">
                <table className="table table-bordered table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Adresse</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      address.map((item: any, key: number) =>
                        <tr key={key}>
                          <td>
                            {item}
                          </td>
                          <td style={{ width: 10 }}>
                            <button onClick={(e) => removeAddress(key)} className="btn btn-default">
                              <i className="fas fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
      </OverviewCard>

      <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">Autre adresse</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="col-md-12">
              <fieldset>
                <TextField
                  label="Adresse"
                  onChange={(e) => setAddr(e.target.value)}
                  name="level"
                  required
                />
              </fieldset>
            </div>

            <div className="modal-footer">
              <button ref={cancelRef} type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
              <button onClick={onAdd} type="button" className="btn btn-primary">Ajouter</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
