import React from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import CourseInfo from "./CourseInfo";
import LevelInfo from "./LevelInfo";
import InterventionInfo from "./InterventionInfo";
import ProfileInfo from "./ProfileInfo";
import AccountInfo from "./AccountInfo";
import { TeacherCreationContext } from "context/TeacherCreation";
import SuccessInfo from "./SuccessInfo";
import Header from "ui/Header/Request";
import RequestForm from "../RequestForm";
import axios from "axios";


const Main = styled.div`
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`

export const TabWapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const TabHeader = styled.div`
  margin-bottom: 40px;
  color: #000;

  & h4 {
    font-weight: bold;
    margin-bottom: 0;
    color: var(--primary-text)
  }
`

export const TabFooter = styled.div`
  margin-top: auto;
  padding-top: 40px;
  display: flex;

  & .btn {
    padding: 0 20px;
    height: 48px;
    font-weight: bold;
    width: 125px;
  }

  & .btn.back {
    border: 1px solid #000;
  }

  & .btn.next {
    background-color: var(--primary-bg);
    color: #fff;
    margin-left: auto;
  }
`

export const Button = styled.button`

`

const AuthWrapper = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.13);
  background-color: #fff;
  /* width: 90%; */
  margin: auto;
  /* border: 1px solid #e0e0e0; */
  border-radius: 25px;
  position: relative;
  min-height: 542px;
  z-index: 2;

  /* :before {
    content: '';
    position: absolute;
    width: 5px;
    height: 48px;
    left: -3px;
    background: #FF4C28;
    border-radius: 5px;
  } */
`

const Nav = styled.div`
  padding: 32px;
`

const NavSteps = styled.ul`
  display: flex;
  margin-top: 72px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
`

const NavStep = styled.li`
  display: flex;
  border-bottom: 1px solid rgba(49,49,50,.1);
  height: 64px;
`

const TabsWapper = styled.div`
  padding: 24px;
`

const Title = styled.h1`
  font-size: 25px;
  color: #fff;
  font-weight: bold;
`

export const Overview = styled.div`
  padding: 50px 25px;
  background-color: #233266;
  min-height: 100vh;
  color: #fff;
  background-image: url("/images/teacher-signup-bg.jpg");
  background-size: cover;
  background-position: 50% 50%;
  z-index: 2;

  p {
    color: #fff;
  }

  :after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(35, 50, 102, 0.4);
    z-index: -1;
  } 
`

const Flow = styled.div`
  padding: 0;
`;

const StepList = styled.ul`
  list-style: none;
  padding-left: 20px;

  h4 {
    color: #fff;
    font-weight: bold;
  }

    .step {
      position: relative;
    padding: 4px 25px 27px 25px;
    }

    .step .step-tag.bordered {
          background: transparent;
    color: #fff;
    border: 2px solid #fff;
    }

     .step .step-tag {
           width: 35px;
    height: 35px;
    position: absolute;
    top: 0;
    left: -20px;
    color: #2169ec;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 35px;
    font-size: 14px;
     }
`;

interface TabProps {
  value: number;
  index: number;
  children?: React.ReactNode;

}

const Tab = ({ index, value, children }: TabProps) => {
  return (
    <>
      {
        value === index && (
          <>
            { children}
          </>
        )
      }
    </>
  )
}

const Request: React.FC<RouteComponentProps> = () => {
  const { tabValue } = React.useContext(TeacherCreationContext);

  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    axios('/api/v1/tutoring/page-text/5')
      .then(res => {
        setData(res.data)
      })
  }, []);

  return (
    <Main>
      <div className="row">
        <Flow className="col-md-8">
          <Header />
          {/* 
          <TabsWapper className="col-md-9 mx-auto">
            <Tab value={tabValue} index={0}>
              <CourseInfo />
            </Tab>
            <Tab value={tabValue} index={1}>
              <LevelInfo />
            </Tab>
            <Tab value={tabValue} index={2}>
              <InterventionInfo />
            </Tab>
            <Tab value={tabValue} index={3}>
              <ProfileInfo />
            </Tab>
            <Tab value={tabValue} index={4}>
              <SuccessInfo />
            </Tab>
          </TabsWapper> */}
          <RequestForm />
        </Flow>
        <Overview className="col-md-4">
          <div dangerouslySetInnerHTML={{ __html: data?.presentation }}></div>
        </Overview>
      </div>
    </Main>
  )
}

export default Request;