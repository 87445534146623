import clsx from "clsx";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 670px;
  align-items: center;

  &.selected {
    border: 3px #512581 solid;
  }

  .btn {
    width: 168px;
  }
`;

const Title = styled.h1`
  color: #512581;
`;

const Price = styled.h1`
  font-size: 1.5rem;
  color: #dd2421;
  .text-small {
    font-size: 17px;
  }
`;

interface BlocProps {
  title: string;
  price: { months: string; years: string };
  items: string[];
  selected?: boolean;
  by: string;
}
export default function Bloc({ by, title, price, items, selected }: BlocProps) {
  return (
    <div className="col-lg-4 mt-4">
      <Wrapper
        className={clsx("bg-white p-5 rounded-lg shadow", {
          selected: selected,
        })}
      >
        <div>
          <Title className="h6 text-uppercase font-weight-bold mb-4">
            {title}
          </Title>
          <Price className="h1 font-weight-bold">
            {by === "months" ? price.months : price.years}
          </Price>
          <div className="custom-separator my-4 mx-auto bg-primary"></div>

          <ul className="list-unstyled my-5 text-small text-left font-weight-normal">
            {items.map((item, key) => (
              <li key={key} className="mb-3">
                <i className="fa fa-check mr-2 text-primary"></i> {item}
              </li>
            ))}
          </ul>
        </div>
        <a
          href="/nous-contacter"
          className="btn btn-primary btn-block p-2 shadow rounded-pill"
        >
          Débuter
        </a>
      </Wrapper>
    </div>
  );
}
