import React from "react";
import { RouteComponentProps } from "@reach/router";
import Bloc from "./Bloc";
import Switch from "ui/Switch";

const prices = [

  {
    name: "ENSEIGNEMENT SECONDAIRE",
    items: [
      {
        title: "Basic",
        price: { months: "190 000 FCFA", years: "300 000 FCFA" },
        items: ["Référencement"]
      },
      {
        title: "STANDARD",
        price: { months: "230 000 FCFA", years: "385 000 FCFA" },
        items: ["Référencement", "Positionnement PRIME LIST"],
        selected: true
      },
      {
        title: "PREMIUM",
        price: { months: "290 000 FCFA", years: "450 000 FCFA" },
        items: ["Référencement", "Positionnement PRIME LIST", "Extraction des candidats"]
      },
    ]
  },

]



const Section = ({ price }: { price: any }) => {
  const [by, setBy] = React.useState(true);
  return (
    <div className="container py-5">
      <header className="text-center mb-5 text-white">
        <div className="row">

          <div className="col-lg-7 mx-auto">
            {/* <h1 style={{ color: "#0BA094" }}>{price.name}</h1> */}
            <p className="text-center">

              <Switch
                primary={{
                  label: "6 mois",
                  checked: by,
                }}
                secondary={{
                  label: "12 mois",
                  checked: !by,
                }}
                onChange={(check) => {
                  setBy(check)
                }
                }
              />
            </p>
          </div>
        </div>
      </header>
      <div className="row text-center align-items-end">
        {
          price.items.map((item: any) =>
            <Bloc by={by ? "months" : "years"} title={item.title} price={item.price} items={item.items} selected={item?.selected} />
          )
        }
      </div>
    </div>
  )
}

export default function ContactPage(props: RouteComponentProps) {
  return (
    <section>
      {
        prices.map((price, key) =>
          <Section price={price} />
        )
      }
    </section>
  )
}
