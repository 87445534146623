
import React from "react";
import MainLayout from "ui/Layout";
import { Router, RouteComponentProps } from "@reach/router";
import Overview from "./Overview";
import SchoolsSearch from "./SchoolsSearch";
import SchoolPage from "./School";
import NewsPage from "./News";

const StudiesPage: React.FC<RouteComponentProps> = () => {
  return (
    <MainLayout>
      <Router>
        <Overview path="/" />
        <SchoolPage path="/:name" />
        <NewsPage path="/ne-pas-manquer/:slug" />

        <SchoolsSearch path="/elementaire/recherche" type="elementary_primary" />
        <SchoolsSearch path="/secondaire/recherche" type="secondary" />
        <SchoolsSearch path="/superieur/recherche" type="high" />

        <SchoolsSearch path="/recherche" type="all" />
        <SchoolsSearch path="/elementaire" type="elementary_primary" />
        <SchoolsSearch path="/secondaire" type="secondary" />
        <SchoolsSearch path="/superieur" type="high" />
      </Router>
    </MainLayout>
  )
}

export default StudiesPage;