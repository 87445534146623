import React from "react";
import styled from "styled-components";
import moment from "moment";
import clsx from "clsx";
import { navigate } from "@reach/router";

const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 15px;
  box-shadow: 0 12px 60px rgb(0 0 0 / 20%);
  position: relative;
  z-index: 1;

  background-size: cover;


  :nth-child(1) {
    background-color: #BE1823;

  }

  :nth-child(2) {
    background-color: #512581;
    .btn {
      background-color: #BE1823;
      border-color: #BE1823;
    }
  }

  :nth-child(3) {
    background-color: #DD2421;
  }
  
  .ww::before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    content: "";
    background-color: rgba(35, 50, 102, 0.7);
    z-index: -1;
    border-radius: 20px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfosWrapper = styled.div`
  color: #777;

  /* display: grid; */
  /* grid-template-columns: 45% auto; */
  /* grid-template-rows: 290px; */

  .subtitle {
    text-transform: uppercase;
  }
`;

const Image = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  min-height: 240px;
`;

const Infos = styled.div`
  padding-left: 15px;
  color: #fff;
`;

const Title = styled.h4`
  color: #fff;
  font-weight: bold;
  font-size: 2.5em;
  margin: 0;
`;

const Date = styled.span`
  color: #777;
  font-size: 13px;
`;

interface JobItemProps {
  title: string;
  image: string;
  description: string;
  moreLink: string;
}
const JobItem: React.FC<JobItemProps> = ({ title, description, moreLink, image }) =>
  <Wrapper style={{ backgroundImage: `url(${image})` }}>
    <div className="ww"></div>
    <Title>{title}</Title>
    <br />
    {/* <ImageWrapper>
      {
        item?.entreprise?.profile_image ?
          <img src={item.entreprise.profile_image} className="img-responsive" />
          :
          <i className="fas fa-building" style={{ fontSize: "40px", color: "#512581" }}></i>
      }
    </ImageWrapper> */}
    <InfosWrapper>
      <div className="row">
        {/* <div className="col-md-6">
          <Image style={{ backgroundImage: `url(${image})` }} />
        </div> */}
        <div className="col-md-12">
          <Infos>
            {description}
            <br />
            <br />
            <a style={{
            }} href={moreLink} className="btn btn-primary">En savoir plus</a>
          </Infos>
        </div>
      </div>
    </InfosWrapper>
  </Wrapper>

export default JobItem;