
import React from "react";
import styled from "styled-components";
import moment from "moment";

const Wrapper = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 2px;
  border-radius: 5px;
  word-break: break-word;
  border: 1px solid #eeeeee;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 220px;
  margin-bottom: 5px;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

  /* ::after {
    content: "";
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }


  :hover {
    -webkit-transform: scale(1.25, 1.25);
    transform: scale(1.25, 1.25);
  }
  */
  :hover {
    box-shadow: 0 8px 10px 5px rgb(0 0 0 / 10%);
  }
`

const Desc = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const CoverWrapper = styled.div`
  overflow: hidden;
  border-radius: 5px;
  position: relative;

  a {
    display: block;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
    height: 140px;
  }

  a:hover {
    transform: scale(1.2);
  }

  a::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    background: #000;
    opacity: 0.1;
    z-index: 1;
  }

  a:hover::after {
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s;
  }
`

const Title = styled.h4`
  color: #000;
  font-size: 1.1875rem;
  margin: 0;
  padding-bottom: 10px;

  :hover {
    color: var(--secondary-color);
  }
`;

const Date = styled.span`
  color: #888;
  text-transform: capitalize;
  font-weight: 300;
  font-size: 14px;
`;

interface ArticleItemProps {
  article: any;
  path?: string;
}

export default function ArticleItem({ article, path }: ArticleItemProps) {

  return (
    <Wrapper className="fh5co-bldog">
      <CoverWrapper>
        <a
          href={`${path}/${article.slug}`}
          style={{ backgroundImage: `url(${article.cover_image || '/images/no-image.png'})` }}>
        </a>
      </CoverWrapper>

      <Desc>
        <div>
          <a href={`${path}/${article.slug}`}>
            <Title>
              {article.name.length <= 60 ? article.name : article.name.substr(0, 60) + " ..."}
            </Title>
          </a>
          <p>
            {article.description.length <= 100 ? article.description : article.description.substr(0, 100) + " ..."}
          </p>
        </div>
        <Date>{moment(article.createdAt).format('MMMM YYYY')}</Date>
      </Desc>
    </Wrapper>
  )
}
