import React from "react";
import { navigate } from "@reach/router";
import styled from "styled-components";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
// Import styles
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import FileButton from "ui/FileButton";
import axios from "axios";
import { useAuthToken } from "hooks/useAuthToken";
import useCurrentParticular from "hooks/useCurrentParticular";
import { OverviewCard, OverviewCardHeader } from "pages/style";

const Applications = ({}) => {
  const [resume, setResume] = React.useState(null);
  const [authToken] = useAuthToken();
  const [currentUser] = useCurrentParticular();

  function onDrop(picture: any) {
    setResume(picture);
  }

  function sendResume() {
    const formData = new FormData();
    formData.append("resume", resume);
    axios
      .put("/api/v1/candidats/upload-resume", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          ...(Boolean(authToken)
            ? { Authorization: `Bearer ${authToken}` }
            : {}),
        },
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {});
  }
  return (
    <div className="container">
      <div className="row">
        <OverviewCard className="col-lg-12">
          <OverviewCardHeader>
            <h4 className="page-title">Mon CV</h4>
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              Uploader
            </button>
          </OverviewCardHeader>

          {currentUser?.resume && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div style={{ height: "750px" }}>
                <Viewer
                  fileUrl={currentUser?.resume?.replace("http://", "https://")}
                />
              </div>
            </Worker>
          )}
        </OverviewCard>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Uploader mon cv
              </h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <FileButton
              onChange={onDrop}
              id="d"
              name="s"
              acceptedTypes={[".pdf"]}
            />

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Annuler
              </button>
              <button
                onClick={sendResume}
                type="button"
                className="btn btn-primary"
              >
                Envoyez
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applications;
