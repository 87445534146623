import React from "react";
import { RouteComponentProps } from "@reach/router";
import axios from "axios";

export default function AboutPage(props: RouteComponentProps) {
  const [data, setData] = React.useState(null);
  const [about, setAbout] = React.useState(null);

  React.useEffect(() => {
    axios.get('/api/v1/settings/informations/')
      .then(res => {
        if (res.status === 200 && res.data.length > 0)
          setData(res.data[0])
      });
    axios('/api/v1/tutoring/page-text/15')
      .then(res => {
        setAbout(res.data)
      })
  }, []);

  return (
    <div id="fh5co-about">
      <div className="container">
        <div className="row">
          <div className="col-md-6 animate-box">
            <span className="search-filter-title">À propos</span>
            <br />
            <br />
            <h2>{about?.name}</h2>
            <div>
              <div dangerouslySetInnerHTML={{ __html: about?.presentation }}></div>
            </div>
          </div>
          <div className="col-md-6">
            <img className="img-responsive" src={about?.cover_image} alt="Image" />
          </div>
        </div>
      </div>
    </div>
  )
}
