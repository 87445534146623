import clsx from "clsx";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #f1f1f1;

  .img-responsive {
    width: 100%;
  }

  .ads-item {
    min-height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .col-md-12 {
    margin-bottom: 5px;
  }
`
const Ul = styled.div`

`;

const Li = styled.div`
  min-height: 250px;
  border-radius: 3px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 15px;
  
`

interface CoversProps {
  page: string;
  sticky?: boolean;
}

const Ads: React.FC<CoversProps> = ({ page, sticky }) => {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    fetch('/api/v1/page-ads/public?page=' + page)
      .then(res => res.json())
      .then((data) => {
        setData(data)
      })
      .catch(console.log);
  }, []);

  return (
    <>
      {data.length > 0 ?
        <Wrapper className={clsx({ "sticky-top": sticky })}>
          <div className="row">
            {
              data.map((item, key) =>
                <div className="col-md-12">
                  <a href="">
                    <img src={item.cover_image} alt="" className="img-fluid" width="100%" />
                  </a>
                </div>
              )
            }
          </div>
        </Wrapper>
        :
        ""
      }
    </>
  )
}


export default Ads;