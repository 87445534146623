
import React from "react";
import styled from "styled-components";
import { CoverWrapper } from "pages/ArticleItem";

const Wrapper = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 2px;
  border-radius: 5px;
  word-break: break-word;
  border: 1px solid #eeeeee;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 120px;
  margin-bottom: 15px;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

  :hover {
    box-shadow: 0 8px 10px 5px rgb(0 0 0 / 10%);
  }
`

const Desc = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  
  p {
   margin: 0;
   font-size: 16px;
  }
`

const Title = styled.h4`
  color: #512581;
  font-weight: bold;
  font-size: 1.1875rem;
  margin: 0;
  padding: 5px 0;
`;

interface ArticleItemProps {
  article: any;
}

export default function ArticleItem({ article }: ArticleItemProps) {

  return (
    <Wrapper className="fh5co-blog">
      <CoverWrapper>
        <a href={`/fiches-metiers/__/${article.slug}`} className="blog-img-holder" style={{ backgroundImage: `url(${article.cover_image || '/images/project-1.jpg'})` }}></a>
      </CoverWrapper>

      <Desc>
        <a href={`__/${article.slug}`}><Title>{article.name}</Title></a>
        <p>
          {article.description.length <= 60 ? article.description : article.description.substr(0, 60) + "..."}
        </p>
      </Desc>
    </Wrapper >
  )
}
