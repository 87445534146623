import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import FamilyLayout from "ui/Layout/Family";
import PrivateRoute from "./PrivateRoute";
import Interventions from "./Interventions";
import Childrens from "./Childrens";
import NewChildren from "./NewChildren";
import Setting from "./Setting";
// import ContactInfos from "./ContactInfos";
// import Resume from "./Resume";
import Profile from "./Profile";
import Followup from "./Followup";

const Particulier: React.FC<RouteComponentProps> = () => {
  return (
    <FamilyLayout>
      <Router>
        <PrivateRoute path="/interventions" component={Interventions} default />
        <PrivateRoute path="/interventions/:code" component={Followup} />
        <PrivateRoute path="/enfants/edit" component={NewChildren} />
        <PrivateRoute path="/enfants/" component={Childrens} />
        <PrivateRoute path="/parmaretres" component={Setting} />
        {/* <PrivateRoute path="/contacts" component={ContactInfos} /> */}
        {/* <PrivateRoute path="/cv" component={Resume} /> */}
        <PrivateRoute path="/profil" component={Profile} />
      </Router>
    </FamilyLayout>
  )
}
export default Particulier;