import React from "react";
import styled from "styled-components";
const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  color: #fff;
  background-color: var(--tertiary-bg);
  border-radius: 5px;

  a {
    color: #fff;
    font-weight: bold;
    position: relative;
  }

  a:hover {
    color: var(--tertiary-color);
    font-weight: bold;
  }

  .current::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 3px;
    left: 1px;
    background-color:  var(--tertiary-color);
    bottom: -5px;
  }

`



const Line: React.FC = () =>
  <div className="container">
    <br />
    <Wrapper id="bottom-elm">
      <div className="row">
        <div className="col-md-4 text-center">
          <a href="/etudes/elementaire/" className={window.location.pathname.includes('/elementaire/') ? 'current' : ''}>
            Élémentaire
          </a>
        </div>
        <div className="col-md-4 text-center">
          <a href="/etudes/secondaire/"
            className={window.location.pathname.includes('/secondaire/') ? 'current' : ''}>
            Secondaire
          </a>
        </div>
        <div className="col-md-4 text-center">
          <a href="/etudes/superieur/"
            className={window.location.pathname.includes('/superieur/') ? 'current' : ''}>
            Supérieure
          </a>
        </div>
      </div>
    </Wrapper>
  </div>

export default Line;