import React from "react";

export interface ICandidatCreation {
  tabValue: number;
  info: any;
  profile: any;
  resume: any;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  setInfo: React.Dispatch<React.SetStateAction<any>>;
  setProfile: React.Dispatch<React.SetStateAction<any>>;
  setResume: React.Dispatch<React.SetStateAction<any>>;
}
export const CandidatCreationContext = React.createContext<ICandidatCreation>(null);

const CandidatCreationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tabValue, setTabValue] = React.useState<number>(0);
  const [info, setInfo] = React.useState<any>(null);
  const [profile, setProfile] = React.useState<Array<any>>([]);
  const [resume, setResume] = React.useState<any>(null);
  return (
    <CandidatCreationContext.Provider
      value={{
        profile,
        tabValue,
        info,
        resume,
        setInfo,
        setTabValue,
        setProfile,
        setResume
      }}
    >
      {children}
    </CandidatCreationContext.Provider>
  );
};

export default CandidatCreationProvider;
