import React from "react";
import Autosuggest from 'react-autosuggest';
import Select from 'react-select';

export type SearchType = "all" | "elementary_primary" | "secondary" | "high";

interface SearchBarProps {
  type: SearchType;
  educationTypes?: any[];
  sectors?: any[];
  educationLevels?: any[];
  cities?: any[];

}

const COL_BY_TYPE = {
  all: {
    name: "col-md-5",
    city: "col-md-5"
  },
  elementary_primary: {
    name: "col-md-4",
    city: "col-md-3",
    status: "col-md-3"

  },
  secondary: {
    name: "col-md-3",
    city: "col-md-2",
    status: "col-md-2",
    education: "col-md-3"

  },
  high: {
    name: "col-md-2",
    city: "col-md-2",
    education: "col-md-2"

  },
}

export const SEARCH_PATH_BY_TYPE = {
  all: "/etudes/recherche/",
  elementary_primary: "/etudes/elementaire/recherche/",
  secondary: "/etudes/secondaire/recherche/",
  high: "/etudes/superieur/recherche/",
}

type ParmasType = {
  key: string;
  value: string;
};

const SearchBar: React.FC<SearchBarProps> = ({ type, educationTypes, sectors, educationLevels, cities }) => {
  const [cityQuery, setCityQuery] = React.useState("");
  const [nameQuery, setNameQuery] = React.useState("");
  const [filterStatus, setFilterStatus] = React.useState("");
  const [filterEducation, setFilterEducation] = React.useState("");

  const [filterSecteur, setFilterSecteur] = React.useState("");
  const [filterLevel, setFilterLevel] = React.useState(null);

  const [suggestedCities, setSuggestedCities] = React.useState([]);

  const [dataEducationTypes, setEducationTypes] = React.useState([]);


  React.useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    const city = params.get('lieu');
    const name = params.get('ecole');
    const status = params.get('filtre-statut');
    const education = params.get('filtre-enseignement');
    const level = params.get('filtre-niveau');
    const sector = params.get('filtre-filiere');


    if (city) {
      setCityQuery(city);
    }

    if (name) {
      setNameQuery(name);
    }

    if (status) {
      setFilterStatus(status);
    }

    if (education) {
      setFilterEducation(education);
    }

    if (level) {
      setFilterLevel(level);
    }

    if (sector) {
      setFilterSecteur(sector);
    }

  }, []);


  React.useEffect(() => {
    if (educationTypes && educationTypes.length > 0) {
      if (type === "high" || type === "secondary") {
        setEducationTypes(educationTypes.filter(item => item.level === type))
      }
    }
  }, [educationTypes])


  const params: Record<string, ParmasType> = {
    ...(nameQuery && {
      nameQuery: {
        key: "ecole",
        value: nameQuery
      }
    }),
    ...(cityQuery && {
      cityQuery: {
        key: "lieu",
        value: cityQuery
      }
    }),
    ...(filterStatus && {
      filterStatus: {
        key: "filtre-statut",
        value: filterStatus
      }
    }),
    ...(filterEducation && {
      filterEducation: {
        key: "filtre-enseignement",
        value: filterEducation
      }
    }),
    ...(filterSecteur && {
      filterSecteur: {
        key: "filtre-filiere",
        value: filterSecteur
      }
    }),
    ...(filterLevel && {
      filterLevel: {
        key: "filtre-niveau",
        value: filterLevel
      }
    }),
  };

  const buildParmas = (params: Record<string, ParmasType>): string => {
    let param = Object.values(params).reduce((str, current) =>
      `${str}&${current.key}=${encodeURIComponent(current.value)}`
      , "");

    if (param) {
      param = param.replace("&", "?");
    }

    return param;
  };

  const onSearch = () => {
    window.location.href = `${SEARCH_PATH_BY_TYPE[type]}${buildParmas(params)}`;
  }


  const onSuggestionsFetchRequested = ({ value }: any) => {
    setSuggestedCities(getSuggestions(value))
  };

  const onSuggestionsClearRequested = () => {
    setSuggestedCities([])
  };

  const getSuggestions = (value: any) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : cities.filter(lang =>
      lang.name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };

  const getSuggestionValue = (suggestion: any) => suggestion.name;

  const renderSuggestion = (suggestion: any) => (
    <div>
      {suggestion.name}
    </div>
  );

  const onChange = (event: any, { newValue }: any) => {
    setCityQuery(newValue);
  };


  return (
    <div className="search-container">
      <div className="row">
        <div className={COL_BY_TYPE[type].name}>
          <input onChange={e => setNameQuery(e.target.value)} value={nameQuery} placeholder="Nom de l'ecole" className="form-control" type="text" />
        </div>

        {
          (type === "elementary_primary" || type === "secondary") &&
          <>
            <div className={COL_BY_TYPE[type].status}>
              <select name="" id="" className="form-control" value={filterStatus} onChange={e => setFilterStatus(e.target.value)}>
                <option value="">Statut</option>
                <option value="public">Public</option>
                <option value="private">Privé</option>
              </select>
            </div>
          </>
        }


        {
          (type === "secondary" || type === "high") &&
          <>
            <div className={COL_BY_TYPE[type].education}>
              <Select
                isClearable
                options={dataEducationTypes?.map((item: any) => ({ value: item.name, label: item.name }))}
                placeholder="Type d'enseignement"
                value={filterEducation ? { value: filterEducation, label: filterEducation } : null}
                onChange={(value, action) => {
                  setFilterEducation(value?.value || "")
                }}
              />
            </div>
          </>
        }


        {
          type === "high" &&
          <>
            <div className="col-md-2">
              <Select
                isClearable
                options={sectors?.map((item: any) => ({ value: item.name, label: item.name }))}
                placeholder="Filières"
                value={filterSecteur ? { value: filterSecteur, label: filterSecteur } : null}
                onChange={(value, action) => {
                  setFilterSecteur(value?.value || "")
                }}
              />
            </div>
            <div className="col-md-2">
              <Select
                isClearable
                options={educationLevels?.map((item: any) => ({ value: item.name, label: item.name }))}
                placeholder="Niveau"
                value={filterLevel ? { value: filterLevel, label: filterLevel } : null}
                onChange={(value, action) => {
                  setFilterLevel(value?.value || "")
                }}
              />
            </div>
          </>
        }

        <div className={COL_BY_TYPE[type].city}>
          <Autosuggest
            suggestions={suggestedCities}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{
              placeholder: "Où ?",
              value: cityQuery,
              onChange: onChange
            }}
            highlightFirstSuggestion
          />
        </div>

        <div className="col-md-2">
          <button onClick={onSearch} className="search-button btn-rounded">Rechercher</button>
        </div>
      </div>
    </div>
  )
}

export default SearchBar;