import { CandidatCreationContext } from "context/CandidatCreation";
import LabelRadio from "pages/Tutoring/LabelRadio";
import React from "react";
import TextField from "ui/TextField";
import { Button, TabFooter, TabHeader, TabWapper } from "pages/Auth/style";
import Select from 'react-select';
import axios from "axios";
import SelectField from "ui/SelectField";
import clsx from "clsx";

const defaultValues = {
  availability: "",
  search_contract_type: "",
  professional_situation: "",
  current_job_level: "",
  study_level: "",
  sought_sectors: null as any
}

const CourseTab = () => {

  const { tabValue, setTabValue, profile, setProfile } = React.useContext(CandidatCreationContext);
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [submitted, setSubmitted] = React.useState(false);
  const [setting, setSetting] = React.useState(null);


  React.useEffect(() => {
    if (profile) {
      setFormValues(profile);
    }

    axios.get("/api/v1/settings/stage-et-emploi")
      .then(res => {
        setSetting(res.data)
      });
  }, []);

  const back = () => {
    setTabValue(tabValue - 1);
  }

  const next = () => {
    setSubmitted(true);

    if (
      !Boolean(formValues.availability) || !Boolean(formValues.search_contract_type)
      || !Boolean(formValues.professional_situation) || !Boolean(formValues.current_job_level) || !Boolean(formValues.study_level || !Boolean(formValues.sought_sectors?.length))
    )
      return;

    setProfile(formValues)
    setTabValue(tabValue + 1);
  }

  const handleInputChange = React.useCallback(
    (event) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  return (
    <TabWapper>
      <TabHeader>
        <h4>Profil</h4>
      </TabHeader>
      <div className="row">
        <div className="col-md-12">

          <SelectField
            label="Disponibilité"
            name="availability"
            value={formValues.availability}
            onChange={handleInputChange}
            required
            error={submitted && !Boolean(formValues.availability)}
            helpText={submitted && !Boolean(formValues.availability) && "Veuillez sélectionner votre disponibilité"}
          >
            <option value=""></option>
            <option value="Immédiate">Immédiate</option>
            <option value="dans 01 mois">dans 01 mois</option>
            <option value="dans 03 mois">dans 03 mois</option>
          </SelectField>
        </div>

        <div className="col-md-12">
          <SelectField
            label="Type de contrat recherché"
            name="search_contract_type"
            value={formValues.search_contract_type}
            onChange={handleInputChange}
            required
            error={submitted && !Boolean(formValues.search_contract_type)}
            helpText={submitted && !Boolean(formValues.search_contract_type) && "Veuillez sélectionner le type de contrat recherché"}
          >
            <option value=""></option>
            {
              setting?.contractType.map((c: any, key: any) =>
                <option key={key} value={c.name}>{c.name}</option>
              )
            }
          </SelectField>
        </div>

        <div className="col-md-12">
          <SelectField
            label="Situation professionnelle"
            name="professional_situation"
            value={formValues.professional_situation}
            onChange={handleInputChange}
            required
            error={submitted && !Boolean(formValues.professional_situation)}
            helpText={submitted && !Boolean(formValues.professional_situation) && "Veuillez sélectionner votre situation professionnelle"}
          >
            <option value=""></option>
            {
              setting?.professionalSituation.map((c: any, key: any) =>
                <option key={key} value={c.name}>{c.name}</option>
              )
            }
          </SelectField>
        </div>

        <div className="col-md-12">
          <SelectField
            label="Experience professionnelle"
            name="current_job_level"
            value={formValues.current_job_level}
            onChange={handleInputChange}
            required
            error={submitted && !Boolean(formValues.current_job_level)}
            helpText={submitted && !Boolean(formValues.current_job_level) && "Veuillez sélectionner votre experience professionnelle"}
          >
            <option value=""></option>
            {
              setting?.professionalSituation.map((c: any, key: any) =>
                <option key={key} value={c.name}>{c.name}</option>
              )
            }
          </SelectField>
        </div>


        <div className="col-md-12">
          <SelectField
            label="Niveau d'études"
            name="study_level"
            value={formValues.study_level}
            onChange={handleInputChange}
            required
            error={submitted && !Boolean(formValues.study_level)}
            helpText={submitted && !Boolean(formValues.study_level) && "Veuillez sélectionner votre niveau d'études"}
          >
            <option value=""></option>
            {
              setting?.schoolLevel.map((c: any, key: any) =>
                <option key={key} value={c.name}>{c.name}</option>
              )
            }
          </SelectField>
        </div>

        <div className="col-md-12">
          <label >Métiers recherché</label>
          <Select
            options={setting?.requiredTraining?.map((item: any) => ({ value: item.name, label: item.name }))}
            isMulti
            className={clsx("form-contrsol", {
              "is-invalid": submitted && !Boolean(formValues.sought_sectors?.length)
            })}
            name="sought_sectors"
            value={formValues.sought_sectors}
            onChange={(value, action) => {
              setFormValues({
                ...formValues,
                sought_sectors: value
              })
            }}
          />
          <div className="invalid-feedback">
            Veuillez choisir vos métiers recherché
          </div>
        </div>

      </div>
      <TabFooter>
        <Button
          onClick={back}
          className="btn back"
        >
          Précédent
        </Button>
        <Button
          onClick={next}
          className="btn next"
        >
          Suivant
        </Button>
      </TabFooter>
    </TabWapper>
  )
}

export default CourseTab;