import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import TinyHeader from "ui/Header/TinyHeader";
import axios from "axios";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from "ui/TextField";
import TinyFooter from "ui/Footer/TinyFooter";

const AuthWrapper = styled.div`
  background-color: #fff;
  padding: 30px 50px;
  width: 90%;
  margin: auto;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  position: relative;

  :before {
    content: '';
    position: absolute;
    width: 5px;
    height: 48px;
    left: -3px;
    background: #FF4C28;
    border-radius: 5px;
  }
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 2.5rem;
  color: #233266;
`

type InputField = {
  name: string;
  phonenumber: string;
  login_email: string;
  password: string;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  phonenumber: yup.string().required(),
  login_email: yup.string().required(),
  password: yup.string().required()
});

const Auth: React.FC<RouteComponentProps> = () => {
  const { register, handleSubmit, reset, errors } = useForm({ resolver: yupResolver(schema) });
  const [isError, setIsError] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);


  const onSubmit = (data: any) => {
    axios.post("/api/v1/entreprises/signup", {
      ...data
    }).then(res => {
      reset();
      setIsSuccess(true);
    })
      .catch(error => {
        if (error.response && error.response.status === 409)
          setIsError(true);
      })
  }


  return (
    <div id="page" style={{ backgroundColor: "#F6F5FA" }}>
      <TinyHeader />
      <aside id="fh5co-course">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <AuthWrapper>
                <form onSubmit={handleSubmit(onSubmit)}>

                  <div>
                    <Title>
                      Inscrivez-vous
                    </Title>
                  </div>
                  {
                    isError &&
                    <div className="alert alert-danger" role="alert">
                      L`adresse email existe déjà.
                    </div>
                  }
                  {
                    isSuccess &&
                    <div className="alert alert-success" role="alert">
                      Votre compte a été créé avec succès!
                      Un mail vous a été envoyé pour activer votre compte
                    </div>
                  }
                  <div className="form-group">
                    <TextField
                      label="Nom de l'entreprise"
                      name="name"
                      type="text"
                      required
                      inputRef={register}
                      error={!!errors.name}
                      helpText={errors.name?.message}
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      label="Tél. professionnel"
                      name="phonenumber"
                      type="text"
                      required
                      inputRef={register}
                      error={!!errors.phonenumber}
                      helpText={errors.phonenumber?.message}
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      label="Adresse email"
                      name="login_email"
                      type="email"
                      required
                      inputRef={register}
                      error={!!errors.login_email}
                      helpText={errors.login_email?.message}
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      label="Mot de passe"
                      name="password"
                      type="password"
                      required
                      placeholder="Mot de passse"
                      inputRef={register}
                      error={!!errors.password}
                      helpText={errors.password?.message}
                    />
                  </div>

                  <div className="form-group text-right">
                    <button
                      type="submit" className="btn btn-primary">
                      S'inscrire
								    </button>
                  </div>

                  <div className="form-group text-center">
                    Vous avez déjà un compte ? <a href="/auth/~e/signin">Se connecter</a>
                  </div>
                </form>

              </AuthWrapper>
            </div>
          </div>
        </div>
      </aside>
      <TinyFooter />
    </div>
  )
}

export default Auth;