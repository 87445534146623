import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import TextField from "ui/TextField";
import axios from "axios";
import LabelRadio from "./LabelRadio";

import styled from "styled-components";
import SelectField from "ui/SelectField";
import ConfirmCheckbox from "ui/ConfirmCheckbox";
import { toast } from "react-toastify";
import Select from "react-select";
import Checkbox from "ui/Checkbox";
import clsx from "clsx";

const BlockTitle = styled.h1`
  color: var(--primary-color);
  font-weight: bold;
  font-size: 1.5rem;
`;

const defaultValues = {
  city: null as { value: string; label: string },
  district: "",
  intervention_period: "",
  intervention_frequency: "",

  civility: "",
  requester_firstname: "",
  requester_lastname: "",
  requester_email: "",
  requester_phonenumber: "",

  condition: false,
  interventionTypes: ["onsite"],
};

const frequences = [
  "1 / semaine",
  "2 / semaine",
  "3 / semaine",
  "4 / semaine",
  "5 / semaine",
  "6 / semaine",
  "7 / semaine",
];
const TutoringPage: React.FC<RouteComponentProps> = () => {
  const [levels, setLevels] = React.useState<any[]>([]);
  const [courses, setCourses] = React.useState<any[]>([]);
  const [classes, setClasses] = React.useState<any[]>([]);
  const [intrvs, setIntervs] = React.useState<any[]>([]);

  const [level, setLevel] = React.useState("");
  const [course, setCourse] = React.useState("");
  const [classe, setClasse] = React.useState("");

  const [intrvSubmitted, setIntrvSubmitted] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [cities, setCities] = React.useState([]);

  const handleInputChange = React.useCallback(
    (event) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  React.useEffect(() => {
    axios("/api/v1/tutoring/levels/for-request").then((res) => {
      setLevels(res.data);
    });

    axios.get("/api/v1/studies/cities").then((res) => {
      setCities(res.data);
    });
  }, []);

  const levelChange = (e: any) => {
    setLevel(e.target.value);
    const _c = levels.find((level) => level.id == e.target.value);
    if (_c) {
      setClasses(_c.classes);
      setCourses(_c.courses);
    }
  };

  const courseChange = (e: any) => {
    setCourse(e.target.value);
  };

  const classeChange = (e: any) => {
    setClasse(e.target.value);
  };

  const addInt = () => {
    setIntrvSubmitted(true);
    if (!Boolean(level) || !Boolean(course) || !Boolean(classe)) return;

    const _l = levels.find((_level) => _level.id == level);
    const _cl = classes.find((_classe) => _classe.id == classe);
    const _co = courses.find((_course) => _course.id == course);

    setIntervs([
      ...intrvs,
      {
        level: _l.name,
        course: _co.name,
        classe: _cl.name,
      },
    ]);

    setIntrvSubmitted(false);
    setLevel("");
    setCourse("");
    setClasse("");
  };

  const removeInterv = (key: number) => {
    setIntervs(intrvs.filter((i, index) => index != key));
  };

  const send = () => {
    setSubmitted(true);

    if (intrvs.length === 0) {
      toast.error("Veuillez entrer le niveau d'intervention souhaité");
    }

    if (
      intrvs.length === 0 ||
      !Boolean(formValues.city) ||
      !Boolean(formValues.district) ||
      !Boolean(formValues.intervention_period) ||
      !Boolean(formValues.intervention_frequency) ||
      !Boolean(formValues.civility) ||
      !Boolean(formValues.requester_firstname) ||
      !Boolean(formValues.requester_lastname) ||
      !Boolean(formValues.requester_email) ||
      !Boolean(formValues.requester_phonenumber)
    )
      return;

    axios
      .post("/api/v1/tutoring/request", {
        ...formValues,
        city: formValues.city?.value,
        intrvs: intrvs,
      })
      .then((res) => {
        setSubmitted(false);
        setFormValues(defaultValues);
        toast.success("Votre demande a bien été envoyée");
        setTimeout(() => {
          navigate("/soutien-scolaire");
        }, 1500);
      })
      .catch((error) => {});
  };

  const handleIntervention = (e: any) => {
    const types = e.target.value;
    if (formValues.interventionTypes?.includes(types)) {
      setFormValues((prev) => ({
        ...prev,
        interventionTypes: prev.interventionTypes?.filter(
          (value) => value !== types
        ),
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        interventionTypes: [...prev.interventionTypes, types],
      }));
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            {/* <h4 className="">SOYEZ CONTACTÉ PAR UN CONSEILLER PÉDAGOGIQUE</h4> */}
          </div>
          {/* <br /> */}
          <div
            className="col-md-12 request-block"
            style={{ padding: "3rem 8rem" }}
          >
            <h4 className="text-center">
              SOYEZ CONTACTÉ PAR UN CONSEILLER PÉDAGOGIQUE
            </h4>
            <br />

            <form action="#" className="row">
              <div className="col-md-12">
                <BlockTitle>Votre demande</BlockTitle>
              </div>

              <div className="col-md-11">
                <div className="row">
                  <div className="col-md-4">
                    <fieldset>
                      <SelectField
                        value={level}
                        label="Niveau"
                        onChange={levelChange}
                        name="level"
                        required
                        error={intrvSubmitted && !Boolean(level)}
                        helpText={
                          intrvSubmitted &&
                          !Boolean(level) &&
                          "Veuillez selectionner le niveau"
                        }
                      >
                        <option></option>
                        {levels.map((c, key) => (
                          <option key={key} value={c.id}>
                            {c.name}
                          </option>
                        ))}
                      </SelectField>
                    </fieldset>
                  </div>

                  <div className="col-md-4">
                    <fieldset>
                      <SelectField
                        value={course}
                        onChange={courseChange}
                        label="Matiere"
                        required
                        name="domain"
                        error={intrvSubmitted && !Boolean(course)}
                        helpText={
                          intrvSubmitted &&
                          !Boolean(course) &&
                          "Veuillez selectionner la matiere"
                        }
                      >
                        <option></option>
                        {courses.map((c, key) => (
                          <option key={key} value={c.id}>
                            {c.name}
                          </option>
                        ))}
                      </SelectField>
                    </fieldset>
                  </div>

                  <div className="col-md-4">
                    <fieldset>
                      <SelectField
                        value={classe}
                        onChange={classeChange}
                        label="Classe"
                        name="class"
                        required
                        error={intrvSubmitted && !Boolean(classe)}
                        helpText={
                          intrvSubmitted &&
                          !Boolean(classe) &&
                          "Veuillez selectionner la classe"
                        }
                      >
                        <option></option>
                        {classes.map((c, key) => (
                          <option key={key} value={c.id}>
                            {c.name}
                          </option>
                        ))}
                      </SelectField>
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="col-md-1" style={{}}>
                <br />
                <button
                  onClick={addInt}
                  type="button"
                  className="btn btn-primary"
                  style={{
                    width: "52px",
                    height: "52px",
                    padding: "0",
                    marginTop: "8px",
                  }}
                >
                  {" "}
                  <span className="fa fa-plus"></span>
                </button>
              </div>

              {intrvs.length > 0 && (
                <div className="col-md-12" style={{}}>
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Niveau</th>
                        <th scope="col">Matiere</th>
                        <th scope="col">Classe</th>
                      </tr>
                    </thead>
                    <tbody>
                      {intrvs.map((intrv, key) => (
                        <tr key={key}>
                          <th scope="row">{key + 1}</th>
                          <td>{intrv.level}</td>
                          <td>{intrv.course}</td>
                          <td>{intrv.classe}</td>
                          <td>
                            <button
                              onClick={() => removeInterv(key)}
                              type="button"
                              style={{ border: 0 }}
                              className="btn btn-outline-danger"
                            >
                              <i className="far fa-times-circle"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              <div className="col-md-12">
                <BlockTitle>Lieu d'intervention</BlockTitle>
              </div>

              <div className="col-md-6">
                <fieldset>
                  <label>Ville</label>
                  <Select
                    options={cities?.map((item: any) => ({
                      value: item.name,
                      label: item.name,
                    }))}
                    name="city"
                    placeholder=""
                    value={formValues.city}
                    onChange={(value, action) => {
                      setFormValues({
                        ...formValues,
                        city: value,
                      });
                    }}
                  />
                </fieldset>
              </div>

              <div className="col-md-6">
                <fieldset>
                  <TextField
                    value={formValues.district}
                    onChange={handleInputChange}
                    label="Quartier *"
                    name="district"
                    type="text"
                    required
                    error={submitted && !Boolean(formValues.district)}
                    helpText={
                      submitted &&
                      !Boolean(formValues.district) &&
                      "Veuillez saisir le quartier"
                    }
                  />
                </fieldset>
              </div>

              <div className="col-md-6">
                <fieldset>
                  <TextField
                    value={formValues.intervention_period}
                    onChange={handleInputChange}
                    label="Periode d'intervention *"
                    name="intervention_period"
                    type="date"
                    required
                    error={
                      submitted && !Boolean(formValues.intervention_period)
                    }
                    helpText={
                      submitted &&
                      !Boolean(formValues.intervention_period) &&
                      "Veuillez selectionner la periode"
                    }
                  />
                </fieldset>
              </div>

              <div className="col-md-6">
                <fieldset>
                  <SelectField
                    onChange={handleInputChange}
                    label="Frequence d'intervention *"
                    required
                    name="intervention_frequency"
                    error={
                      submitted && !Boolean(formValues.intervention_frequency)
                    }
                    helpText={
                      submitted &&
                      !Boolean(formValues.intervention_frequency) &&
                      "Veuillez selectionner la frequence"
                    }
                  >
                    <option></option>
                    {frequences.map((c, key) => (
                      <option key={key} value={c}>
                        {c}
                      </option>
                    ))}
                  </SelectField>
                </fieldset>
              </div>

              <div className="col-md-12">
                <BlockTitle>Types d'intervention souhaités *</BlockTitle>
              </div>
              <div className="col-md-12">
                <div
                  className={clsx("row", {
                    "is-invalid":
                      submitted && !formValues.interventionTypes?.length,
                  })}
                >
                  <div className="col-md-2">
                    <Checkbox
                      onChange={handleIntervention}
                      name="interventionTypes"
                      value="onsite"
                      label="À domicile"
                      checked={formValues.interventionTypes?.includes("onsite")}
                    />
                  </div>
                  <div className="col-md-2">
                    <Checkbox
                      onChange={handleIntervention}
                      name="interventionTypes"
                      value="online"
                      label="En ligne"
                      checked={formValues.interventionTypes?.includes("online")}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <BlockTitle>Vos informations</BlockTitle>
              </div>

              <div className="col-md-12">
                <label htmlFor="">VOTRE CIVILITÉ *</label>
                <br />
                <LabelRadio
                  onChange={handleInputChange}
                  id="Madame"
                  label="Madame"
                  name="civility"
                  value="Madame"
                />
                <LabelRadio
                  onChange={handleInputChange}
                  id="Monsieur"
                  label="Monsieur"
                  name="civility"
                  value="Monsieur"
                />
                {submitted && !Boolean(formValues.civility) && (
                  <div className="text-error">
                    Veuillez sélectionner votre civilité
                  </div>
                )}
              </div>

              <div className="col-md-6">
                <fieldset>
                  <TextField
                    value={formValues.requester_lastname}
                    onChange={handleInputChange}
                    label="VOTRE PRÉNOM *"
                    name="requester_lastname"
                    type="text"
                    required
                    error={submitted && !Boolean(formValues.requester_lastname)}
                    helpText={
                      submitted &&
                      !Boolean(formValues.requester_lastname) &&
                      "Veuillez saisir votre prénom"
                    }
                  />
                </fieldset>
              </div>

              <div className="col-md-6">
                <fieldset>
                  <TextField
                    value={formValues.requester_firstname}
                    onChange={handleInputChange}
                    label="VOTRE NOM *"
                    name="requester_firstname"
                    type="text"
                    required
                    error={
                      submitted && !Boolean(formValues.requester_firstname)
                    }
                    helpText={
                      submitted &&
                      !Boolean(formValues.requester_firstname) &&
                      "Veuillez saisir votre nom"
                    }
                  />
                </fieldset>
              </div>

              <div className="col-md-6">
                <fieldset>
                  <TextField
                    value={formValues.requester_email}
                    onChange={handleInputChange}
                    label="VOTRE E-MAIL *"
                    name="requester_email"
                    type="email"
                    required
                    error={submitted && !Boolean(formValues.requester_email)}
                    helpText={
                      submitted &&
                      !Boolean(formValues.requester_email) &&
                      "Veuillez saisir votre email"
                    }
                  />
                </fieldset>
              </div>

              <div className="col-md-6">
                <fieldset>
                  <TextField
                    value={formValues.requester_phonenumber}
                    onChange={handleInputChange}
                    label="TÉLÉPHONE *"
                    name="requester_phonenumber"
                    type="text"
                    required
                    error={
                      submitted && !Boolean(formValues.requester_phonenumber)
                    }
                    helpText={
                      submitted &&
                      !Boolean(formValues.requester_phonenumber) &&
                      "Veuillez saisir votre numéro de téléphone"
                    }
                  />
                </fieldset>
              </div>

              <div className="col-md-12">
                <br />
                <ConfirmCheckbox
                  onChange={(e) => console.log(e.target.checked)}
                  label="J'accepte de recevoir par e-mail, sms ou courrier des offres ou informations sur les cours particuliers, stages en petits groupes, orientation"
                  error={true}
                />
              </div>

              <div className="col-md-12 text-left">
                <br />
                <input
                  onClick={send}
                  value="Envoyer"
                  type="button"
                  className="btn btn-primary"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TutoringPage;
