import React from "react";
import { OverviewCard, OverviewCardHeader } from "../../style";
import axios from "axios";
import TextField from "ui/TextField";
import { useAuthToken } from "hooks/useAuthToken";
import { toast } from 'react-toastify';
import SelectField from "ui/SelectField";
import { navigate } from "@reach/router";

export default function NewChildren({ }) {
  const [authToken] = useAuthToken();
  const [levels, setLevels] = React.useState<any[]>([]);
  const [classes, setClasses] = React.useState<any[]>([]);

  const [level, setLevel] = React.useState();
  const [classe, setClasse] = React.useState();
  const [lastname, setLastname] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    axios('/api/v1/tutoring/levels/for-request')
      .then(res => {
        setLevels(res.data)
      });
  }, []);

  const levelChange = (e: any) => {
    setLevel(e.target.value)
    const _c = levels.find(level => level.id == e.target.value)
    if (_c) {
      setClasses(_c.classes)
    }
  }

  const classeChange = (e: any) => {
    setClasse(e.target.value)
  }

  const nameChange = (e: any) => {
    setLastname(e.target.value)
  }

  const onSubmit = () => {
    setSubmitted(true);

    if (!Boolean(lastname) || !Boolean(level) || !Boolean(classe))
      return;

    axios.post("/api/v1/families/my/students", {
      level_id: level,
      classe_id: classe,
      lastname
    }, {
      headers: {
        ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {}),
      }
    }).then(res => {
      toast.success("Enfant ajouté avec succès!");
      setTimeout(() => {
        // window.location.reload();
        navigate("/soutien-scolaire/e/famille/enfants")
      }, 1500);
    })
      .catch(error => {
        toast.error("Erreur!");
      });
  }

  return (
    <div className="container">
      <OverviewCard>
        <div className="row">
          <OverviewCardHeader className="col-md-12">
            <h4 className="page-title">Ajouter un enfant</h4>
          </OverviewCardHeader>

          <div className="col-lg-12">
            <form className="row">
              <div className="col-md-6">
                <fieldset>
                  <TextField
                    onChange={nameChange}
                    label="Prénom"
                    type="text"
                    required
                    error={submitted && !Boolean(lastname)}
                    helpText={submitted && !Boolean(lastname) && "Veuillez saisir le prénom"}
                  />
                </fieldset>
              </div>

              <div className="col-md-6">
                <fieldset>
                  <SelectField
                    label="Niveau"
                    onChange={levelChange}
                    name="level"
                    required
                    error={submitted && !Boolean(level)}
                    helpText={submitted && !Boolean(level) && "Veuillez selectionner le niveau"}
                  >
                    <option></option>
                    {
                      levels.map((c, key) =>
                        <option key={key} value={c.id}>{c.name}</option>
                      )
                    }
                  </SelectField>
                </fieldset>
              </div>

              <div className="col-md-6">
                <fieldset>
                  <SelectField
                    onChange={classeChange}
                    label="Classe"
                    name="class"
                    required
                    error={submitted && !Boolean(classe)}
                    helpText={submitted && !Boolean(classe) && "Veuillez selectionner la classe"}
                  >
                    <option></option>
                    {
                      classes.map((c, key) =>
                        <option key={key} value={c.id}>{c.name}</option>
                      )
                    }
                  </SelectField>
                </fieldset>
              </div>

              <div className="col-md-12">
                <fieldset>
                  <button className="btn btn-primary" onClick={onSubmit}>Ajouter</button>
                </fieldset>
              </div>
            </form>
          </div>
        </div>
      </OverviewCard>
    </div>
  )
}
