import React from "react";
import axios from "axios";
import OnTopItem from "./OnTopItem";

interface IOnTop {
  section: string;
}

const TopJobOffers: React.FC<IOnTop> = ({ section }) => {
  const [data, setData] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    axios.get('/api/v1/articles/' + section + '/ontop')
      .then(res => {
        if (res.status === 200) {
          setData(res.data)
        }
      });
  }, []);

  return (
    <div className="card-simple sticky-top">
      <div>
        <h4 className="page-title">Articles à la une</h4>
      </div>
      <hr />
      <div className="container" style={{ overflow: "scroll", height: "500px" }}>
        <div className="row" >
          {
            data &&
            data.map((item, key) =>
              <OnTopItem section={section} article={item} key={key} />
            )
          }
        </div>
      </div>
    </div>
  )
}

export default TopJobOffers;