import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import ParticulierLayout from "ui/Layout/Particulier";
import PrivateRoute from "./PrivateRoute";
import Applications from "./Applications";
import Setting from "./Setting";
import ContactInfos from "./ContactInfos";
import Resume from "./Resume";
import Profile from "./Profile";

const Particulier: React.FC<RouteComponentProps> = () => {
  return (
    <ParticulierLayout>
      <Router>
        <PrivateRoute path="/candidatures" component={Applications} default />
        <PrivateRoute path="/parmaretres" component={Setting} />
        <PrivateRoute path="/contacts" component={ContactInfos} />
        <PrivateRoute path="/cv" component={Resume} />
        <PrivateRoute path="/profil" component={Profile} />
      </Router>
    </ParticulierLayout>
  )
}
export default Particulier;