import React from "react";
import styled from "styled-components";
import axios from "axios";
import { useAuthToken } from "hooks/useAuthToken";
import { toast } from 'react-toastify';
import useCurrentParticular from "hooks/useCurrentParticular";
import ReactPlayer from "react-player";
import { navigate } from "@reach/router";

const Wrapper = styled.div`
  height: 90vh;
  width: 100%;
  padding: 10px;
  display: flex;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  flex-direction: column;
  overflow: scroll;
`;

const Header = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 70px auto 130px;
  grid-template-rows: 100px;
  padding-bottom: 10px;

  @media (max-width: 425px) {
    grid-template-columns: 70px auto;
    grid-row-gap: 8px;

    & .apply {
      grid-column: span 2;
    }

    button {
      height: 45px;
      width: 100%;
    }
  }
`;

const ImageWrapper = styled.div`
  text-align: center;
`;

const InfosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  color: #777;
  height: 100%;
`;

const Title = styled.h4`
  color: var(--primary-color);
  font-weight: 400;
  font-size: 22px;
  margin: 0;
`;

const SubTitle = styled.h4`
  color: var(--primary-color);
  font-weight: bold;
  font-size: 18px;
  margin: 0;
`;

const Desc = styled.div`
  padding-bottom: 15px;
`;

const Action = styled.button`
  height: 50px;
  width: 130px;
`;

const Apply = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--tertiary-bg);
  border-radius: 30px;

  .far {
    margin-right: 5px;
  }

`;

export const Ul = styled.ul`
  background-color: rgba(128, 128, 128, 0.1);

  .nav-item .nav-link {
    background-color: transparent;
    border-radius: 0;
    color: var(--text-color)
  }

  .nav-item .nav-link.active {
    color: var(--tertiary-color);
    border-bottom: 3px solid var(--tertiary-color);
    font-weight: bold;
  }
`;

export const InfoList = styled.ul`
  display: block;
  list-style-type: none;
  padding: 0;

  .item {
    padding: 5px 0;
  }

  .item .item-title{
    padding: 5px 0;
    font-weight: 600;
  }
`;

interface JobDetailsProps {
  job?: any;
}
export default function JobDetails({ job }: JobDetailsProps) {
  const [data, setData] = React.useState(null);
  const [authToken] = useAuthToken();
  const [currentUser] = useCurrentParticular();
  let cancelRef = React.useRef<HTMLButtonElement>(null);
  let loginRef = React.useRef<HTMLButtonElement>(null);
  const [formValues, setFormValues] = React.useState("");

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('jobid')) {
      axios.get('/api/v1/joboffers/public/jobid/' + params.get('jobid'), {
        headers: {
          ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {})
        }
      })
        .then(res => {
          if (res.status === 200) {
            setData(res.data)
          }
        });
    }
  }, [window.location.search]);

  React.useEffect(() => {
    if (job) {
      setData(job)
    }
  }, [job]);


  const apply = () => {
    axios.post('/api/v1/applications', {
      jobid: data.id,
      message: formValues
    }, {
      headers: {
        ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {})
      }
    })
      .then(res => {
        toast.success("Votre candidature a bien été prise en compte");
        navigate("/stage-et-emploi/e/particulier/candidatures");
        cancelRef.current.click();
      })
      .catch(error => {
        if (error.response && error.response.status === 409) {
          toast.success("Votre candidature a bien été prise en compte");
          navigate("/stage-et-emploi/e/particulier/candidatures");
        }
        cancelRef.current.click();
      })
  }

  const verify = (e: any) => {
    loginRef.current.click();
    e.preventDefault();
    e.stopPropagation();
  }

  return (
    <>
      {
        data ?
          <Wrapper>
            <Header>
              <ImageWrapper>
                {
                  data.entreprise?.profile_image ?
                    <img src={data.entreprise.profile_image} width="100%" />
                    :
                    <i className="fas fa-building" style={{ fontSize: "60px", color: "#512581" }}></i>
                }
              </ImageWrapper>
              <InfosWrapper>
                <div>
                  <Title>{data.title}</Title>
                  <span>{data.entreprise?.name}</span>
                </div>
                <span><i className="fas fa-map-marker-alt"></i> {data.city}, {data.address}</span>
              </InfosWrapper>
              <div className="apply">
                {
                  data.hasApply ?
                    <Apply>
                      <i className="far fa-check-circle"></i>
                      Posutlé
                    </Apply>
                    :
                    currentUser ?

                      <Action className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                        Postuler
                      </Action>
                      :
                      <Action onClick={verify} className="btn btn-primary">
                        Postuler
                      </Action>

                }
              </div>
            </Header>
            <div>

              <Ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Description</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Infos clés</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="pills-entreprise-tab" data-toggle="pill" href="#pills-entreprise" role="tab" aria-controls="pills-entreprise" aria-selected="false">Entreprise</a>
                </li>
              </Ul>

              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade active show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <div>
                    <Desc>
                      <SubTitle>Description du poste</SubTitle>
                      <br />
                      <p>
                        {data.description}
                      </p>
                    </Desc>
                    <Desc>
                      <SubTitle>Profil recherché</SubTitle>
                      <br />
                      <p>
                        {data.profile}
                      </p>
                    </Desc>
                  </div>
                </div>

                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div>
                    <SubTitle>Infos clés</SubTitle>
                    <br />
                    <InfoList>
                      <li className="item">
                        <span className="item-title">Poste : </span>
                        <span> {data.title}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Lieu du poste : </span>
                        <span> {data.city}, {data.address}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Type de contrat : </span>
                        <span>{data.contract_type}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Durée du contrat : </span>
                        <span>{data.contract_length}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Remuneration : </span>
                        <span>{data.remuneration}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Niveau d'études : </span>
                        <span>{data.required_training}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Experience requise : </span>
                        <span>{data.required_experience}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Code de référence : </span>
                        <span>{data.reference}</span>
                      </li>
                    </InfoList>
                  </div>
                </div>

                <div className="tab-pane fade" id="pills-entreprise" role="tabpanel" aria-labelledby="pills-entreprise-tab">
                  <div>
                    <SubTitle>{data.entreprise?.name}</SubTitle>
                    <br />
                    <p>
                      {data.entreprise?.about}
                    </p>
                    <InfoList>
                      <li className="item">
                        <span className="item-title">Secteur : </span>
                        <span>{data.entreprise?.activity_sector} </span>
                      </li>
                      <li className="item">
                        <span className="item-title">Réseaux sociaux : </span>
                        <a href={data.entreprise?.facebook_link}><i className="fab fa-facebook-square"></i></a>
                      </li>
                      <li className="item">
                        <span className="item-title">Site web : </span>
                        <a href={data.entreprise?.website_link}> Visiter le site web de l'entreprise</a>
                      </li>
                    </InfoList>
                    <div style={{ textAlign: 'center' }}>
                      {
                        data.entreprise?.presentation_video_link &&
                        <ReactPlayer url={data.entreprise.presentation_video_link} />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Wrapper >
          : ""
      }

      <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">Confirmer</h3>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <h6>Laisser un message au recruteur</h6>
              <textarea value={formValues} onChange={(e) => setFormValues(e.target.value)} name="message" id="message" cols={30} rows={20} className="form-control" placeholder="Votre message"></textarea>
            </div>

            <div className="modal-footer">
              <button ref={cancelRef} type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
              <button onClick={apply} type="button" className="btn btn-primary">Envoyer</button>
            </div>

          </div>
        </div>
      </div>

      <Action ref={loginRef} className="btn btn-primary" data-toggle="modal" data-target="#loginModal" style={{ display: "none" }}>
        Login
      </Action>

      <div className="modal fade" id="loginModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">Veuillez-vous connecter</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body" style={{ fontSize: "16px" }}>
              <p className="text-center">
                <i className="fas fa-exclamation-triangle" style={{ color: "#dc3545", fontSize: "5rem" }}></i>
              </p>
              <p className="text-center">
                <b>
                  Vous souhaitez soumettre votre candidature?
                </b>
              </p>
              <p>
                Déjà inscrit? <br /> Connectez-vous à votre espace candidat. <a href={`/auth/~p/signin?redirect=${window.location.pathname}${window.location.search}`}>Connexion</a>
              </p>
              <p>
                Pas encore inscrit? <br /> Veuillez enregistrer votre candidature pour  accéder et postuler aux offres d'emploi. <a href={`/auth/~p/signup?redirect=${window.location.pathname}`}>Commencez l'enregistrement ici</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

