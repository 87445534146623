import React from "react";
import { RouteComponentProps } from "@reach/router";
import Bloc from "./Bloc";
import Switch from "ui/Switch";
import Studies from "./Studies";
import JobsAdsPricing from "./Jobs";
import { Desc } from "pages/StudentLife/Article";
import NewItem from "./NewItem";
import { NewsWrapper } from "pages/JobOffer/NewsList";
export default function ContactPage(props: RouteComponentProps) {

  React.useEffect(() => {
    initSlider();

  }, []);

  const initSlider = () => {
    if ((window as any).$) {
      (window as any).$("#studentlife.owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: false,
        dots: false,
        animateOut: true,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 3
          }
        }
      })
    }
  }

  return (
    <>
      <aside id="fh5co-hero" className="joboffer">
        <div className="flexslider">
          <ul className="slides">
            <li style={{ backgroundImage: `url(/images/works.jpg)` }}>
              <div className="overlay-gradient"></div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-md-offset-2 slider-text">
                    <div className="slider-text-inner">
                      <h1>Espace Annonceurs</h1>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </aside>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <br />
            <h1 style={{ color: "#0BA094" }} className="text-center">Nos Tarifs</h1>
            <NewsWrapper id="studentlife" className="owl-carousel">
              <NewItem item={{ name: "Tarifs annonceurs", slug: "/espace-annonceurs/annonceurs" }} />
              <NewItem item={{ name: "Tarifs études", slug: "/espace-annonceurs/etudes" }} />
              <NewItem item={{ name: "Tarifs entreprises", slug: "/espace-annonceurs/entreprises" }} />
            </NewsWrapper>
            <br />
            <br />
          </div>
        </div>
      </div>



      {/* <Studies /> */}
      {/* <JobsAdsPricing /> */}
    </>
  )
}
