import React from "react";
import styled from "styled-components";
import './style.css';
import './bread.css';
import Logo from "ui/Logo";
import clsx from "clsx";

export const Nav = styled.nav`
  background-color: #fff;
  padding: 0 15px;
`

export const Ul = styled.ul`
  background-color: #fff;
  padding: 0 15px;
  justify-content: space-between;
  display: flex;
  align-items: center;
`

interface HeaderProps {
  steps: Array<any>
  current: number;
}

const Header = (props: HeaderProps) => {
  return (
    <Nav className="fh5co-nav" role="navigation">
      <div className="top-menu">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-sm-2">
              <div id="fh5co-logo">
                <a href="/soutien-scolaire">
                  <Logo src="/logo-studies.png" alt="Logo Studies" style={{ width: 100 }} />
                </a>
              </div>
            </div>
            <div className="breadcrumb col-sm-8">
              {
                props.steps.map(((step, key) =>
                  <span key={key} className={clsx("breadcrumb__step", {
                    "breadcrumb__step--active": step.index <= props.current
                  })}>
                    {step.label}
                  </span>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </Nav>
  )
}

export default Header;