import React from "react";
import styled from "styled-components";
import clsx from "clsx";

interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  inputRef?: any;
  error?: boolean;
  helpText?: string;
}

const ConfirmCheckbox = ({ label, inputRef, helpText, error, ...props }: TextFieldProps) => {
  return (
    <>
      <input
        {...props}
        ref={inputRef}
        type="checkbox"
        className={clsx("", {
          "is-invalid": error
        })}
      />
      <label>{label}</label>
      <div className="invalid-feedback">
        {helpText}
      </div>
    </>
  )
}
export default ConfirmCheckbox;
