
import React from "react";
import { RouteComponentProps } from "@reach/router";
import ArticleItem from "pages/ArticleItem";
import axios from "axios";
import OnTop from "pages/OnTop";

export default function HomeArticle(props: RouteComponentProps) {
  const [articles, setArticles] = React.useState([]);

  const initSlider = () => {
    if ((window as any).$) {
      (window as any).$("#home.owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        dots: false,
        animateOut: true,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 3
          }
        }
      })
    }
  }

  React.useEffect(() => {
    axios('/api/v1/articles/home_page/onhome')
      .then(res => {
        setArticles(res.data);
        initSlider();
      });
  }, []);

  return (
    <>
      <div id="home" className="owl-carousel">
        {
          articles.map((article, key) => {
            return (
              <ArticleItem path="/articles" article={article} />
            )
          })
        }
      </div>
    </>
  )
}
