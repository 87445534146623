
import React from "react";
import HeaderJobOffer from 'ui/Header/HeaderJobOffer'
import TinyFooter from 'ui/Footer/TinyFooter'
import { Cover, Header, IconWrapper } from "./style";
import "./style.css";

const FamilyLayout = (props: any) => {
  const { children } = props;

  return (
    <div id="page">
      <HeaderJobOffer redirectPath="/soutien-scolaire" />
      <Header>
        <Cover />
        <div className="container" >
          <div className="row">
            {/* <div className="col-md-12">
              <IconWrapper>
                <i className="fas fa-user" style={{ fontSize: "50px", color: "white" }}></i>
              </IconWrapper>
            </div> */}
            <div className="col-md-12 fh5co-nav">
              <div className=" menu-1">
                <ul>
                  <li className={window.location.pathname.includes('interventions') ? 'active' : ''}>
                    <a href="/soutien-scolaire/e/famille/interventions">Interventions</a>
                  </li>
                  {/* <li className={window.location.pathname.includes('demande-intervention') ? 'active' : ''}>
                    <a href="/soutien-scolaire/e/famille/demande-intervention">Demandes d'intervention</a>
                  </li> */}
                  <li className={window.location.pathname.includes('enfants') ? 'active' : ''}>
                    <a href="/soutien-scolaire/e/famille/enfants">Enfants</a>
                  </li>
                  <li className={window.location.pathname.includes('profil') ? 'active' : ''}>
                    <a href="/soutien-scolaire/e/famille/profil">Profil</a>
                  </li>
                  {/* <li className={window.location.pathname.includes('contacts') ? 'active' : ''}>
                    <a href="/soutien-scolaire/e/famille/contacts">Coordonnées</a>
                  </li> */}
                  <li className={window.location.pathname.includes('parmaretres') ? 'active' : ''}>
                    <a href="/soutien-scolaire/e/famille/parmaretres">Paramètres</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Header>
      <div style={{ paddingBottom: "90px" }}>
        {children}
      </div>
      <TinyFooter />
    </div>
  )
}

export default FamilyLayout;