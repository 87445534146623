import React from "react";
import styled from "styled-components";
import moment from "moment";

const Wrapper = styled.div`
  height: 90px;
  width: 100%;
  padding: 10px;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &.selected {
    border-left: 3px solid #DD2421;
    background-color: rgba(128,128,128,0.1);
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  margin-right: 5px;
  flex-basis: 40px;

  img { 
    border-radius: 5px;
  }
`;

const InfosWrapper = styled.div`
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 5px;
`;

const Title = styled.h4`
  color: #000;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  word-break: break-word;

  :hover {
    color: var(--secondary-color);
  }
`;

const Date = styled.span`
  color: #777;
  font-size: 13px;
  text-transform: capitalize;
`;

const PATH = {
  orientation: "/orientation/article/",
  student_life: "/vie-etudiante/"
}

interface JobItemProps {
  article: any;
  section: string;
}
const JobItem: React.FC<JobItemProps> = ({ article, section }) =>
  <a href={`${PATH[section]}${article.slug}`}>
    <Wrapper className="col-md-12">
      <ImageWrapper>
        <img src={article.cover_image || '/images/no-image.png'} className="img-responsive" />
      </ImageWrapper>
      <InfosWrapper>
        <Title>{article.name.length <= 50 ? article.name : article.name.substr(0, 50) + "..."}</Title>
        <Date>  {moment(article.createdAt).format('MMMM YYYY')}</Date>
      </InfosWrapper>
    </Wrapper>
  </a>

export default JobItem;