import React from "react";
import styled from "styled-components";
import './style.css';
import Logo from "ui/Logo";

export const Nav = styled.nav`
  background-color: #fff;
  padding: 0 15px;
`

export const Ul = styled.ul`
  background-color: #fff;
  padding: 0 15px;
  justify-content: space-between;
  display: flex;
  align-items: center;
`

const Header = (props: any) => {
  return (
    <Nav className="fh5co-nav" role="navigation">
      {props.top}
      <div className="top-menu">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-sm-2">
              <div id="fh5co-logo">
                <a href="/soutien-scolaire/">
                  <Logo src="/logo-studies.png" alt="Logo Studies" style={{ width: 100 }} />
                </a>
              </div>
            </div>
            {/* <Ul className="col-sm-8">
              <li>
                <span>Demande</span>
              </li>
              <li>
                <span>Intervention</span>
              </li>
              <li>
                <span>Informations</span>
              </li>
            </Ul> */}
          </div>
        </div>
      </div>
    </Nav>
  )
}

export default Header;