import styled from "styled-components";

export const JobOverviewList = styled.div`
  height: 90vh;
  overflow: auto;
  box-shadow: 0 0.3rem 0.94rem rgba(128,128,128,0.06);
  border-radius: 5px;
  width: 100%;
  border: 1px solid #eeeeee;
`;
export const OverviewCard = styled.div`
  border: none;
  box-shadow: 0 0.3rem 0.94rem rgba(128,128,128,0.06);
  border-radius: 5px;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  margin: 15px auto;
  min-height: 70vh;
`;

export const OverviewCardHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
