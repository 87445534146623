import React from "react";
import { OverviewCard, OverviewCardHeader } from "../../style";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import axios from "axios";
import TextField from "ui/TextField";
import { useAuthToken } from "hooks/useAuthToken";
import useCurrentEntreprise from "hooks/useCurrentEntreprise";


type InputFieldEmail = {
  email: string;
}

const schemaEmail = yup.object().shape({
  email: yup.string().email().required("L'email est obligatoire"),
});

type InputFieldPassword = {
  password: string;
  newPassword: string;
  confPassword: string;
}

const schemaPassword = yup.object().shape({
  password: yup.string().required("Veuillez saisir votre mot de passe actuel"),
  newPassword: yup.string().required("Veuillez saisir votre nouveau mot de passe"),
  confPassword: yup.string().required("Veuillez confirmer le  nouveau mot de passe").oneOf([yup.ref('newPassword')], 'Non conforme au nouveau mot de passe'),
});

export default function Application({ }) {

  const { register: registerEmail, handleSubmit: handleSubmitEmail, errors: errorsEmail, reset: resetEmail } = useForm<InputFieldEmail>({ resolver: yupResolver(schemaEmail) });

  const { register: registerPassword, handleSubmit: handleSubmitPassword, errors: errorsPassword, reset: resetPassword } = useForm<InputFieldPassword>({ resolver: yupResolver(schemaPassword) });

  const [isSuccessEmail, setIsSuccessEmail] = React.useState(false);
  const [isSuccessPassword, setIsSuccessPassword] = React.useState(false);
  const [isErrorPassword, setIsErrorPassword] = React.useState(false);

  const [authToken] = useAuthToken();
  const [currentUser] = useCurrentEntreprise();

  const onSubmitEmail = (data: any) => {
    axios.put("/api/v1/entreprises/update-email", {
      email: data.email,
    }, {
      headers: {
        ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {}),
      }
    }).then(res => {
      setIsSuccessEmail(true);
      resetEmail();
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    })
      .catch(error => {
      })
  }

  const onSubmitPassword = (data: any) => {
    axios.put("/api/v1/entreprises/update-password", {
      password: data.password,
      newPassword: data.newPassword,
    }, {
      headers: {
        ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {}),
      }
    }).then(res => {
      setIsSuccessPassword(true);
      resetPassword();
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          setIsErrorPassword(true);
          setTimeout(() => {
            resetPassword();
            setIsErrorPassword(false);
          }, 1500);
        }
      })
  }

  return (
    <div className="container">
      <OverviewCard>
        <div className="row">
          <OverviewCardHeader className="col-md-12">
            <h4 className="page-title">Paramètres</h4>
          </OverviewCardHeader>

          <div className="col-lg-6">
            <form onSubmit={handleSubmitPassword(onSubmitPassword)}>
              {
                isErrorPassword &&
                <div className="alert alert-danger" role="alert">
                  Votre mot de passe est incorrect.
                    </div>
              }
              {
                isSuccessPassword &&
                <div className="alert alert-success" role="alert">
                  Mot de passe modifié avec succès!
                    </div>
              }
              <b>Changer votre mot de passe</b>
              <fieldset>
                <TextField
                  label="Ancien mot de passe"
                  name="password"
                  type="password"
                  required
                  inputRef={registerPassword}
                  error={!!errorsPassword.password}
                  helpText={errorsPassword.password?.message}
                />
              </fieldset>
              <fieldset>
                <TextField
                  label="Nouveau mot de passe"
                  name="newPassword"
                  type="password"
                  required
                  inputRef={registerPassword}
                  error={!!errorsPassword.newPassword}
                  helpText={errorsPassword.newPassword?.message}
                />
              </fieldset>
              <fieldset>
                <TextField
                  label="Réécrire nouveau mot de passe"
                  name="confPassword"
                  type="password"
                  required
                  inputRef={registerPassword}
                  error={!!errorsPassword.confPassword}
                  helpText={errorsPassword.confPassword?.message}
                />
              </fieldset>
              <fieldset>
                <button className="btn btn-primary">Mettre à jour</button>
              </fieldset>
            </form>
          </div>

          <div className="col-lg-6">
            <form onSubmit={handleSubmitEmail(onSubmitEmail)}>
              {
                isSuccessEmail &&
                <div className="alert alert-success" role="alert">
                  Email modifié avec succès!
                    </div>
              }
              <b>Votre email</b>
              <fieldset>
                <label htmlFor="prenom">Adresse e-mail actuelle</label>
                <input value={currentUser?.login_email} placeholder="" className={'form-control'} type="text" required disabled />
              </fieldset>
              <fieldset>
                <TextField
                  label="Nouvel email"
                  name="email"
                  type="email"
                  required
                  inputRef={registerEmail}
                  error={!!errorsEmail.email}
                  helpText={errorsEmail.email?.message}
                />
              </fieldset>

              <fieldset>
                <button className="btn btn-primary">Mettre à jour</button>
              </fieldset>
            </form>
          </div>
        </div>
      </OverviewCard>
    </div>
  )
}
