import React from "react";
import { StudiesLogo } from "./style";
import './style.css';

const TinyHeader = () => {
  return (
    <nav className="fh5co-nav" role="navigation">
      <div className="top-menu">
        <div className="container">
          <div className="row">
            <div className="col-xs-2 mx-auto">
              <div id="fh5co-logo mx-auto">
                <a href="/">
                  <StudiesLogo src="/logo-studies.png" alt="Logo Studies" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
export default TinyHeader;
