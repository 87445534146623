import clsx from "clsx";
import React from "react";

interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  inputRef?: any;
  error?: boolean;
  helpText?: string;
}

const TextField = ({
  label,
  inputRef,
  helpText,
  error,
  ...props
}: TextFieldProps) => {
  return (
    <>
      <label>{label}</label>
      <input
        {...props}
        ref={inputRef}
        className={clsx("form-control", {
          "is-invalid": error,
        })}
      />
      <div className="invalid-feedback">{helpText}</div>
    </>
  );
};
export default TextField;
