
import React from "react";
import { RouteComponentProps } from "@reach/router";
import ArticleItem from "../ArticleItem";
import axios from "axios";
import OnTop from "pages/OnTop";

export default function HomeArticle(props: RouteComponentProps) {
  const [articles, setArticles] = React.useState([]);

  React.useEffect(() => {
    axios('/api/v1/articles/student_life/onhome')
      .then(res => {
        setArticles(res.data);
        initSlider();
      });
  }, []);

  const initSlider = () => {
    if ((window as any).$) {
      (window as any).$("#studentlife.owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        dots: false,
        animateOut: true,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 3
          }
        }
      })
    }
  }

  return (
    <>
      <div id="studentlife" className="owl-carousel">
        {
          articles.map((article, key) => {
            return (
              <ArticleItem path="/vie-etudiante" article={article} />
            )
          })
        }
      </div>

      {/* <div className="row">
        {
          articles.map((article, key) => {
            return (
              <div key={key} className="col-lg-4 col-md-4">
                <ArticleItem path="/vie-etudiante" article={article} />
              </div>
            )
          })
        }
      </div> */}
    </>
  )
}
