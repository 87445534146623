import React from "react";
import styled from "styled-components";

const Label = styled.label`
  background-color: #fff;
  padding: 7px 14px;
  border-radius: 30px;
  cursor: pointer;
  border: 1px solid #bababb;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
  width: 120px;

`

const Input = styled.input`
  opacity: 0;
  display: none;
  visibility: hidden;
  position: absolute;
  z-index: -999;

  :checked + Label {
    background-color: #512581;
    border: 1px solid #512581;
    color: #fff;
  }
`
const x = 4;
interface LabelRadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  name: string;
  value: string;
}

const LabelRadio = ({ id, label, name, value, ...props }: LabelRadioProps) => {
  return (
    <>
      <Input {...props} id={id} type="radio" name={name} value={value} />
      <Label htmlFor={id}>{label}</Label>
    </>
  )
}

export default LabelRadio;