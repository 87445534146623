import React from "react";

const SearchBar: React.FC = () => {
  const [searchQuery, setSearchQuery] = React.useState(null);

  const search = () => {
    if (searchQuery)
      window.location.href = `/fiches-metiers/?query=${searchQuery}`;
  }

  return (
    <div className="search-container">
      <div className="row">
        <div className="col-md-10">
          <input onChange={e => setSearchQuery(e.target.value)} className="form-control" type="text" placeholder="Rechercher un metier" />
        </div>
        <div className="col-md-2">
          <button onClick={search} className="search-button btn-rounded">Rechercher</button>
        </div>
      </div>
    </div>
  )
}

export default SearchBar;