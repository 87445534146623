import React from "react";
import styled from "styled-components";
import Logo from "ui/Logo";
import "./style.css";
import axios from "axios";

const Wrapper = styled.div`
  background-color: #233266;
  padding-top: 30px;
  bottom: 0;
  width: 100%;
`;

const Footer: React.FC = () => {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    axios.get("/api/v1/settings/informations/").then((res) => {
      if (res.status === 200 && res.data.length > 0) setData(res.data[0]);
    });
  }, []);

  return (
    <Wrapper id="fh5co-footer" role="footerinfo">
      <div className="container">
        <div className="row">
          <div className="col-md-4 fh5co-widget">
            <div id="fh5co-logo">
              <a href="/">
                <Logo src="/logo-studies.png" alt="Logo Studies" />
              </a>
            </div>
            <br />
            <ul className="fh5co-footer-links">
              {data?.address && (
                <li>
                  <i className="fa fa-map-marker-alt contact-title"></i>
                  <span>{data?.address}</span>
                </li>
              )}
              {data?.email && (
                <li>
                  <i className="fa fa-envelope-open  contact-title"></i>
                  <span>{data?.email}</span>
                </li>
              )}
              {data?.phonenumber && (
                <li>
                  <i className="fas fa-phone-alt contact-title"></i>
                  <span>{data?.phonenumber}</span>
                </li>
              )}
            </ul>
          </div>
          <div className="col-md-2 col-sm-4 col-xs-6 col-md-push-1 fh5co-widget">
            <h3>Navigation</h3>
            <ul className="fh5co-footer-links">
              <li>
                <a href="/">Accueil</a>
              </li>
              <li>
                <a href="/orientation">Orientation</a>
              </li>
              <li>
                <a href="/etudes">Études</a>
              </li>
              <li>
                <a href="/vie-etudiante">Vie Étudiante</a>
              </li>
              <li>
                <a href="/stage-et-emploi">Stages & Emploi</a>
              </li>
            </ul>
          </div>

          <div className="col-md-2 col-sm-4 col-xs-6 col-md-push-1 fh5co-widget">
            <br />
            <ul className="fh5co-footer-links">
              <li>
                <a href="/fiches-metiers">Fiches Metiers</a>
              </li>
              <li>
                <a href="/soutien-scolaire">Soutien Scolaire</a>
              </li>
              <li>
                <a href="/nous-contacter">Nous contacter</a>
              </li>
              <li>
                <a href="/a-propos">À propos</a>
              </li>
              <li>
                <a href="/espace-annonceurs">Espace annonceurs</a>
              </li>
            </ul>
          </div>
        </div>

        <hr />

        <div className="row copyright">
          <div className="col-md-12">
            <p>
              <small className="block">
                &copy; 2020 Studies. Tous droits réservés.
              </small>
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;
