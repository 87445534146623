import React from "react";
import { RouteComponentProps } from "@reach/router";
import axios from "axios";
import { Overview, PageTitle } from "./style";
import Ads from "pages/Ads";
import Adser from "pages/Adser";

interface IProps extends RouteComponentProps {
  slug?: string;
}


const TutoringPage: React.FC<IProps> = ({ slug }) => {

  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    axios('/api/v1/articles/tutoring_section/slug/' + slug)
      .then(res => {
        setData(res.data)
      });
  }, []);

  return (
    <div className="container">
      <br />
      <div className="row">
        <div className="col-md-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">Accueil</a></li>
              <li className="breadcrumb-item"><a href="/soutien-scolaire">Soutien scolaire</a></li>
            </ol>
          </nav>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-8">
          <div className="">
            <div className="row">
              <div className="col-md-12 noPdright">
                <div className="clsap">
                  <PageTitle>{data?.name}</PageTitle>

                  <p>
                    {data?.description}

                  </p>
                </div>
              </div>
            </div>

            <br />

            <div className="">
              <div className="row">
                <div className="col-md-12">
                  <div dangerouslySetInnerHTML={{ __html: data?.presentation }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <Adser />
          <br />
          <Ads sticky page="tutoring_home" />
        </div>
      </div>
    </div>
  )
}

export default TutoringPage;