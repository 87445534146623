import React from "react";
import styled from "styled-components";

const Chip = styled.span`
  background-color: var(--primary-color);
  margin-right: 5px;
  color: #fff;
  padding: 5px 15px;
  border-radius: 30px;
  display: inline-block;
  margin-bottom: 6px;

  .fas {
    margin-left: 10px;
    cursor: pointer;
  }
`

interface RemoveChipProps {
  label: string;
  onRemove: () => void;
}

const RemoveChip: React.FC<RemoveChipProps> = ({ label, onRemove }) => {
  return (
    <Chip>
      {label}
      <i className="fas fa-close" onClick={onRemove}></i>
    </Chip>
  )
}

export default RemoveChip;