import React from "react";
import { RouteComponentProps } from "@reach/router";
import SearchBar from "./SearchBar";
import Covers from "pages/Covers";
import styled from "styled-components";
import { Cover } from "./Sheet";
import Item from "./Item";
import { Desc } from "pages/StudentLife/Article";
import axios from "axios";
import { Count } from "pages/Studies/SearchResults";

export const Wrapper = styled.div`
  background: #f1f1f1;
  padding: 20px;
  border-radius: 10px;

  .img-responsive {
    height: 250px;
    width: 100%;
  }

  img {
    width: 100%;
  }

  p {
    font-size: 14px;
  }
`;

export const Title = styled.h1`
  font-size: 30px;
  color: var(--primary-color);
  font-weight: bold;
  text-align: center;
`;

export const FloatTitle = styled.h1`
  font-size: 50px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin-top: -100px;
  margin-bottom: 50px;
  position: relative;
  z-index: 2;
`;

export const SubTitle = styled.h4`
  font-size: 18px;
  color: var(--primary-color);
  font-weight: bold;
`;

interface IProps extends RouteComponentProps {
  slug?: string;
}

export default function SectorPage({ slug }: IProps) {
  const [sector, setSector] = React.useState(null);
  const [show, setShow] = React.useState(true);

  React.useEffect(() => {
    fetch("/api/v1/sheets/sectors/slug/" + slug)
      .then((res) => res.json())
      .then((data) => {
        setSector(data);
      })
      .catch(console.log);
  }, []);

  React.useEffect(() => {
    if (sector)
      axios("/api/v1/page-covers/public?page=sheet_sector_" + sector?.id).then(
        (res) => {
          if (res.data.length > 0) {
            setShow(false);
          }
        }
      );
  }, [sector]);

  return (
    <>
      {sector && <Covers page={`sheet_sector_${sector?.id}`} />}

      {sector ? (
        <>
          <div id="fh5co-blog" style={{ paddingTop: "2em" }}>
            <div className="container">
              <div className="row">
                <Wrapper className="col-md-12 slider-text stduies-card">
                  <div className="slider-text-inner">
                    {show && sector?.cover_image ? (
                      <>
                        <Cover
                          style={{
                            backgroundImage: `url(${sector?.cover_image})`,
                          }}
                        />
                        <FloatTitle>{sector.name}</FloatTitle>
                      </>
                    ) : (
                      <Title>{sector.name}</Title>
                    )}

                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                              <li className="breadcrumb-item">
                                <a href="/">Accueil</a>
                              </li>
                              <li className="breadcrumb-item">
                                <a href="/fiches-metiers">Fiches métiers</a>
                              </li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </div>

                    <div className="container">
                      <SearchBar />
                    </div>

                    {sector?.description && (
                      <>
                        <br />
                        <Desc>{sector?.description}</Desc>
                      </>
                    )}

                    <br />
                    <div
                      dangerouslySetInnerHTML={{ __html: sector.presentation }}
                    ></div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <SubTitle> Metiers du secteur</SubTitle>
                      {sector.sheets.length > 0 && (
                        <Count>
                          {sector.sheets.length} métier
                          {sector.sheets.length > 1 && "s"}
                        </Count>
                      )}
                    </div>

                    {sector.sheets.map((sector: any, key: any) => {
                      return (
                        <div className="col-lg-3 col-md-3">
                          <Item article={sector} />
                        </div>
                      );
                    })}
                  </div>
                </Wrapper>
              </div>
            </div>
          </div>
        </>
      ) : (
        " "
      )}
    </>
  );
}
