import React from "react";
import { TabHeader, TabWapper } from "pages/Auth/style";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const SuccessTab = () => {

  React.useEffect(() => {
    MySwal.fire({
      icon: "success",
      title: <p>Votre compte a bien été créé</p>,
      text: "Un mail pour l'activation de votre compte vous a été envoyé.",
      showConfirmButton: false,
      willClose: () => {
        window.location.href = "/auth/~f/signin"
      }
    })
  }, []);

  return (
    <TabWapper>
      <TabHeader>
      </TabHeader>
    </TabWapper >
  )
}

export default SuccessTab;