import React from "react";
import { RouteComponentProps } from "@reach/router";
import { OrientationCategorie } from "__types__";
import axios from "axios";
import ArticleItem from "pages/ArticleItem";
import { Desc } from "pages/StudentLife/Article";
import OnTop from "pages/OnTop";

interface IProps extends RouteComponentProps {
  slug?: string;
}

export default function OrientationPage({ slug }: IProps) {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<OrientationCategorie>();

  React.useEffect(() => {
    axios.get('/api/v1/articles/categories/orientation/public/slug/' + slug)
      .then(res => {
        setLoading(false);
        if (res.status === 200) {
          setData(res.data)
        }
      });
  }, []);

  return (
    <>
      {
        !loading && data ?
          <>
            <aside className="page-cover" style={{ backgroundImage: `url(${data.cover_image || '/images/project-1.jpg'})` }}>
              <div className="container flex">
                <h1 className="page-cover-title">{data.name}</h1>
              </div>
            </aside>

            <div className="container">
              <br />
              <div className="row">
                <div className="col-md-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="/">Accueil</a></li>
                      <li className="breadcrumb-item"><a href="/orientation">Orientation</a></li>
                      {/* <li className="breadcrumb-item active" aria-current="page">Data</li> */}
                    </ol>
                  </nav>
                </div>
              </div>
            </div>

            <div id="fh5co-blog" >

              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-12">
                        {
                          data?.description &&
                          <Desc>{data?.description}</Desc>
                        }
                      </div>
                      {
                        data?.articles?.map((article, key) => {
                          return (
                            <div key={key} className="col-lg-4 col-md-4">
                              <ArticleItem path={"/orientation/" + data?.slug} article={article} />
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  <div className="col-md-4">
                    <OnTop section="orientation" />
                  </div>
                </div>

              </div>
            </div>
          </>
          :
          <></>
      }
    </>
  )
}
