import React from "react";
import { handleErrors } from "utils/http";
import { RouteComponentProps } from "@reach/router";
import axios from "axios";

const defaultValues = {
  name: "",
  lastnme: "",
  email: "",
  subject: "",
  message: "",
};

export default function ContactPage(props: RouteComponentProps) {
  const [data, setData] = React.useState(null);
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  React.useEffect(() => {
    axios.get("/api/v1/settings/informations/").then((res) => {
      if (res.status === 200 && res.data.length > 0) setData(res.data[0]);
    });
  }, []);

  const handleInputChange = React.useCallback(
    (event) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  const sendMessage = (e: any) => {
    setFormSubmited(true);

    if (
      formValues.name.length === 0 ||
      formValues.email.length === 0 ||
      formValues.message.length === 0
    )
      return;

    const body = JSON.stringify(formValues);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    };

    fetch("/api/v1/messages", requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((res) => {
        setIsSuccess(true);
        setFormSubmited(false);
        setFormValues(defaultValues);
        setTimeout(() => {
          setIsSuccess(false);
        }, 1500);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  return (
    <div id="fh5co-contact">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-md-push-1 animate-box">
            <div className="fh5co-contact-info">
              <h3 className="search-filter-title">Information</h3>
              <ul>
                {data?.address && <li className="address">{data?.address}</li>}
                {data?.phonenumber && (
                  <li className="phone">{data?.phonenumber}</li>
                )}
                {data?.email && <li className="email">{data?.email}</li>}
              </ul>
            </div>
          </div>
          <div className="col-md-6 animate-box">
            <h3 className="search-filter-title">Contact</h3>
            {isSuccess && (
              <div className="contact-success">
                Votre message a bien été envoyé
              </div>
            )}
            <form action="#">
              <div className="row form-group">
                <div className="col-md-6">
                  <input
                    value={formValues.name}
                    onChange={handleInputChange}
                    name="name"
                    type="text"
                    id="fname"
                    className="form-control"
                    placeholder="Nom"
                  />
                  {isFormSubmited && formValues.name.length === 0 && (
                    <label className="text-error">
                      Veuillez remplir votre nom
                    </label>
                  )}
                </div>
                <div className="col-md-6">
                  {/* <!-- <label for="email">Email</label> --> */}
                  <input
                    value={formValues.email}
                    onChange={handleInputChange}
                    name="email"
                    type="text"
                    id="email"
                    className="form-control"
                    placeholder="Email"
                  />
                  {isFormSubmited && formValues.email.length === 0 && (
                    <label className="text-error">
                      Veuillez remplir votre email
                    </label>
                  )}
                </div>
              </div>

              <div className="row form-group">
                <div className="col-md-12">
                  {/* <!-- <label for="subject">Subject</label> --> */}
                  <input
                    value={formValues.subject}
                    onChange={handleInputChange}
                    name="subject"
                    type="text"
                    id="subject"
                    className="form-control"
                    placeholder="Sujet"
                  />
                </div>
              </div>

              <div className="row form-group">
                <div className="col-md-12">
                  <textarea
                    value={formValues.message}
                    onChange={handleInputChange}
                    name="message"
                    id="message"
                    cols={30}
                    rows={10}
                    className="form-control"
                    placeholder="Votre message"
                  ></textarea>
                  {isFormSubmited && formValues.message.length === 0 && (
                    <label className="text-error">
                      Veuillez remplir votre message
                    </label>
                  )}
                </div>
              </div>
              <div className="form-group text-right">
                <input
                  onClick={sendMessage}
                  value="Envoyer"
                  type="button"
                  className="btn btn-primary"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
