import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import TeacherLayout from "ui/Layout/Teacher";
import PrivateRoute from "./PrivateRoute";
import Followup from "./Followup";
import Interventions from "./Interventions";
import Setting from "./Setting";
import ContactInfos from "./ContactInfos";
import Resume from "./Resume";
import Profile from "./Profile";
import NewTeaching from "./NewTeaching";

const Particulier: React.FC<RouteComponentProps> = () => {
  return (
    <TeacherLayout>
      <Router>
        <PrivateRoute path="/interventions" component={Interventions} default />
        <PrivateRoute path="/interventions/:code" component={Followup} />
        <PrivateRoute path="/parmaretres" component={Setting} />
        <PrivateRoute path="/contacts" component={ContactInfos} />
        <PrivateRoute path="/cv" component={Resume} />
        <PrivateRoute path="/profil" component={Profile} />
        <PrivateRoute path="/profil/edit" component={NewTeaching} />
      </Router>
    </TeacherLayout>
  )
}
export default Particulier;