import Covers from "pages/Covers";
import CategoryBloc from "pages/Overview/CategoryBloc";
import React from "react";
import Footer from "ui/Footer";
import Header from "ui/Header/Header";
// import Footer from "ui/Footer";
// import Header from "ui/Header";
import { StudiesLogo } from "ui/Header/style";

interface MainLayoutProps {
  top?: React.ReactNode;
  children: React.ReactNode;
}
const MainLayout: React.FC<MainLayoutProps> = ({ children, top }) => {
  return (
    <>
      <Header top={top} />
      <div>
        {children}
      </div>
      <Footer />
    </>
  )
}

export default MainLayout