import React from "react";
import styled from "styled-components";
import Rating from "react-rating";
import axios from "axios";
import moment from "moment";


const Wrapper = styled.div`
.btn-grey{
    background-color:#D8D8D8;
	color:#FFF;
}
.rating-block{
	background-color:#FAFAFA;
	border:1px solid #EFEFEF;
	padding:15px 15px 20px 15px;
	border-radius:3px;
}
.bold{
	font-weight:700;
}
.padding-bottom-7{
	padding-bottom:7px;
}

.review-block{
	background-color:#FAFAFA;
	border:1px solid #EFEFEF;
	padding:15px;
	border-radius:3px;
	margin-bottom:15px;
}
.review-block-name{
	font-size:16px;
	margin:10px 0;
}
.review-block-date{
	font-size:12px;
  font-style: italic;
}
.review-block-rate{
	font-size:13px;
	margin-bottom:15px;
}
.review-block-title{
	font-size:15px;
	font-weight:700;
	margin-bottom:10px;
}
.review-block-description{
	font-size:13px;
}

.fa-star {
  color: var(--primary-color);
}

.average {
  font-size: 20px;
}
`;


const RatingInfos = ({ schoolId }: { schoolId: string }) => {
  const [data, setData] = React.useState([]);
  const [average, setAverage] = React.useState(0);


  React.useEffect(() => {
    axios.get('/api/v1/studies/rating/' + schoolId, {
    })
      .then(res => {
        if (res.status === 200) {
          setData(res.data)
          setAverage(
            Math.ceil(res.data.reduce((prev: any, curr: any) => curr.rating + prev, 0) / (res.data.length | 1))
          )
        }
      });

  }, []);

  return (
    <Wrapper className="container">
      <div className="row">
        <div className="col-sm-3">
          <div className="rating-block">
            <h4>Note des utilisateurs</h4>
            <h2 className="bold padding-bottom-7">{average} <small>/ 5</small></h2>
            <Rating
              className="average"
              emptySymbol={<i className="far fa-star"></i>}
              fullSymbol={<i className="fas fa-star"></i>}
              initialRating={average}
              readonly
            />
          </div>
        </div>
      </div>

      <br />

      <div>
        <button type="button" className="btn btn-secondary school-card-button" data-toggle="modal" data-target="#ratingModal" >Donner un avis</button>
      </div>

      <br />

      {
        data.length > 0 &&

        <div className="row">
          <div className="col-sm-7">
            <div className="review-block">
              {
                data.map((rating, key) =>
                  <>
                    <div className="row">
                      <div className="col-sm-3">
                        <img src="http://dummyimage.com/60x60/666/666" className="img-rounded" />
                        <div className="review-block-name"><a href="#">{rating.name}</a></div>
                        <div className="review-block-date">{moment(rating.createdAt).format('D/M/YYYY')}<br /></div>
                      </div>
                      <div className="col-sm-9">
                        <div className="review-block-rate">
                          <Rating
                            emptySymbol={<i className="far fa-star"></i>}
                            fullSymbol={<i className="fas fa-star"></i>}
                            initialRating={rating.rating}
                            readonly
                          />
                        </div>
                        <div className="review-block-description">{rating.message}</div>
                      </div>
                    </div>
                    <hr />
                  </>
                )
              }
            </div>
          </div>
        </div>

      }
    </Wrapper>
  )
}

export default RatingInfos;