import React from "react";
import Entreprise from "./Entreprise";
import { Router, RouteComponentProps } from "@reach/router";
import Overview from "./Overview";
import OverviewEntreprise from "./OverviewEntreprise";
import OverviewParticular from "./OverviewParticular";
import OverviewHome from "./OverviewHome";
import Jobs from "./Jobs";
import Particulier from "./Candidat";
import JobsSearch from "./JobsSearch";
import NewsPage from "./News";
import JobDetailsPage from "./JobDetailsPage";

const Private: React.FC<RouteComponentProps> = () => {
  return (
    <Router>
      <Entreprise path="/entreprise/*" />
      <Particulier path="/particulier/*" />
    </Router>
  )
}

const JobOffer: React.FC<RouteComponentProps> = () => {
  return (
    <Router>
      <Overview path="/" />
      <OverviewEntreprise path="/entreprise" />
      <OverviewParticular path="/candidats" />
      <OverviewHome path="/a-propos" />
      <Jobs path="/offres" />
      <JobDetailsPage path="/offres/:jobId" />
      <JobsSearch path="/recherche" />
      <Private path="/e/*" />
      <NewsPage path="/:slug" />
    </Router>
  )
}

export default JobOffer;