import React from "react";
import { RouteComponentProps, Router } from "@reach/router";
import Signin from "./Signin";
import New from "./Reset/New";
import Reset from "./Reset/Reset";
import Signup from "./Signup";
import Activate from "./Activate";
import CandidatCreationProvider from "context/CandidatCreation";

const Particular: React.FC<RouteComponentProps> = () => {
  return (
    <CandidatCreationProvider>
      <Router>
        <New path="/reset/new" />
        <Reset path="/reset/password/:token" />
        <Signin path="/signin" />
        <Signup path="/signup" />
        <Activate path="/activate/:token" />
      </Router>
    </CandidatCreationProvider>
  )
}

export default Particular;