import React from "react";
import { RouteComponentProps } from "@reach/router";
import Bloc from "./Bloc";
import Switch from "ui/Switch";
import BlocJobs from "./BlocJobs2";
import { Ul } from "pages/JobOffer/JobDetails";

const prices = [
  {
    name: "GRILLE TARIFAIRE STUDIES- SOUTIEN SCOLAIRE",
    items: [] as any[],
  },
];

const Section = ({ price }: { price: any }) => {
  const [by, setBy] = React.useState(true);
  return (
    <div className="container py-5">
      <header className="text-center mb-5 text-white">
        <div className="row">
          <div className="col-lg-7 mx-auto">
            <h1 style={{ color: "#0BA094" }}>{price.name}</h1>
            <p className="text-center">
              <Switch
                primary={{
                  label: "6 mois",
                  checked: by,
                }}
                secondary={{
                  label: "12 mois",
                  checked: !by,
                }}
                onChange={(check) => {
                  setBy(check);
                }}
              />
            </p>
          </div>
        </div>
      </header>
      <div className="row text-center justify-content-center">
        {price.items.map((item: any) => (
          <BlocJobs
            by={by ? "months" : "years"}
            title={item.title}
            price={item.price}
            items={item.items}
            selected={item?.selected}
          />
        ))}
      </div>
    </div>
  );
};
export default function JobsAdsPricing() {
  return (
    <section style={{ textAlign: "center" }}>
      <h2 className="text-center rouded-bg">
        GRILLE TARIFAIRE STUDIES-SOUTIEN SCOLAIRE
      </h2>
      <br />
      <br />
      <div>
        <h4
          className="text-center"
          style={{ color: "#DD2421", textAlign: "center" }}
        >
          TARIFS INTERVENTIONS HEBDOMADAIRES À DOMICLE
        </h4>

        <img src="/tutoring-pricing-1.png" alt="" />
        {/* <Ul
          className="nav nav-pills mb-3 justify-content-around"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className="nav-link active"
              id="pills-home-tab"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              NIVEAU PRIMAIRE
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="pills-profile-tab"
              data-toggle="pill"
              href="#pills-profile"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
            >
              NIVEAU SECONDAIRE
            </a>
          </li>
        </Ul> */}

        {/* <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade active show"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <table className="table table-bordered table-hover table-striped text-center table-light">
              <thead className="thead-light">
                <tr>
                  <th className="color-primary" scope="col">
                    Classes ordinaires
                  </th>
                  <th className="color-primary" scope="col" colSpan={3}>
                    CP-CE1-CE2-CM1
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nombre d'enfants /famille</td>
                  <td>01 enfant</td>
                  <td>02 enfants</td>
                  <td>03 enfants et plus</td>
                </tr>
                <tr>
                  <td>Tarif/Séance/enfant</td>
                  <td>2 400 FCFA</td>
                  <td>2 200 FCFA</td>
                  <td>2 000 FCFA</td>
                </tr>
                <tr className="thead-light">
                  <th className="color-primary" scope="col">
                    Classes
                  </th>
                  <th className="color-primary" scope="col" colSpan={3}>
                    CM2
                  </th>
                </tr>
                <tr>
                  <td>Nombre d'enfants /famille</td>
                  <td>01 enfant</td>
                  <td>02 enfants</td>
                  <td>03 enfants et plus</td>
                </tr>
                <tr>
                  <td>Tarif/Séance/enfant</td>
                  <td>2 600 FCFA</td>
                  <td>2 500 FCFA</td>
                  <td>2 400 FCFA</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            className="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <table className="table table-bordered table-hover table-striped text-center table-light">
              <thead className="thead-light">
                <tr>
                  <th className="color-primary" scope="col">
                    Classes
                  </th>
                  <th className="color-primary" scope="col" colSpan={3}>
                    Sixième-Cinquième-Quatrième
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nombre d'enfants /famille</td>
                  <td>01 enfant</td>
                  <td>02 enfants</td>
                  <td>03 enfants et plus</td>
                </tr>
                <tr>
                  <td>Tarif/Séance/enfant</td>
                  <td>2 600 FCFA</td>
                  <td>2 400 FCFA</td>
                  <td>2 200 FCFA</td>
                </tr>
                <tr className="thead-light">
                  <th className="color-primary" scope="col">
                    Classes
                  </th>
                  <th className="color-primary" scope="col" colSpan={3}>
                    Troisième
                  </th>
                </tr>
                <tr>
                  <td>Nombre d'enfants /famille</td>
                  <td>01 enfant</td>
                  <td>02 enfants</td>
                  <td>03 enfants et plus</td>
                </tr>
                <tr>
                  <td>Tarif/Séance/enfant</td>
                  <td>2 800 FCFA</td>
                  <td>2 700 FCFA</td>
                  <td>2 600 FCFA</td>
                </tr>
                <tr className="thead-light">
                  <th className="color-primary" scope="col">
                    Classes
                  </th>
                  <th className="color-primary" scope="col" colSpan={3}>
                    Seconde
                  </th>
                </tr>
                <tr>
                  <td>Nombre d'enfants /famille</td>
                  <td>01 enfant</td>
                  <td>02 enfants</td>
                  <td>03 enfants et plus</td>
                </tr>
                <tr>
                  <td>Tarif/Séance/enfant</td>
                  <td>2 800 FCFA</td>
                  <td>2 600 FCFA</td>
                  <td>2 400 FCFA</td>
                </tr>
                <tr className="thead-light">
                  <th className="color-primary" scope="col">
                    Classes
                  </th>
                  <th className="color-primary" scope="col" colSpan={3}>
                    Première-Terminale
                  </th>
                </tr>
                <tr>
                  <td>Nombre d'enfants /famille</td>
                  <td>01 enfant</td>
                  <td>02 enfants</td>
                  <td>03 enfants et plus</td>
                </tr>
                <tr>
                  <td>Tarif/Séance/enfant</td>
                  <td>3 000 FCFA</td>
                  <td>2 900 FCFA</td>
                  <td>2 800 FCFA</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}
      </div>

      <div>
        <br />
        <br />
        <h4
          className="text-center"
          style={{ color: "#DD2421", textAlign: "center" }}
        >
          TARIFS DES INTERVENTIONS HEBODMADAIRES EN LIGNE
        </h4>

        <div>
          <img src="/tutoring-pricing-2.png" alt="" />
        </div>
        <br />
        <br />

        {/* <Ul
          className="nav nav-pills mb-3 justify-content-around"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className="nav-link active"
              id="pills-home-tab"
              data-toggle="pill"
              href="#pills-homes"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              NIVEAU PRIMAIRE
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="pills-profile-tab"
              data-toggle="pill"
              href="#pills-profiles"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
            >
              NIVEAU SECONDAIRE
            </a>
          </li>
        </Ul>

        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade active show"
            id="pills-homes"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <table className="table table-bordered table-hover table-striped text-center table-light">
              <thead className="thead-light">
                <tr className="color-primary">
                  <th className="color-primary" scope="col">
                    Classes
                  </th>
                  <th className="color-primary" scope="col" colSpan={3}>
                    CP-CE1-CE2-CM1
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nombre d'enfants /famille</td>
                  <td>01 enfant</td>
                  <td>02 enfants</td>
                  <td>03 enfants et plus</td>
                </tr>
                <tr>
                  <td>Tarif/Séance/enfant</td>
                  <td>2 300 FCFA</td>
                  <td>2 100 FCFA</td>
                  <td>1 900 FCFA</td>
                </tr>
                <tr className="thead-light">
                  <th className="color-primary" scope="col">
                    Classes
                  </th>
                  <th className="color-primary" scope="col" colSpan={3}>
                    CM2
                  </th>
                </tr>
                <tr>
                  <td>Nombre d'enfants /famille</td>
                  <td>01 enfant</td>
                  <td>02 enfants</td>
                  <td>03 enfants et plus</td>
                </tr>
                <tr>
                  <td>Tarif/Séance/enfant</td>
                  <td>2 400 FCFA</td>
                  <td>2 300 FCFA</td>
                  <td>2 200 FCFA</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            className="tab-pane fade"
            id="pills-profiles"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <table className="table table-bordered table-hover table-striped text-center table-light">
              <thead className="thead-light">
                <tr className="thead-light">
                  <th className="color-primary" scope="col">
                    Classes
                  </th>
                  <th className="color-primary" scope="col" colSpan={3}>
                    Sixième-Cinquième-Quatrième
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nombre d'enfants /famille</td>
                  <td>01 enfant</td>
                  <td>02 enfants</td>
                  <td>03 enfants et plus</td>
                </tr>
                <tr>
                  <td>Tarif/Séance/enfant</td>
                  <td>2 400 FCFA</td>
                  <td>2 200 FCFA</td>
                  <td>2 000 FCFA</td>
                </tr>
                <tr className="thead-light">
                  <th scope="col">Classes</th>
                  <th scope="col" colSpan={3}>
                    Troisième
                  </th>
                </tr>
                <tr>
                  <td>Nombre d'enfants /famille</td>
                  <td>01 enfant</td>
                  <td>02 enfants</td>
                  <td>03 enfants et plus</td>
                </tr>
                <tr>
                  <td>Tarif/Séance/enfant</td>
                  <td>2 700 FCFA</td>
                  <td>2 600 FCFA</td>
                  <td>2 600 FCFA</td>
                </tr>
                <tr className="thead-light">
                  <th className="color-primary" scope="col">
                    Classes
                  </th>
                  <th className="color-primary" scope="col" colSpan={3}>
                    Seconde
                  </th>
                </tr>
                <tr>
                  <td>Nombre d'enfants /famille</td>
                  <td>01 enfant</td>
                  <td>02 enfants</td>
                  <td>03 enfants et plus</td>
                </tr>
                <tr>
                  <td>Tarif/Séance/enfant</td>
                  <td>2 700 FCFA</td>
                  <td>2 500 FCFA</td>
                  <td>2 300 FCFA</td>
                </tr>
                <tr className="thead-light">
                  <th className="color-primary" scope="col">
                    Classes
                  </th>
                  <th className="color-primary" scope="col" colSpan={3}>
                    Première-Terminale
                  </th>
                </tr>
                <tr>
                  <td>Nombre d'enfants /famille</td>
                  <td>01 enfant</td>
                  <td>02 enfants</td>
                  <td>03 enfants et plus</td>
                </tr>
                <tr>
                  <td>Tarif/Séance/enfant</td>
                  <td>2 900 FCFA</td>
                  <td>2 800 FCFA</td>
                  <td>2 700 FCFA</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
    </section>
  );
}
