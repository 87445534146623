import React from "react";
import { RouteComponentProps } from "@reach/router";
import JobsAdsPricing from "../Jobs";
export default function AdvertisersEntreprise(props: RouteComponentProps) {


  return (
    <>
      <aside id="fh5co-hero" className="joboffer">
        <div className="flexslider">
          <ul className="slides">
            <li style={{ backgroundImage: `url(/images/works.jpg)` }}>
              <div className="overlay-gradient"></div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-md-offset-2 slider-text">
                    <div className="slider-text-inner">
                      <h1 style={{ fontSize: 35 }}>
                        Vous êtes une entreprise et vous souhaitez accéder à nos services ? <br />
                        Découvrez nos tarifs
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </aside>
      <div className="container">
        <br />
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Accueil</a></li>
                <li className="breadcrumb-item"><a href="/espace-annonceurs">Espace annonceurs</a></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <JobsAdsPricing />
    </>
  )
}
