import React from "react";
import { OverviewCard, OverviewCardHeader } from "../../style";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import axios from "axios";
import TextField from "ui/TextField";
import { useAuthToken } from "hooks/useAuthToken";
import useCurrentEntreprise from "hooks/useCurrentEntreprise";
import styled from "styled-components";
import FileButton from "ui/FileButton";
import { toast } from "react-toastify";
const ProfileWrapper = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 75px;
  overflow: hidden;
  background-color: #e1eaef;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  button {
    width: 100%;
    background-color: rgba(32, 33, 36, 0.6);
    height: 54px;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    display: none;
  }

  :hover button {
    display: block;
  }
`

const schema = yup.object().shape({
  name: yup.string().required(),
  owner_name: yup.string().required(),
  about: yup.string(),
  // address: yup.string().required(),

  presentation_video_link: yup.string(),
  website_link: yup.string(),
  facebook_link: yup.string(),
});

export default function Application({ }) {
  const { register, handleSubmit, errors, reset, formState } = useForm({ resolver: yupResolver(schema) });

  const [authToken] = useAuthToken();
  const [currentUser] = useCurrentEntreprise();
  const [resume, setResume] = React.useState(null);
  const [preview, setPreview] = React.useState(null);
  const [sectors, setSectors] = React.useState([]);
  const [activitySector, setActivitySector] = React.useState("");

  React.useEffect(() => {
    axios.get("/api/v1/sheets/sectors")
      .then(res => {
        setSectors(res.data)
      })
      .catch(error => {
      });
  }, []);

  React.useEffect(() => {
    if (currentUser) {
      setActivitySector(currentUser.activity_sector)
    }
  }, [currentUser]);


  const onSubmit = (data: any) => {
    axios.put("/api/v1/entreprises/contact", {
      ...data,
      activity_sector: activitySector
    }, {
      headers: {
        ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {}),
      }
    }).then(res => {
      toast.success("Informations modifiées avec succès!")
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    })
      .catch(error => {
      });
  }

  function sendResume() {
    if (resume) {
      const formData = new FormData();
      formData.append("profile_image", resume);
      axios.put("/api/v1/entreprises/upload-profile-image", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...(Boolean(authToken) ? { Authorization: `Bearer ${authToken}` } : {}),
        }
      }).then(res => {
        window.location.reload();
      })
        .catch(error => {

        })
    }
  }

  function onDrop(picture: any) {
    setResume(picture)
    setPreview(URL.createObjectURL(picture))
  }

  return (
    <div className="container">
      <OverviewCard>
        <div className="row">
          <OverviewCardHeader className="col-md-12">
            <h4 className="page-title">Présentaion</h4>
          </OverviewCardHeader>
          <div className="col-lg-12">
            <ProfileWrapper>
              <img src={currentUser?.profile_image} />
              <button
                data-toggle="modal" data-target="#exampleModal"
              >
                Changer
            </button>
            </ProfileWrapper>
          </div>
          <div className="col-lg-12">
            <form className="row" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-6">
                <fieldset>
                  <TextField
                    label="Nom de l'entreprise"
                    name="name"
                    type="text"
                    required
                    inputRef={register}
                    error={!!errors.name}
                    helpText={errors.name?.message}
                    defaultValue={currentUser?.name}
                  />
                </fieldset>
              </div>

              <div className="col-md-6">
                <fieldset>
                  <TextField
                    label="Nom du responsable"
                    name="owner_name"
                    type="text"
                    required
                    inputRef={register}
                    error={!!errors.owner_name}
                    helpText={errors.lastname?.owner_name}
                    defaultValue={currentUser?.owner_name}
                  />
                </fieldset>
              </div>
              <div className="col-md-6">
                <fieldset>
                  <label htmlFor="">À propos</label>
                  <textarea
                    className="form-control"
                    name="about"
                    ref={register}
                    defaultValue={currentUser?.about}
                    rows={7}
                  >
                  </textarea>
                </fieldset>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <fieldset>
                      <label htmlFor="">Secteur d'activité</label>
                      <select value={activitySector} className="form-control"
                        onChange={(e) => setActivitySector(e.target.value)}
                      >
                        <option value=""></option>

                        {
                          sectors.map((sector, key) =>
                            <option key={key} value={sector.name}>{sector.name}</option>
                          )
                        }
                        <option value="Autres">Autres</option>
                      </select>
                    </fieldset>
                  </div>
                  <div className="col-md-12">
                    <fieldset>
                      <TextField
                        label="Video de presentation (lien youtube)"
                        name="presentation_video_link"
                        type="text"
                        inputRef={register}
                        error={!!errors.presentation_video_link}
                        helpText={errors.presentation_video_link?.message}
                        defaultValue={currentUser?.presentation_video_link}
                      />
                    </fieldset>
                  </div>
                </div>

              </div>
              <div className="col-md-6">
                <fieldset>
                  <TextField
                    label="Lien facebook"
                    name="facebook_link"
                    type="text"
                    inputRef={register}
                    error={!!errors.facebook_link}
                    helpText={errors.facebook_link?.message}
                    defaultValue={currentUser?.facebook_link}
                  />
                </fieldset>
              </div>
              <div className="col-md-6">
                <fieldset>
                  <TextField
                    label="Lien site web"
                    name="website_link"
                    type="text"
                    inputRef={register}
                    error={!!errors.website_link}
                    helpText={errors.website_link?.message}
                    defaultValue={currentUser?.website_link}
                  />
                </fieldset>
              </div>

              <div className="col-md-12">
                <fieldset>
                  <button className="btn btn-primary">Mettre à jour</button>
                </fieldset>
              </div>
            </form>
          </div>
        </div>
      </OverviewCard>


      <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={{ padding: 15 }}>
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">Changer de logo</h3>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <FileButton onChange={onDrop} id="d" name="s" acceptedTypes={[".jpg", ".png"]} />
            <br />
            <div style={{ height: 325, border: "solid black 1px" }}>
              <img height={"100%"} width="100%" src={preview}></img>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
              <button onClick={sendResume} type="button" className="btn btn-primary">Changer</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
