import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import axios from "axios";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from "ui/TextField";
import { useAuthToken } from "hooks/useAuthToken";
import AuthWrapper from "../../Wrapper";
import { Title } from "../../style";
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  password: yup.string().required("Veuillez saisir votre mot de passe.")
});

interface IProps extends RouteComponentProps {
  token?: string;
}

const Auth: React.FC<IProps> = ({ token }) => {
  const [_, setAuthToken] = useAuthToken();
  const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(schema) });
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const onSubmit = (data: any) => {
    axios.post("/api/v1/families/reset-password/" + token, {
      password: data.password
    }).then(res => {
      toast.success("Votre mot de passe a été édité avec succès, veuillez vous connectez pour continuer");
      navigate("/auth/~f/signin");
    })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          setIsError(true);
          setErrorMessage("Le mot de passe et/ou l'email que vous avez saisi est invalide.")
        }
      })
  }


  return (
    <AuthWrapper profile="teacher">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Title className="text-center" style={{ fontSize: 20 }}>
            Modifier mon mot de passe
          </Title>
        </div>
        {
          isError &&
          <div className="alert alert-danger" role="alert">
            {/* {errorMessage} */}
          </div>
        }
        <div className="form-group">
          <TextField
            label="Mot de passe"
            name="password"
            type="password"
            required
            inputRef={register}
            error={!!errors.password}
            helpText={errors.password?.message}
          />
        </div>

        <div className="form-group text-center">
          <button
            type="submit" className="btn btn-primary">
            Mettre à jour
					</button>
        </div>

        <div className="form-group text-center">
          Vous n'avez pas encore de compte ? <a href="/auth/~f/signup">S'inscrire</a>
        </div>
      </form>
    </AuthWrapper>
  )
}

export default Auth;