import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  height: 150px;
  width: 100%;
  padding: 10px;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  border-radius: 10px;
  margin-bottom: 10px;

  /* ::after {
    content: "";
    border-radius: 10px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  } */

`;

const ImageWrapper = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  margin-right: 5px;
  flex-basis: 40px;

  img { 
    border-radius: 5px;
  }
`;

const InfosWrapper = styled.div`
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
`;

const Title = styled.h4`
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  margin: 0;
`;

const Date = styled.span`
  color: #777;
  font-weight: 600;
  font-size: 13px;
  text-transform: capitalize;
`;

interface JobItemProps {
  item: any;

}
const JobItem: React.FC<JobItemProps> = ({ item }) =>
  <Wrapper className="item">
    <InfosWrapper>
      <a href={`/stage-et-emploi/${item.slug}`}>
        <Title>{item.name.length <= 40 ? item.name : item.name.substr(0, 40) + "..."}</Title>
      </a>
    </InfosWrapper>
  </Wrapper >

export default JobItem;