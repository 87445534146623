import React from "react";
import { navigate } from "@reach/router";
import ApplicationItem, { JOB_OFFER_STATUS, Status } from "./ApplicationItem";
import axios from "axios";
import moment from "moment";
import { OverviewCard, OverviewCardHeader } from "../../style";
import { useAuthToken } from "hooks/useAuthToken";
import CandidatItem from "./CandidatItem";
import { InfoList, Ul } from "../JobDetails";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import SearchBar from "./SearchBar";
import Checkbox from "ui/Checkbox";

const defaultFilters = {
  search_contract_type: [] as any[],
  professional_situation: [] as any[],
  current_job_level: [] as any[],
  study_level: [] as any[],
  sought_sectors: [] as any[],
};

const Applications = ({}) => {
  const [data, setData] = React.useState<Array<any>>([]);
  const [showData, setShowData] = React.useState<Array<any>>([]);
  const [prevData, setPrevData] = React.useState<Array<any>>([]);
  const [currentCandidate, setCurrentCandidate] = React.useState(null);

  const [authToken] = useAuthToken();
  const [setting, setSetting] = React.useState(null);

  const [filters, setFilters] = React.useState(defaultFilters);
  const [sectors, setSectors] = React.useState([]);
  const [showSectors, setShowSectors] = React.useState([]);

  React.useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    const _query = params.get("q");
    const _city = params.get("city");
    let path = "/api/v1/entreprises/cvtheque/?";

    if (_query) {
      path += "title=" + _query;
    }

    if (_city) {
      path += !_query ? "" : "&";
      path += "city=" + _city;
    }

    axios
      .get(path, {
        headers: {
          ...(Boolean(authToken)
            ? { Authorization: `Bearer ${authToken}` }
            : {}),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setShowData(res.data);
          setPrevData(res.data);
        }
      });

    axios.get("/api/v1/settings/stage-et-emploi").then((res) => {
      setSetting(res.data);
    });

    axios("/api/v1/sheets").then((res) => {
      setSectors(res.data);
      setShowSectors(res.data);
    });
  }, []);

  const handlFilters = (key: string, value: string) => {
    const fil = { ...filters };
    value = value.toLowerCase();

    if (fil[key]) {
      if (!fil[key].includes(value)) {
        fil[key].push(value);
      } else {
        fil[key] = fil[key].filter((item: string) => item != value);
      }
    }

    setFilters(fil);
  };

  React.useEffect(() => {
    const res = data.filter((item) => getNeedles(item));

    setShowData(res);
    setPrevData(res);
  }, [
    filters.search_contract_type.length,
    filters.professional_situation.length,
    filters.study_level.length,
    filters.current_job_level.length,
    filters.sought_sectors.length,
  ]);

  // React.useEffect(() => {
  //   if (filters.sectors.length > 0) {
  //     setPrevData(showData)
  //     setShowData(data.filter(item => item.sought_sectors && item.sought_sectors.split(",")
  //       .filter((el: any) => filters.sectors.includes(el)).length > 0));
  //   } else {
  //     setShowData(prevData)
  //   }
  // }, [filters.sectors.length]);

  let getNeedles = (obj: any): boolean =>
    Object.keys(filters).every((key) => {
      if (filters[key].length > 0) {
        if (key === "sought_sectors") {
          return (
            obj[key] &&
            obj[key]
              .split(",")
              .filter((el: any) =>
                filters.sought_sectors.includes(el.toLowerCase())
              ).length > 0
          );
        }
        return filters[key].includes(obj[key].toLowerCase());
      }
      return true;
    });

  return (
    <div className="container">
      <div className="row">
        <OverviewCard className="col-lg-12">
          <OverviewCardHeader>
            <h4 className="page-title">CVthèque</h4>
          </OverviewCardHeader>

          <SearchBar />
          <br />
          <div className="row">
            <div className="col-md-4">
              <div className="search-filter">
                <h5>Filtrer</h5>
                <div className="search-filter-title">Métiers recherché</div>
                <hr />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Rechercher"
                  onChange={(e) => {
                    setShowSectors(
                      sectors.filter((item) =>
                        item.name
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      )
                    );
                  }}
                />
                <br />
                <div className="row">
                  {showSectors.map((c: any, key: any) => (
                    <div className="col-md-12">
                      <Checkbox
                        checked={
                          filters.sought_sectors.indexOf(
                            c.name.toLowerCase()
                          ) !== -1
                        }
                        label={c.name}
                        value={c.name}
                        onChange={(e) => {
                          handlFilters("sought_sectors", e.target.value);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="search-filter">
                <div className="search-filter-title">
                  Type de contrat recherché
                </div>
                <hr />
                <div className="row">
                  {setting?.contractType.map((c: any, key: any) => (
                    <div className="col-md-12">
                      <Checkbox
                        label={c.name}
                        value={c.name}
                        onChange={(e) => {
                          handlFilters("search_contract_type", e.target.value);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="search-filter">
                <div className="search-filter-title">
                  Situation professionnelle
                </div>
                <hr />
                <div className="row">
                  {setting?.professionalSituation.map((c: any, key: any) => (
                    <div className="col-md-12">
                      <Checkbox
                        label={c.name}
                        value={c.name}
                        onChange={(e) => {
                          handlFilters(
                            "professional_situation",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="search-filter">
                <div className="search-filter-title">
                  Experience professionnelle
                </div>
                <hr />
                <div className="row">
                  {setting?.experienceLevel.map((c: any, key: any) => (
                    <div className="col-md-12">
                      <Checkbox
                        label={c.name}
                        value={c.name}
                        onChange={(e) => {
                          handlFilters("current_job_level", e.target.value);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="search-filter">
                <div className="search-filter-title">Niveau d'études</div>
                <hr />
                <div className="row">
                  {setting?.schoolLevel.map((c: any, key: any) => (
                    <div className="col-md-12">
                      <Checkbox
                        label={c.name}
                        value={c.name}
                        onChange={(e) => {
                          handlFilters("study_level", e.target.value);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-8">
              {showData && showData.length > 0 ? (
                showData.map((candidat, key) => (
                  <CandidatItem
                    key={key}
                    candidat={candidat}
                    onDetails={setCurrentCandidate}
                  />
                ))
              ) : (
                <>Rien à afficher</>
              )}
            </div>
          </div>
        </OverviewCard>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Candidat
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {currentCandidate ? (
              <div className="modal-body" style={{ minHeight: "70vh" }}>
                <Ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="pills-details-tab"
                      data-toggle="pill"
                      href="#pills-details"
                      role="tab"
                      aria-controls="pills-details"
                      aria-selected="true"
                    >
                      Profil
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-resume-tab"
                      data-toggle="pill"
                      href="#pills-resume"
                      role="tab"
                      aria-controls="pills-resume"
                      aria-selected="false"
                    >
                      CV
                    </a>
                  </li>
                </Ul>

                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade active show"
                    id="pills-details"
                    role="tabpanel"
                    aria-labelledby="pills-details-tab"
                  >
                    <InfoList>
                      <li className="item">
                        <span className="item-title">Nom : </span>
                        <span> {currentCandidate.firstname}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Prénom : </span>
                        <span>{currentCandidate.lastname}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Tel : </span>
                        <span>{currentCandidate.phonenumber}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Email : </span>
                        <span>{currentCandidate.email}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Adresse : </span>
                        <span>{currentCandidate.address}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Niveau d'étude : </span>
                        <span>{currentCandidate.study_level}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">
                          Type de contrat recherché :{" "}
                        </span>
                        <span>{currentCandidate.search_contract_type}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Disponibilité : </span>
                        <span>{currentCandidate.availability}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">
                          Expérience professionnelle :{" "}
                        </span>
                        <span>{currentCandidate.current_job_level}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">
                          Situation professionnelle :{" "}
                        </span>
                        <span>{currentCandidate.professional_situation}</span>
                      </li>
                      <li className="item">
                        <span className="item-title">Métier ciblé : </span>
                        <span>{currentCandidate.sought_sectors}</span>
                      </li>
                    </InfoList>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-resume"
                    role="tabpanel"
                    aria-labelledby="pills-resume-tab"
                  >
                    {currentCandidate?.resume ? (
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                        <div style={{ height: "750px" }}>
                          <Viewer
                            fileUrl={currentCandidate?.resume?.replace(
                              "http://",
                              "https://"
                            )}
                          />
                        </div>
                      </Worker>
                    ) : (
                      <div
                        style={{
                          padding: 20,
                          marginTop: 50,
                          textAlign: "center",
                        }}
                      >
                        Pas de CV disponible
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applications;
