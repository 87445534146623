import React from "react";
import MainLayout from "ui/Layout";
import { Router, RouteComponentProps } from "@reach/router";
import CategoriesPage from "./Categories";
import ArticlePage from "./Article";
import CategoryPage from "./Category";

const StaticPage: React.FC<RouteComponentProps> = () =>
  <MainLayout>
    <Router>
      <CategoriesPage path="/" />
      <CategoryPage path="/:slug" />
      <ArticlePage path="/:category/:slug" />
    </Router>
  </MainLayout>

export default StaticPage;