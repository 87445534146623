import React from "react";
import Autosuggest from 'react-autosuggest';

const SearchBar: React.FC = () => {
  const [query, setQuery] = React.useState("");
  const [city, setCity] = React.useState("");
  const [suggestedCities, setSuggestedCities] = React.useState([]);
  const [cities, setCities] = React.useState([]);

  React.useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    setQuery(params.get('q'));
    if (params.get('city'))
      setCity(params.get('city'));

    fetch("/api/v1/studies/cities")
      .then((res) => res.json())
      .then((data) => {
        setCities(data);
      })
  }, []);

  const getSuggestions = (value: any) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : cities.filter(lang =>
      lang.name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };

  const getSuggestionValue = (suggestion: any) => suggestion.name;

  const renderSuggestion = (suggestion: any) => (
    <div>
      {suggestion.name}
    </div>
  );

  const onSearch = () => {
    let path = '/stage-et-emploi/recherche/';
    if (query)
      path += `?q=${query}&`;

    if (city) {
      path += !query ? '?' : '';
      path += `city=${city}&`;
    }

    window.location.href = path;
  }

  const onChange = (event: any, { newValue }: any) => {
    setCity(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }: any) => {
    setSuggestedCities(getSuggestions(value))
  };

  const onSuggestionsClearRequested = () => {
    setSuggestedCities([])
  };

  return (
    <div className="search-container">
      <div className="row">
        <div className="col-lg-5 col-md-4">
          <input value={query} onChange={e => setQuery(e.target.value)} type="text" className="form-control" name="motCle" placeholder="Mot-clé (ex : commercial)" />
        </div>
        <div className="col-lg-5 col-md-4">
          <Autosuggest
            suggestions={suggestedCities}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{
              placeholder: "Lieu : ville, ou région",
              value: city,
              onChange: onChange
            }}
            highlightFirstSuggestion
          />
        </div>
        <div className="col-lg-2 col-md-4">
          <button onClick={onSearch} className="btn search-button btn-primary">Rechercher</button>
        </div>
      </div>
    </div>
  )
}

export default SearchBar;