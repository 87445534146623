import React from "react";
import { RouteComponentProps } from "@reach/router";
import axios from "axios";
import { PageTitle } from "./style";
import Ads from "pages/Ads";
import Adser from "pages/Adser";

const TutoringPage: React.FC<RouteComponentProps> = () => {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    axios('/api/v1/tutoring/page-text/6')
      .then(res => {
        setData(res.data)
      })
  }, []);

  return (
    <div className="container">
      <br />
      <div className="row">
        <div className="col-md-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">Accueil</a></li>
              <li className="breadcrumb-item"><a href="/soutien-scolaire">Soutien scolaire</a></li>
            </ol>
          </nav>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-8">
          <div className="">
            <div className="row">
              <div className="col-md-12 noPdright">
                <div className="clsap">
                  <PageTitle>{data?.name}</PageTitle>
                  <div className="action-card">
                    <div className="text">
                      {data?.description}
                    </div>
                    <div className="action">
                      <a href="/auth/~t/signup" className="btn btn-primary" style={{ width: 215 }}>
                        S'enregister
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br />

            <div className="">
              <div className="row">
                <div className="col-md-12">
                  <div dangerouslySetInnerHTML={{ __html: data?.presentation }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <Adser />
          <br />
          <Ads sticky page="tutoring_home" />
        </div>
      </div>
    </div>
  )
}

export default TutoringPage;