import React from "react";
import { OverviewCard, OverviewCardHeader } from "../../style";
import axios from "axios";
import { useAuthToken } from "hooks/useAuthToken";
import { toast } from "react-toastify";
import SelectField from "ui/SelectField";
import { navigate } from "@reach/router";

export default function NewChildren({}) {
  const [authToken] = useAuthToken();
  const [levels, setLevels] = React.useState<any[]>([]);
  const [courses, setCourses] = React.useState<any[]>([]);
  const [classes, setClasses] = React.useState<any[]>([]);

  const [level, setLevel] = React.useState(null);
  const [course, setCourse] = React.useState(null);
  const [classe, setClasse] = React.useState(null);

  const [submitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    axios("/api/v1/tutoring/levels/for-request").then((res) => {
      setLevels(res.data);
    });
  }, []);

  const levelChange = (e: any) => {
    const value = e.target.value;

    setLevel(value);
    if (value) {
      const _c = levels.find((level) => level.id == value);

      if (_c) {
        setClasses(_c.classes);
        setCourses(_c.courses);
      }
    }
    setClasse(null);
    setCourse(null);
  };

  const classeChange = (e: any) => {
    setClasse(e.target.value);
  };

  const courseChange = (e: any) => {
    setCourse(e.target.value);
  };

  const onSubmit = () => {
    setSubmitted(true);

    if (!Boolean(level) || !Boolean(classe)) return;

    axios
      .post(
        "/api/v1/teachers/my/teachings",
        {
          level_id: level,
          course_id: course,
          classe_id: classe,
        },
        {
          headers: {
            ...(Boolean(authToken)
              ? { Authorization: `Bearer ${authToken}` }
              : {}),
          },
        }
      )
      .then((res) => {
        toast.success("Domaine ajouté avec succès!");
        setTimeout(() => {
          navigate("/soutien-scolaire/e/enseignant/profil");
        }, 1500);
      })
      .catch((error) => {
        toast.error("Erreur!");
      });
  };

  return (
    <div className="container">
      <OverviewCard>
        <div className="row">
          <OverviewCardHeader className="col-md-12">
            <h4 className="page-title">Ajouter un domaine</h4>
          </OverviewCardHeader>

          <div className="col-md-4">
            <fieldset>
              <SelectField
                label="Niveau"
                onChange={levelChange}
                name="level"
                required
                error={submitted && !Boolean(level)}
                helpText={
                  submitted &&
                  !Boolean(level) &&
                  "Veuillez selectionner le niveau"
                }
              >
                <option></option>
                {levels.map((c, key) => (
                  <option key={key} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </SelectField>
            </fieldset>
          </div>

          <div className="col-md-4">
            <fieldset>
              <SelectField
                onChange={courseChange}
                label="Matiere"
                name="course"
                required
                error={submitted && !Boolean(course)}
                helpText={
                  submitted &&
                  !Boolean(course) &&
                  "Veuillez selectionner la matiere"
                }
              >
                <option></option>
                {courses.map((c, key) => (
                  <option key={key} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </SelectField>
            </fieldset>
          </div>

          <div className="col-md-4">
            <fieldset>
              <SelectField
                onChange={classeChange}
                label="Classe"
                name="class"
                required
                error={submitted && !Boolean(classe)}
                helpText={
                  submitted &&
                  !Boolean(classe) &&
                  "Veuillez selectionner la classe"
                }
              >
                <option></option>
                {classes.map((c, key) => (
                  <option key={key} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </SelectField>
            </fieldset>
          </div>

          <div className="col-md-12">
            <fieldset>
              <button className="btn btn-primary" onClick={onSubmit}>
                Ajouter
              </button>
            </fieldset>
          </div>
        </div>
      </OverviewCard>
    </div>
  );
}
