import React from "react";
import { RouteComponentProps } from "@reach/router";
import "./style.css"
import Studies from "../Studies";
import Studies1 from "../Studies1";
import Studies2 from "../Studies2";
import Studies3 from "../Studies3";
import { NewsWrapper } from "pages/JobOffer/NewsList";
import NewItem from "../NewItem";
import { Ul } from "pages/JobOffer/JobDetails";
export default function AdvertisersStudies(props: RouteComponentProps) {

  React.useEffect(() => {
    initSlider();

  }, []);

  const initSlider = () => {
    if ((window as any).$) {
      (window as any).$("#studentlife.owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: false,
        dots: false,
        animateOut: true,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 3
          }
        }
      })
    }
  }
  return (
    <>
      {/* <h1>Vous êtes une école et vous souhaitez être référencée? découvrez notre offre Studies-Études</h1> */}
      <aside id="fh5co-hero" className="joboffer">
        <div className="flexslider">
          <ul className="slides">
            <li style={{ backgroundImage: `url(/images/works.jpg)` }}>
              <div className="overlay-gradient"></div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-md-offset-2 slider-text">
                    <div className="slider-text-inner">
                      <h1 style={{ fontSize: 35 }}>Vous êtes une école et vous souhaitez être référencée? <br /> découvrez notre offre Studies-Études</h1>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </aside>
      <div className="container">
        <br />
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Accueil</a></li>
                <li className="breadcrumb-item"><a href="/espace-annonceurs">Espace annonceurs</a></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 style={{ color: "#DD2421", textAlign: "center" }}>Contenu de l'offre</h3>
            <ol className="bull-list">
              <li className="bull-item">
                <h2 className="bull-headline">REFERENCEMENT</h2><span>
                  Apparition dans l'annuaire en ligne en bénéficiant d'un ciblage par rubriques
                  (filières métiers, ville, niveau d'études, nom de l'école, type d'enseignement) pendant une
                  durée de 06 ou 12 mois consécutifs à partir de la date de souscription sur tous les terminaux
                  connectés (ordinateur, Mac, Tablettes, Smartphones). Ainsi vous associez puissance et
notoriété. Vous pouvez même affiner votre ciblage selon vos besoins.</span>
              </li>
              <li className="bull-item">
                <h2 className="bull-headline">OPTION EXTRACTION DES CANDIDATS</h2><span>
                  Vous recevez quotidiennement la liste détaillée
                  (coordonnées complètes , contacts téléphoniques, adresses mail) des visiteurs de Studies qui
                  auront renseigné un formulaire de demande d'informations complémentaires pour vos offres
de formations/ services/ pendant la durée de votre contrat de référencement (06 ou 12 mois)</span>
              </li>
              <li className="bull-item">
                <h2 className="bull-headline">OPTION PRIME LIST</h2><span>
                  Vous pourrez vous démarquer en faisant apparaître votre établissement
                  en tête de liste, et en ajoutant votre logo et un surlignage pendant la durée de votre contrat.
</span>
              </li>
            </ol>

            <h3 style={{ color: "#DD2421", textAlign: "center" }}>Tarifs</h3>

            <div>

              <Ul className="nav nav-pills mb-3 justify-content-around" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">ENSEIGNEMENT PRIMAIRE</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">ENSEIGNEMENT SECONDAIRE</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="pills-entreprise-tab" data-toggle="pill" href="#pills-entreprise" role="tab" aria-controls="pills-entreprise" aria-selected="false">ENSEIGNEMENT SUPERIEUR</a>
                </li>
              </Ul>

              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade active show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <Studies1 />
                </div>

                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <Studies2 />
                </div>

                <div className="tab-pane fade" id="pills-entreprise" role="tabpanel" aria-labelledby="pills-entreprise-tab">
                  <Studies3 />
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </>
  )
}
