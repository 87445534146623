import React from "react";
import useAuth from "hooks/useAuth";

export default function NavMenuTop({ }) {
  const [isAuth, _, authData] = useAuth();

  return (
    <div className="top-menu nav-top">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-right">
            <p className="num">
              {
                (!isAuth || (isAuth && (authData?.role !== "teacher" && authData?.role !== "family"))) &&
                <>
                  <a href="/soutien-scolaire/e/enseignant" className="btn btn-outline btn-primary button-top-menu-right">Espace Enseignant</a>
                  <a href="/soutien-scolaire/e/famille" className="btn btn-primary button-top-menu-right">Espace Famille</a>
                </>
              }

              {
                isAuth && authData?.role === "teacher" &&
                <a href="/soutien-scolaire/e/enseignant" className="btn btn-outline btn-primary button-top-menu-right">Mon espace</a>
              }

              {
                isAuth && authData?.role === "family" &&
                <a href="/soutien-scolaire/e/famille" className="btn btn-primary  button-top-menu-right">Mon espace</a>
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
