
import React from "react";
import { RouteComponentProps } from "@reach/router";
import ArticleItem from "../ArticleItem";
import Covers from "pages/Covers";
import axios from "axios";
import OnTop from "pages/OnTop";

export default function StudentLifePage(props: RouteComponentProps) {
  const [articles, setArticles] = React.useState([]);

  React.useEffect(() => {
    axios('/api/v1/articles/student_life/public')
      .then(res => {
        setArticles(res.data)
      });
  }, []);

  return (
    <>
      <Covers page="student_life_home" />

      <div id="fh5co-blog">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                {
                  articles.map((article, key) => {
                    return (
                      <div key={key} className="col-lg-4 col-md-4">
                        <ArticleItem path="/vie-etudiante" article={article} />
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="col-md-4">
              <OnTop section="student_life" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
