import React from "react";
import { OverviewCard, OverviewCardHeader } from "pages/style";
import axios from "axios";
import { useAuthToken } from "hooks/useAuthToken";
import useCurrentParticular from "hooks/useCurrentParticular";
import { navigate } from "@reach/router";

export default function Application({}) {
  const [resume, setResume] = React.useState("");
  const [authToken] = useAuthToken();
  const [currentUser] = useCurrentParticular();

  const [data, setData] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    axios
      .get("/api/v1/teachers/my/teachings", {
        headers: {
          ...(Boolean(authToken)
            ? { Authorization: `Bearer ${authToken}` }
            : {}),
        },
      })
      .then((res) => {
        setData(res.data);
      });
  }, []);

  const deleteTeaching = (key: string) => {
    axios
      .delete("/api/v1/teachers/my/teachings/" + key, {
        headers: {
          ...(Boolean(authToken)
            ? { Authorization: `Bearer ${authToken}` }
            : {}),
        },
      })
      .then((res) => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  };
  return (
    <div className="container">
      <OverviewCard>
        <div className="row">
          <OverviewCardHeader className="col-md-12">
            <h4 className="page-title animate-box">Domaine d'intervention</h4>
            <button
              className="btn btn-primary"
              onClick={() =>
                navigate("/soutien-scolaire/e/enseignant/profil/edit")
              }
            >
              Ajouter
            </button>
          </OverviewCardHeader>

          <div className="col-md-12">
            {data.length > 0 ? (
              <table className="table table-bordered table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Niveau</th>
                    <th scope="col">Matiere</th>
                    <th scope="col">Classe</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item: any, key: number) => (
                    <tr key={key}>
                      <td>{item.level.name} </td>
                      <td>{item.course.name} </td>
                      <td>{item.classe.name} </td>
                      <td style={{ width: 10 }}>
                        <button
                          onClick={(e) => deleteTeaching(item.id)}
                          className="btn btn-default"
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <>Rien à afficher</>
            )}
          </div>
        </div>
      </OverviewCard>
    </div>
  );
}
