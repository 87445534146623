import React from "react";
import { RouteComponentProps } from "@reach/router";
import Covers from "pages/Covers";
import TopJobOffers from "pages/JobOffer/TopJobOffers";
import Ads from "pages/Ads";
import StudentHomeArticle from "pages/StudentLife/HomeArticle";
import StudiesNewsList from "pages/Studies/HomeNewsList";
import OfferNewsList from "pages/JobOffer/HomeNewsList";
import OrientationHomeArticle from "pages/Orientation/HomeArticle";
import TutoringHomeArticle from "pages/Tutoring/HomeArticle";
import HomeArticle from "./HomeArticle";
import HomeSheet from "pages/Job/HomeSheet";
import Adser from "pages/Adser";

export default function HomePage(props: RouteComponentProps) {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    fetch("/api/v1/page-covers?page=home")
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch(console.log);
  }, []);

  return (
    <>
      <section className="w3l-banner-slider-main">
        <Covers page="home" />
      </section>
      <section className="w3l-mag-main">
        <div className="mag-content-inf">
          <div className="container">
            <div className="blog-inner-grids py-md-4 row">
              <div className="mag-content-left-hny col-lg-8">
                <div className="mag-hny-content">
                  <br />
                  <h3 className="hny-title clrs-1">
                    Quoi de neuf? Ici les actus à ne pas manquer
                  </h3>
                  <div className="maghny-grids-inf">
                    <HomeArticle />
                  </div>
                </div>

                <div className="mag-hny-content">
                  <br />
                  <h3 className="hny-title clrs-2">Les métiers à la une</h3>
                  <div className="maghny-grids-inf">
                    <HomeSheet />
                  </div>
                </div>

                <div className="mag-hny-content">
                  <br />
                  <h3 className="hny-title clrs-3">
                    Études - conseils et actualités à ne pas manquer
                  </h3>
                  <div className="maghny-grids-inf">
                    <StudiesNewsList />
                  </div>
                </div>

                <div className="mag-hny-content">
                  <br />
                  <h3 className="hny-title clrs-1">
                    Stages et Emplois - Astuces et conseils
                  </h3>
                  <div className="maghny-grids-inf">
                    <OfferNewsList />
                  </div>
                </div>

                <div className="mag-hny-content">
                  <br />
                  <h3 className="hny-title clrs-1">
                    Soutien scolaire - les informations à ne pas manquer
                  </h3>
                  <div className="maghny-grids-inf">
                    <TutoringHomeArticle />
                  </div>
                </div>

                <div className="mag-hny-content">
                  <br />
                  <h3 className="hny-title clrs-2">
                    Orientation - conseils et astuces à ne pas manquer
                  </h3>
                  <div className="maghny-grids-inf">
                    <OrientationHomeArticle />
                  </div>
                </div>

                <div className="mag-hny-content">
                  <br />
                  <h3 className="hny-title clrs-3">
                    Vie étudiante - les bons plans et actus du moment
                  </h3>
                  <div className="maghny-grids-inf">
                    <StudentHomeArticle />
                  </div>
                </div>
              </div>

              <div className="mag-content-right-hny col-lg-4">
                <aside>
                  <br />
                  <Adser />
                  <br />
                  <Ads page="home" />
                  <div className="side-bar-hny-recent mb-5">
                    <br />
                    {/* <h3 className="mag-side-title">Offres d'emploi à la une</h3> */}
                    <TopJobOffers />
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
